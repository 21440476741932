$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.sidebar-nav {
  padding-top: 8px;
  width: 100%;
  &__item {
    display: block;
    width: 32px;
    height: 32px;

    margin: 0 auto 12px;
    position: relative;

    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    &::before {
      content: attr(data-tooltip);
      display: none;
      position: absolute;
      left: calc(100% + 20px);
      top: 50%;
      transform: translateY(-50%);
      height: 32px;
      padding: 0 12px;
      font-weight: bold;
      font-size: 12px;
      line-height: 32px;
      color: #ffdc26;
      background: #2d2d32;
      border-radius: 4px;
      white-space: nowrap;
    }
    &::after {
      content: '';
      display: none;
      width: 12px;
      height: 12px;
      position: absolute;
      top: 50%;
      left: calc(100% + 16px);
      border-radius: 2px;
      background: #2d2d32;
      transform: translateY(-50%) rotate(-45deg);
    }

    &:hover::before,
    &:hover::after {
      display: block;
    }

    &.is-summer-promo {
      background-image: var(--icon-summer-icon-normal);
    }

    &.is-sport {
      background-image: var(--icon-nav_sports_3);
    }
    &.is-sport:hover {
      background-image: var(--icon-nav_sports_1);
    }
    &.is-sport.active {
      background-image: var(--icon-nav_sports_2);
    }

    &.is-live {
      background-image: var(--icon-nav_live_3);
    }
    &.is-live:hover {
      background-image: var(--icon-nav_live_1);
    }
    &.is-live.active {
      background-image: var(--icon-nav_live_2);
    }

    &.is-virtual {
      background-image: var(--icon-nav_virtual_3);
    }
    &.is-virtual:hover {
      background-image: var(--icon-nav_virtual_1);
    }
    &.is-virtual.active {
      background-image: var(--icon-nav_virtual_2);
    }

    &.is-casino {
      background-image: var(--icon-nav_casino_3);
    }
    &.is-casino:hover {
      background-image: var(--icon-nav_casino_1);
    }
    &.is-casino.active {
      background-image: var(--icon-nav_casino_2);
    }

    &.is-livecasino {
      background-image: var(--icon-nav_livecasino_3);
    }
    &.is-livecasino:hover {
      background-image: var(--icon-nav_livecasino_1);
    }
    &.is-livecasino.active {
      background-image: var(--icon-nav_livecasino_2);
    }

    &.is-promotions {
      background-image: var(--icon-nav_promotions_3);
    }
    &.is-promotions:hover {
      background-image: var(--icon-nav_promotions_1);
    }
    &.is-promotions.active {
      background-image: var(--icon-nav_promotions_2);
    }

    &.is-vip {
      background-image: var(--icon-nav_vip_3);
    }
    &.is-vip:hover {
      background-image: var(--icon-nav_vip_1);
    }
    &.is-vip.active {
      background-image: var(--icon-nav_vip_2);
    }

    &.is-tournament {
      background-image: var(--icon-nav_tournament_3);
    }
    &.is-tournament:hover {
      background-image: var(--icon-nav_tournament_1);
    }
    &.is-tournament.active {
      background-image: var(--icon-nav_tournament_2);
    }

    &.is-shop {
      background-image: var(--icon-shop-icon-grey);
    }
    &.is-shop:hover {
      background-image: var(--icon-shop-icon);
    }
    &.is-shop.active {
      background-image: var(--icon-shop-icon-hover);
    }
  }
  &__hr {
    height: 2px;
    width: 100%;
    background-image: linear-gradient(to right, var(--grLineBlack)), linear-gradient(to right, var(--grLineWhite));
    background-size: 80% 1px, 80% 1px;
    background-position: center top, center bottom;
    background-repeat: no-repeat;
    margin-bottom: 20px;
  }

  @include bp1440 {
    &__hr {
      margin-bottom: 24px;
    }
    &__item {
      margin: 0 auto 24px;
    }
    &__item::before {
      left: calc(100% + 30px);
    }
    &__item::after {
      left: calc(100% + 26px);
    }
  }
}
