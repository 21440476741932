$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.profile-exchanger {
    &__description {
        @include text(14px, 20px, normal, $color-black);
        margin-bottom: 48px;
    }
    &__form {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        background-image: linear-gradient(to left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 51.04%, rgba(0, 0, 0, 0) 100%);
        background-repeat: no-repeat;
        background-size: 100% 1px;
        background-position: center bottom;
        padding-bottom: 25px;
        margin-bottom: 24px;
        @include bp1680 {
            padding-bottom: 51px;
        }
    }
    &__form-item {
        width: calc(50% - 36px);
        text-align: center;
        position: relative;
        @include bp1680 {
            width: 243px;
        }
    }
    &__cashier {
        width: calc(50% - 36px);
        background: $color-white;
        border-radius: 12px;
        padding: 0 12px;
        text-align: center;
        @include text(28px, 48px, bold, $color-black);
        font-family: var(--font-family-main);
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        @include bp1680 {
            width: 194px;
        }
    }
    &__input-title {
        @include text(14px, 20px, normal, $color-black);
        margin-bottom: 4px;
        position: absolute;
        top: -24px;
        left: 0;
        width: 100%;
        text-align: center;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
    &__input-info {
        @include text(14px, 20px, bold, $color-grey);
        position: absolute;
        bottom: -32px;
        left: 0;
        width: 100%;
        text-align: center;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
    &__input {
        width: 100%;
        height: 48px;
        background: $color-white;
        border: 1px solid $color-grey;
        box-sizing: border-box;
        border-radius: 12px;
        text-align: center;
        @include text(24px, 24px, bold, $color-black);
        font-family: var(--font-family-main);
        @include bp1680 {

        }
    }

    &__form-arrow {
        width: 24px;
        height: 24px;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNOC4zMzM3MSAyMC43Njk4QzguNDg5MTMgMjAuOTIzNCA4LjY5MjI1IDIxIDguODk1NzcgMjFDOS4wOTkyOCAyMSA5LjMwMjQgMjAuOTIzNCA5LjQ1NzgyIDIwLjc2OThMMTcuNzY3OCAxMi41NTUzQzE4LjA3ODIgMTIuMjQ4NSAxOC4wNzgyIDExLjc1MSAxNy43Njc4IDExLjQ0NDJMOS40NTc4MiAzLjIzMDE0QzkuMTQ3MzggMi45MjMyOSA4LjY0NDE1IDIuOTIzMjkgOC4zMzM3MSAzLjIzMDE0QzguMDIzMjggMy41MzcgOC4wMjMyOCA0LjAzNDQyIDguMzMzNzEgNC4zNDEyOEwxNi4wODE2IDExLjk5OThMOC4zMzM3MSAxOS42NTgyQzguMDIyODggMTkuOTY1NSA4LjAyMjg4IDIwLjQ2MjUgOC4zMzM3MSAyMC43Njk4WiIgZmlsbD0iI0ZGREMyNiIvPjwvc3ZnPg==);
    }
    &__form-btn {
        margin: 52px auto 0;
        max-width: 400px;
        .btn-60-yellow {
            max-width: 100%;
            font-family: var(--font-family-main);
            font-weight: bold;
            text-transform: uppercase;
            letter-spacing: 0.03em;
            border: none;
            border-radius: 50px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;

        }
        @include bp1680 {
            max-width: 33%;
            margin: 0;
        }
    }
    &__text {
        @include text(14px, 20px, normal, $color-black);
        letter-spacing: 0.03em;
        margin-bottom: 20px;
    }
}
