$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.info-page {
  display: flex;
  justify-content: center;
  background-size: auto;
  margin-top: 24px;
  padding-bottom: 60px;
  height: auto;
  position: relative;

  &__info {
    width: calc(100% - 300px);
  }

  &__menu {
    width: 260px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    margin: 0 24px 0 16px;
  }

  &__menu:hover span {
    opacity: 0.5;
  }

  &__link {
    display: flex;
    align-items: center;
    width: inherit;
    font-size: 16px;
    height: 72px;
    background: linear-gradient(188deg, var(--grLineWhite)), linear-gradient(184deg, var(--grLineBlack));
    background-repeat: no-repeat, no-repeat;
    background-size: 100% 2px, 100% 2px;
    background-position: 0 70px, 0 68px;
  }

  /*увеличен вес селектора для затухания других пунктов меню*/
  & a.info-page__link:hover span,
  & a.info-page__link.active span {
    font-size: 20px;
    line-height: 133%;
    opacity: 1;

    &:lang(at),
    &:lang(de),{
      font-size: 16px;

      @include bp1440 {
        font-size: 20px;
      }
    }
  }

  h1 {
    @include text(54px, 100%, bold, rgba(255, 255, 255, 0.1));
    letter-spacing: 0.08em;
    font-family: var(--font-family-main);
    text-align: center;
    text-transform: uppercase;
    word-break: break-word;
    margin-bottom: 20px;
  }

  section {
    display: block;
    background-color: $color-white;
    border-radius: 20px;
    margin-bottom: 16px;
    margin-left: auto;
    padding: 0 32px 24px 32px;

    &:last-of-type {
      margin-bottom: 0;
    }

    a {
      color: $color-black;
      text-decoration: underline;
    }

    a:hover {
      color: $color-grey;
    }
  }

  h2 {
    @include text(24px, 28px, bold, $color-black);
    text-transform: uppercase;
    letter-spacing: 0.02em;
    padding: 24px 0 16px 0;
    font-family: var(--font-family-main);
  }

  p, ul, ol, table {
    @include text(16px, 24px, null, $color-black);

    span {
      font-weight: 700;
    }
  }

  ul {
    list-style: disc;
    padding-left: 24px;
    @include text(16px, 24px, null, $color-black);
  }

  ol:not(:last-child),
  ul:not(:last-child),
  table:not(:last-child),
  p:not(:last-child) {
    padding-bottom: 8px;
  }

  li:not(:last-child) {
    padding-bottom: 8px
  }

  h2 ~ div {
    overflow-x: auto;
  }

  table {
    display: table;
    position: relative;
    margin: 20px 0;
    width: 100%;

    tr {
      border-bottom-width: 1px;
      border-bottom-style: solid;
      border-image: linear-gradient(180deg,rgba(0,0,0,0) 0,rgba(0,0,0,.6) 51.04%,rgba(0,0,0,0) 100%);
      border-image-slice: 1;
    }
  }
  thead {
    tr {
      th, td {
        font-weight: bold;
        color: $color-grey;
        padding: 6px 10px;
        text-align: center;
      }
    }
  }

  tbody {
    tr {
      td {
        @include text(12px, 120%, normal, $color-black);
        text-align: center;
        padding: 6px 10px;
      }
    }
  }

  @include bp1280 {
    section {
      margin-bottom: 20px;
      padding-bottom: 28px;
    }
    &__menu {
      margin-left: 24px;
      position: sticky;
      top: 0;
    }

    td,
    th {
      padding: 20px 10px;
    }
  }

  @include bp1440 {
    margin-top: 40px;
    &__info {
      flex-grow: 0;
      width: calc(100% - 368px);
      max-width: 1000px;
    }
    &__menu {
      width: 300px;
      margin: 0 36px 0 32px;
    }
    &__link {
      font-size: 18px;
    }
    & a.info-page__link:hover span,
    & a.info-page__link.active span {
      font-size: 24px;
    }
    section {
      margin-bottom: 24px;
      padding: 0 40px 36px 40px;
    }
    h1 {
      font-size: 62px;
    }
    h2 {
      padding: 28px 0 20px 0;
    }
  }

  @include bp1680 {
    &__menu {
      margin-right: 36px;
    }
  }
  @include bp1920 {
    &__menu {
      margin-right: 152px;
      margin-left: 36px;
    }

    &__info {
      max-width: 1020px;
    }
  }
}

