$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.cashbox-pay {
    min-height: 328px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    &__wrap {
        position: relative;
        width: 100%;
        padding-top: 20px;
    }

    &__title {
        @include text(14px, 20px, 700, $color-black);
        letter-spacing: 0.03em;
        text-align: center;
    }

    &__list {
        padding-top: 24px;
        display: grid;
        grid-template-columns: repeat(4, 1fr );
        grid-gap: 24px;
        width: 100%;

        &._crypto {
            grid-template-columns: repeat(2, 1fr );
            grid-gap: 12px;
        }
    }

    &__item {
        background: $color-gray-light;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.05), inset 0 1px 1px rgba(195, 195, 195, 0.2), inset 0 -3px 6px rgba(195, 195, 195, 0.5), inset 0 6px 6px rgba(255, 255, 255, 0.8);
        border-radius: 12px;
        padding: 14px 8px;
        text-align: center;
        cursor: pointer;

        img {
            display: inline-block;
            width: 152px;
            height: 40px;
            @include text(10px, 40px, 400, $color-black);
            @include text-dots;
            overflow: hidden;
        }

        &._crypto {
            display: flex;
            align-items: center;
            text-align: left;
            padding: 14px 20px;

            img {
                display: block;
                width: auto;
                max-width: 115px;
                height: 70px;
                margin-right: 20px;

              &:lang(ar) {
                margin-right: 0;
                margin-left: 20px;
              }
            }

            .cashbox-pay__item-data {
                text-align: right;
            }

            .cashbox-pay__item-name {
                @include text(14px, 16px, 700, $color-black);
                letter-spacing: 0.02em;
                margin-bottom: 6px;
            }

            .cashbox-pay__item-descr {
                @include text(12px, 18px, 400, $color-grey);
            }
        }
    }

  &__history {
    display: block;
    text-align: center;
    max-width: 280px;
    margin: 20px auto;
    @include text-dots;

    &:hover{
      text-decoration: none;
    }
  }

  &__nopay {
    padding: 100px 0;
    max-width: 465px;
    text-align: center;
    margin: 0 auto;

    &-title {
      @include text(32px, 40px, 700, $color-black);
      font-family: var(--font-family-main);
      text-transform: uppercase;
      margin-bottom: 24px;
    }

    &-text {
      @include text(14px, 20px, 400, $color-black);
      margin-bottom: 32px;
    }

    &-btns {
      display: flex;
      flex-direction: column;
      align-items: center;

      .btn {
        width: 300px;
        margin-bottom: 12px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
