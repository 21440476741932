$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.form-error{

  padding-left:8px;
  overflow: hidden;

  & p{
    width: 100%;
    padding-top:4px;
    @include text(12px, 12px, null, $color-red);
  }

  &.is-trustly-bnr {
    padding-left: 0;
    max-height: 34px;

    & p {
      @include text(10px, 13px);
    }
  }
}
