$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.balance-filter {
    position: relative;

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
    }

    &__desc {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__desc-i {
        @include text(14px, 25px, 500, $color-black);
        margin-inline-start: 20px;
        padding-inline-start: 24px;
        position: relative;
        @include text-dots;

        &::before {
            content: '';
            position: absolute;
            inset-inline-start: 0;
            top: 2px;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background: $color-white;
            box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.25);
        }

        &::after {
            content: '';
            position: absolute;
            inset-inline-start: 4px;
            top: 6px;
            width: 12px;
            height: 12px;
            border-radius: 50%;
        }

        &.is-real {
            &::after {
                background: $color-green;
            }
        }

        &.is-bonus {
            &::after {
                background: $color-red;
            }
        }
    }

    &__filters {
        position: absolute;
        top: -30px;
        inset-inline-start: 0;
        width: 100%;

        background: #F6F6F4;
        box-shadow: 0px 8px 20px rgba(25, 25, 29, 0.3);
        border-radius: 20px;
        z-index: 30;

        max-height: 0;
        opacity:0;
        transition:0.3s;
        overflow: hidden;

        &.is-shown{
            max-height: 3900px;
            opacity:1;
            overflow: visible;
            padding: 40px;
        }
    }

    &__btns {
        display: flex;
        align-items: center;
        margin-bottom: 40px;
    }

    &__cancel {
        @include text(18px, 20px, normal, $color-black);
        font-family: var(--font-family-main);
        text-transform: uppercase;
        margin-inline-start: 32px;
        cursor: pointer;
        text-decoration: none;
        @include text-dots;
    }

    &__gt {
        @include text(14px, 20px, normal, $color-black);
        letter-spacing: 0.03em;
        margin-bottom: 12px;
    }

    &__row {
        display: flex;
        flex-wrap: wrap;
    }

    &__btn {
        background: $color-white;
        border: 2px solid #E7E7E4;
        box-sizing: border-box;
        border-radius: 12px;
        padding: 0 20px;
        @include text(16px, 36px, bold, $color-grey);
        margin-bottom: 20px;
        margin-inline-end: 20px;
        cursor: pointer;
        display: block;
        transition: 0.2s;

        &:hover {
            background: $color-yellow;
            border-color: $color-yellow;
            color: $color-black;
        }

        &.is-datepicker{
          position:relative;
          display: inline-block;
        }
    }

    &__subtitle {
        display: none;
        @include text(14px, 20px, normal, $color-black);
        letter-spacing: 0.03em;
        margin-bottom: 12px;

        &:not(.balance-filter__subtitle_visible){
            position: absolute;
            top: -32px;
            margin-inline-start: 0;
            white-space: nowrap;
            margin-top: 0;
        }

        &_visible,
        .linda-first-in-group & {
            display: block;
        }
    }

    &__row {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
    }

    &__status {
        display: none;
        &.linda-is-active { display: block; }

        &.linda-first-in-group{
            position:relative;
            margin-top: 42px;
        }
    }

    .linda-empty-element { width: 100%; }

}
[type='checkbox']:checked + .balance-filter__btn {
    background: $color-yellow;
    border-color: $color-yellow;
    color: $color-black;
}
