$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.link-btn{
  font-size: 18px;
  line-height: 20px;
  text-transform: uppercase;
  color: #FFFFFF;
  font-family: var(--font-family-main);
  cursor:pointer;
  @include text-dots;
  &:hover{ color:$color-yellow;}

  &.is-dark{
    color: #19191D;
  }
}
