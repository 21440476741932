$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.euro-banner {
  position: relative;
  height: 320px;
  width: auto;
  margin-top: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  border-radius: 20px;
  box-shadow: 0 -4px 10px 0 #680A43 inset, 0 4px 10px 0 rgba(255, 255, 255, 0.80) inset;
  background: linear-gradient(180deg, #DA4453 0%, #89216B 100%);
  font-family: $font-roboto;

  @include bp1440 {
    height: 402px;
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: -30px;
    transform: translateY(-50%);
    width: 60px;
    height: 60px;
    background-image: url($cdn + '/fezbet/elements/circle.png');
    background-size: cover;
    z-index: 1;
  }

  &__status {
    z-index: 1;
    color: #19191d;
    border-radius: 8px;
    padding: 0 12px;
    font-family: Fez, Arial, Helvetica, sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    position: absolute;
    top: -10px;
    left: 19px;
    overflow: hidden;
    background-color: #ffdc26;
  }

  &__content {
    right: 0;
    position: absolute;
    width: 50%;
    color: $color-white;
    font-weight: 700;
    padding: 20px;
  }

  &__title {
    line-height: 140%;
    font-size: 20px;
    text-transform: uppercase;
  }

  &__name {
    line-height: 120%;
    font-size: 44px;

    @include bp1440 {
      font-size: 48px;
    }
  }

  &__price {
    color: $color-yellow;
    line-height: 110%;
    font-size: 80px;
    margin: 8px 0 16px;
  }

  &__img {
    position: absolute;
    top: -6px;
    left: 5%;
    width: 350px;
    height: 454px;
    background-image: url( $cdn + '/fezbet/tournaments/fezbet_euro.webp');
    background-repeat: no-repeat;
    background-size: contain;
    margin: 0 auto;
    background-position: center bottom;

    @include bp1280 {
      left: 10%;
    }

    @include bp1440 {
      width: 450px;
      height: 570px;
      left: 7%;
    }
  }
}
