$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.profile-nav{
  margin-top: 16px;

  &__item{
    display: block;
    height: 73px;
    padding-top:16px;
    background-image: linear-gradient( to right , var(--grLineBlack)) , linear-gradient(to right , var(--grLineWhite)) ;
    background-size: 100% 1px , 100% 1px;
    background-repeat: no-repeat;
    background-position: center bottom 1px , center bottom ;
  }

  &__link{
    display: flex;
    align-items: center;
    width: 100% ;
    height: 40px;
    line-height: 40px;
    font-weight: bold;
    font-size: 18px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    font-family: var(--font-family-main);
    color: rgba(134, 138, 146, 0.6);
    @include text-dots;
    transition: 0.2s;

    &:lang(at),
    &:lang(de),
    &:lang(es),
    &:lang(es-CL),
    &:lang(es-PE),
    &:lang(it),
    &:lang(fr-CA),
    &:lang(pt-BR),
    &:lang(no),
    &:lang(ru){
      font-size: 16px;
      letter-spacing: 0;
    }

    &:lang(el),
    &:lang(hu) {
      font-size: 14px;
      letter-spacing: 0;
    }

    &::before{
      flex-shrink: 0;
      content: '';
      width: 6px;
      height: 6px;
      margin-right:14px;
      border-radius: 50%;
      background-image: radial-gradient( #2B2B30 0px , #2B2B30 2px ,  #6B6B6E 2px , #6B6B6E 3px , rgba(0,0,0,0) 3px );
    }

    &:hover{
      color:#fff;
      &::before{
        background-image: radial-gradient( #2B2B30 0px , #2B2B30 2px ,  #FFF 2px , #FFF 3px , rgba(0,0,0,0) 3px );
      }
    }

    &.active{
      font-weight: bold;
      font-size: 24px;
      color: #FFFFFF;

      &:lang(at),
      &:lang(de),
      &:lang(es),
      &:lang(es-CL),
      &:lang(es-PE),
      &:lang(fr-CA),
      &:lang(pt-BR),
      &:lang(it),
      &:lang(no),
      &:lang(ru){
        font-size: 16px;

        @include bp1440 {
          font-size: 20px;
        }
      }

      &:lang(hu),
      &:lang(el) {
        font-size: 14px;
        letter-spacing: 0;

        @include bp1440 {
          font-size: 16px;
        }
      }

      &::before{
        background-image: radial-gradient( #FFF 0px , #FFF 2px ,  #999 2px , #999 4px , rgba(0,0,0,0) 5px );
        width: 8px;
        height: 8px;
      }
    }
  }

  &__bonus-count {
    @include text-dots;
    @include text(16px,  24px, bold, $color-black);

    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: $color-yellow;
    text-align: center;
    flex-shrink: 0;
    margin: 0 8px;
  }

  &__logout{
    display: flex;
    justify-content: center;
    padding-top:25px;
  }
}
