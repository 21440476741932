$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 480px;
  margin: auto;
  padding: 160px 0;
  text-align: center;

  &__title {
    @include text(28px, 32px, normal, $color-white);

    padding-bottom: 40px;
    letter-spacing: 0.03em;
  }

  &__number {
    @include text(140px, 120px, bold, transparent);

    background: linear-gradient(180deg, #FFF0A2 0%, #F0AA17 100%);
    -webkit-background-clip: text;
    padding-bottom: 32px;
    letter-spacing: 0.03em;
  }

  &__description {
    @include text(16px, 20px, normal, $color-grey);
    padding-bottom: 40px;
    letter-spacing: 0.03em;
  }
}
