$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.popup__wrap.shop-choose {
  padding: 55px 40px 60px;

  .popup__title {
    font-family: 'Roboto Condensed', Helvetica, Arial, sans-serif;
    text-transform: none;
    margin-bottom: 16px;
  }
}

.shop-choose-wrapper {
  border-radius: 28px;
  background-color: $color-grey-dark;
  padding: 8px;
}

.shop-choose-amount {
  padding: 18px;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  background-color: $color-white;
  color: $color-black;
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;

  &__image {
    margin-right: 16px;
    height: 200px;
    width: 134px;
    border-radius: 12px;
    background-position: center;
    background-size: contain;
    box-shadow: 0 4px 8px 0 rgba(25, 25, 29, 0.5);
    flex-shrink: 0;
    display: flex;
    cursor: pointer;

    &:lang(ar) {
      margin-right: 0;
      margin-left: 16px;
    }
  }

  &__game-name {
    font-family: 'Roboto Condensed', Helvetica, Arial, sans-serif;
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 16px;
    text-transform: uppercase;
    color: $color-black;
  }

  &__info {
    font-weight: 700;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: $color-grey-dark;
    font-size: 14px;
    line-height: 14px;
  }

  &__price-value {
    margin-top: 6px;
    display: flex;
    align-items: center;

    span {
      direction: ltr;
    }
  }
}

.shop-fs-list {
  display: grid;
  width: 100%;
  grid-template-columns: 100%;
  grid-auto-columns: 56px;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: $color-white;

  &__item {
    display: grid;
    grid-template-columns: 2fr 1fr min-content;
    gap: 8px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 12px 16px;

    &.is-low-balance {
      pointer-events: none;
      filter: opacity(0.4);
    }

    &:not(:last-child) {
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }
  }

  &__item-title {
    display: flex;

    span {
      margin-inline-start: 5px;
    }
  }

  &__item-price {
    color: $color-grey;
    width: max-content;
    display: flex;
    justify-self: flex-end;
    align-items: center;

    &-icon {
      display: block;
      width: 20px;
      height: 20px;
      margin: 0 4px;
      background-image: url($cdn + '/fezbet/shop/shop-coin.webp');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  &__item-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    background-color: rgba(255, 220, 38, 0.1);
    border-radius: 50%;

    &-icon {
      width: 16px;
      height: 16px;
      background-image: var(--icon-i-plus);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}

.shop-confirm-fs {
  color: $color-black;

  &__subtitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 16px;
    text-align: center;
    letter-spacing: 0.4px;
  }

  &__game {
    position: relative;
    display: flex;
    height: 233px;
    width: 100%;
    background-color: $color-white;
    border-radius: 20px;
    justify-content: space-between;
    margin-bottom: 16px;
    padding: 16px;
    box-shadow: 0 9px 30px 0 rgba(0, 0, 0, 0.1);
  }

  &__card {
    margin-right: 16px;
    width: 134px;
    height: 201px;
    background-position: bottom left;
    background-size: contain;
    background-repeat: no-repeat;
    box-shadow: 0 4px 8px 0 rgba(25, 25, 29, 0.5);
    display: flex;
    border-radius: 12px;
    flex-shrink: 0;
    cursor: pointer;

    &:lang(ar) {
      margin-right: 0;
      margin-left: 16px;
    }
  }

  &__content {
    font-size: 14px;
    font-weight: 700;
    width: 100%;
    line-height: 14px;
    display: flex;
    flex-direction: column;
    color: $color-grey-dark;
  }

  &__game-link {
    font-family: 'Roboto Condensed', Helvetica, Arial, sans-serif;
    margin-bottom: 16px;
    color: $color-black;
    pointer-events: auto;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    line-height: 32px;
    font-size: 24px;
  }

  &__offer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-family: 'Roboto Condensed', Helvetica, Arial, sans-serif;
  }

  &__offer-cost {
    position: relative;
    padding-left: 36px;

    &::before {
      content: "";
      position: absolute;
      left: 8px;
      top: 50%;
      transform: translateY(-50%);
      width: 24px;
      height: 24px;
      background-image: url($cdn + '/fezbet/shop/shop-coin.webp');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  &__price {
    display: flex;
    align-items: center;

    &._first-step {
      margin-top: 6px;
    }
  }

  &__btns_vertical {
    display: grid;
    grid-gap: 16px;
    justify-content: center;

    a {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: $color-grey;
      text-align: center;
    }
  }

  &__btn-item {
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }

  &__new-bonus-type {
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 16px;
    color: #666666;
    text-align: center;
  }

  &__arrows {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-image: url($cdn + '/fezbet/shop/shop-arrows-bottom.webp');
    width: 48px;
    height: 30px;
    margin: 8px auto;
  }

  &__sums {
    position: relative;
    width: 100%;
    height: 214px;
    border: 4px solid $color-red;
    border-radius: 16px;
    padding: 24px 20px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;

    &._active {
      border-color: $color-yellow;
    }

    &._new {
      margin-bottom: 16px;
    }
  }

  &__sums-ovarlay {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: linear-gradient(#2d2d32 0%, #2d2d3299 100%);
  }

  &__sums-image {
    position: absolute;
    left: -4px;
    top: -4px;
    width: calc(100% + 8px);
    height: calc(100% + 8px);
    pointer-events: none;
    filter: blur(2px);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    &.is-casino,
    &.is-exchange {
      background-image:  url( $cdn + '/fezbet/bonuses/casino.jpg');
    }

    &.is-sport,
    &.is-freebet {
      background-image:  url( $cdn + '/fezbet/bonuses/sport.jpg');
    }
  }

  &__bonus-type {
    position: relative;
    width: 32px;
    height: 32px;
    filter: $filter-white;
    margin-bottom: 8px;
    background-repeat: no-repeat;
    background-position: center;;

    &.is-casino,
    &.is-freespin,
    &.is-exchange {
      background-image: var(--icon-type-casino);
    }

    &.is-sport,
    &.is-freebet {
      background-image: var(--icon-type-sport);
    }
  }

  &__bonus-amount {
    font-family: 'Roboto Condensed', Helvetica, Arial, sans-serif;
    font-weight: 700;
    font-size: 48px;
    line-height: 52px;
    color: $color-yellow;
    margin-bottom: 10px;
    position: relative;
    text-align: center;
  }

  &__bonus-name {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: $color-white;
    position: relative;
    display: flex;
    justify-content: center;
    font-family: $font-alternative;

    .freespin-fs-link {
      color: $color-yellow;
      margin-left: 4px;
      text-decoration: underline;
    }
  }

  &__bonus-price {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: $color-white;
    position: relative;
    display: flex;
    margin-top: 4px;
    justify-content: center;
    font-family: $font-alternative;
  }

  &__label {
    position: absolute;
    width: 115px;
    height: 31px;
    @include text-dots;
    transform: rotate(-45deg);
    transform-origin: left bottom;
    left: 0;
    top: 50px;
    line-height: 31px;
    font-weight: 700;
    font-size: 16px;
    padding: 0 21px;
    text-align: center;
    text-transform: uppercase;
    font-family: var(--font-family-main);

    &.new {
      color: $color-white;
      background: $color-red;
    }

    &.active {
      color: $color-black;
      background: $color-yellow;
    }
  }

  &__count {
    font-family: 'Roboto Condensed', Helvetica, Arial, sans-serif;
    align-items: center;
    justify-content: center;
    display: flex;
    color: $color-black;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-transform: uppercase;
    margin-bottom: 28px;

    letter-spacing: 0.5px;

    &-icon {
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      background-image: url($cdn + '/fezbet/shop/shop-coin.webp');
      display: block;
      width: 24px;
      height: 24px;
      margin: 0 4px 0 8px;
    }
  }
}
