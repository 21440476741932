$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.bnr-reg {
  width: 372px;
  min-height: 136px;
  border-radius: 20px;
  border: 3px solid #A4771C;
  position: relative;
  z-index: 3;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: linear-gradient(90deg, #924215 0%, rgba(0, 0, 0, 0.7) 100%);
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;

  &__top {
    @include text(32px, 64px, bold, transparent);
    @include text-dots;

    background: $color-yellow;
    font-family: var(--font-family-main);
    padding: 0 48px;
    border-radius: 20px 20px 0 0;
    height: 0;
    opacity: 0;
    text-align: center;
    position: relative;
    z-index: 1;
    width: calc(100% + 6px);
    left: -3px;
    top: -3px;
    max-width: none;

    &:lang(ar) {
      left: 3px;
    }
  }

  &__description {
    background-color: #A4771C;
    padding: 0 16px;
    opacity: 0;
    text-align: center;
    color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-transform: uppercase;
    position: relative;
    top: -3px;
    width: 100%;
    height: 0;
  }

  &__text {
    @include text(18px, 22px, bold);

    font-family: var(--font-family-main);
  }

  &__info {
    max-height: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
  }

  &__item {
    max-width: 220px;
    width: fit-content;
  }

  &__title {
    @include text(16px, 24px, bold, #E7E7E4);
    @include text-dots;

    letter-spacing: 0.03em;
    text-shadow: 0 4px 15px rgba(40, 39, 42, 0.1);
    font-family: var(--font-family-main);

    padding-inline-start: 32px;
    padding-top: 8px;
    height: auto;
  }

  &__subtitle {
    @include text(30px, 32px, bold);

    text-shadow: 0 4px 15px rgba(40, 39, 42, 0.1);
    font-family: var(--font-family-main);

    padding-inline-start: 32px;
    z-index: 2;
  }

  &__img {
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: 0px;
    position: absolute;
    width: 168px;
    height: 168px;
    right: 16px;
    z-index: -1;
    background-image: url($cdn + '/fezbet/popups/registration.png');
    top: 41%;
    transform: translateY(-47%);

    &:lang(ar) {
      right: unset;
      left: 0;
      transform: translateY(-47%) scale(-1,1);
    }
  }

  &__block-btns {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 214px;
    z-index: 2;
    padding: 0 32px 8px;
  }

  &__btn {
    opacity: 0;
    height: 0;
  }
}
