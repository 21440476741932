$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.halloween-wrapper {
  margin-bottom: -60px;

  .halloween-main {
    min-width: 1024px;
    padding-bottom: 170px;
  }

  .halloween-main__bottom__tournament {
    top: -130px;
    right: 90px;
  }

  .halloween {
    min-height: 920px;
  }
}
