$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.promos-menu {
  display: inline-grid;
  width: 132px;
  gap: 76px;

  background-repeat: no-repeat;
  background-image:
      linear-gradient(180deg, var(--grLineWhite)),
      linear-gradient(180deg, var(--grLineBlack));
  background-position: 11px 12px, 12px 12px;
  background-size: 1px calc(100% - 24px);

  &:lang(ar) {
    background-position: 120px 12px, 121px 12px;
  }

  &__item {
    position: relative;
    font-family: var(--font-family-main);
    @include text(14px, 24px, 700, rgba($color-white, .3));
    letter-spacing: .03em;
    text-transform: uppercase;
    padding-left: 28px;
    cursor: pointer;
    transition: 0.2s;
    @include text-dots;

    &:lang(ar) {
      padding-right: 28px;
      padding-left: 0;

      &::before {
        left: unset;
        right: 9px;
      }
    }

    &::before {
      content: '';
      position: absolute;
      top: calc(50% - 4px);
      left: 9px;
      display: inline-block;
      width: 6px;
      height: 6px;
      background-image: var(--grCircleDark);
      border-radius: 100%;
    }

    &.active,
    &:hover {
      color: $color-white;
      font-size: 18px;

      &::before {
        background-image: var(--grCircleLight);
        width: 8px;
        height: 8px;
        left: 8px;
      }

      &:lang(ar) {
        &::before {
          left: unset;
          right: 8px;
        }
      }
    }
  }
}
