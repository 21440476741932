$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.link-category {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12px;
    overflow: hidden;
    background: rgba(255, 220, 38, 0.1);
    font-family: var(--font-family-main);
    border-radius: 12px;
    min-height: 267px;

    &__title {
        @include text(16px, 24px, bold, $color-white);
        letter-spacing: 0.05em;
        text-transform: uppercase;
        @include text-dots;
        padding-bottom: 8px;
        max-width: 100%;
    }
    &__sum {
        @include text(48px, 48px, bold, $color-yellow);
        @include text-dots;
        max-width: 100%;
    }
    &__text {
        @include text(28px, 36px, bold, $color-yellow);
        @include text-dots;
        padding-bottom: 20px;
        max-width: 100%;
        text-transform: capitalize;
    }
    &__arr {
        background-color: #FFDC26;
        box-shadow: inset 0 8px 6px rgba(255, 255, 255, 0.8), inset 0px -8px 10px #FF7A00;
        background-image: var(--icon-arrow-left);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 34px;
        width: 68px;
        height: 68px;
        border-radius: 50%;
        transform: rotate(180deg);
    }

    @include bp1440 {
        padding: 16px;
        &__title {
            font-size: 18px;
            padding-bottom: 16px;
        }
        &__sum {
            @include text(64px, 64px);
        }
        &__text {
            @include text(36px, 44px);
            padding-bottom: 20px;
        }
        &__arr {
            width: 80px;
            height: 80px;
            background-size: 40px;
            box-shadow: inset 0px 8px 10px rgba(255, 255, 255, 0.8), inset 0px -8px 10px #FF7A00;
        }
    }

}
