$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.cashbox-balance{
  width: fit-content;
  border-radius: 12px;

  display: flex;
  justify-content: center;

  margin-bottom:30px;
  background-color: #FFFFFF;
  margin: 0 auto;
  &__item{
    width: 248px;
    text-align: center;
    padding:12px;
    color: #19191D;
    &:nth-child(2),
    &:nth-child(3) {
      background-image: linear-gradient( to top , var(--grLineBlack) );
      background-position: left center;
      background-repeat: no-repeat;
      background-size: 1px 70%;
    }
  }

  &__sum{
    font-family: var(--font-family-main);
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;

    .rtl-lang & {
      direction: ltr;
    }
  }
  &__label{
    margin-top:4px;
    font-size: 13px;
    line-height: 20px;
  }

  &__i{

    position:relative;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: $color-red;
    margin-left: 4px;
    &::before{
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background-image: var(--icon-info);
      filter:$filter-white;
    }
  }



}
