$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.sitemap {
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  &__item {
    display: flex;
    align-items: center;
    height: 56px;
    background-size: 32px;
    background-repeat: no-repeat;
    background-position: left center;
    cursor: pointer;
    padding-inline-start: 40px;

    &:lang(ar) {
      background-position: 100%;
    }

    &.provider__item {
      padding-inline-start: 0;

      img {
        display: block;
        width: 32px;
        height: 32px;
        padding: 6px;
        margin-inline-end: 8px;
        background-color: rgba(65, 65, 65, 1);
        border-radius: 8px;
        box-shadow: 0 -4px 6px 0 rgba(34, 34, 34, 1) inset, 0 4px 4px 0 rgba(255, 255, 255, 0.23) inset;
      }
    }
  }

  &__item:hover &__title {
    color: $color-grey;
  }

  a.sitemap__item {
    text-decoration: none;
  }

  &__title {
    @include text(14px, 20px, bold, $color-black);
  }
}
