$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.slider-nav{

  position:relative;
  height: 168px;

  background-image: linear-gradient( to top , var(--grLineWhite) ) , linear-gradient( to top , var(--grLineBlack) );
  background-size: 1px 100%;
  background-repeat: no-repeat;
  background-position: 15px center , 16px center;

  &__i{
    position: relative;
    font-size: 14px;
    line-height: 24px;
    font-weight: 700;
    letter-spacing: .03em;
    text-transform: uppercase;
    color: rgba(255,255,255,.3);
    padding-left: 28px;
    cursor: pointer;
    font-family: var(--font-family-main);

    transition: 0.3s;

    &:not(.is-last){ margin-bottom:48px; }

    &::before{
      content: '';
      position: absolute;
      top: calc(50% - 4px);
      left: 12px;
      display: inline-block;
      width: 6px;
      height: 6px;
      background-image: var(--grCircleDark);
      border-radius: 100%;
    }

    .slick-current &{
      transition: 0.3s;
      color:#fff;
      font-size: 18px;
      &::before{
        background-image: var(--grCircleLight);
        width: 8px;
        height: 8px;
        left: 11px;
      }
    }

  }
  & .slick-track{
    height: 168px;
    transform: translate3d(0,0,0) !important;
  }


  @include bp1440 {

    &__i{
      &:not(.is-last){ margin-bottom:76px; }
    }
  }
}
