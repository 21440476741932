$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.popup__terms {
  min-width: 620px;

  &-title {
    margin-bottom: 20px;
    color: $color-black;
    text-align: center;
    font-size: 32px;
    font-family: $ff;
    font-weight: 700;
    line-height: 120%;
    text-transform: uppercase;
  }

  &-list {
    list-style: decimal;
    margin-bottom: 84px;
    padding-left: 24px;
  }

  &-item {
    margin-bottom: 8px;
    padding-left: 20px;
    font-size: 16px;
    line-height: 140%;

    span {
      white-space: nowrap;
    }

    &::marker {
      font-weight: 700;
    }
  }

  &-btns {
    text-align: center;
  }
}
