$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.widget-sport {
  cursor: pointer;
  background: $color-black;
  border: 1px solid rgba(255, 220, 38, 0.5);
  border-radius: 20px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  &__img-block {
    margin-bottom: 12px;
    display: flex;
  }

  &__img {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: 5px solid rgba(255, 220, 38, 0.5);
    &.is-right {
      transform: translate(-5px);
    }
  }
  &__data {
    @include text(18px, 12px);
    letter-spacing: 0.03em;
    @include text-dots;
  }
  &__time {
    @include text(20px, 24px, bold, $color-yellow);
    letter-spacing: 0.03em;
    text-transform: uppercase;
    margin-bottom: 4px;
    @include text-dots;
  }
  &__league {
    @include text(10px, 16px);
    letter-spacing: 0.03em;
    margin-bottom: 4px;
    @include text-dots;
  }
  &__team {
    @include text(10px, 16px, bold, $color-yellow);
    letter-spacing: 0.03em;
    margin-bottom: 4px;
    @include text-dots;
  }
  &__coeff-block {
    margin-top: 4px;
    display: grid;
    grid-template-columns: repeat(3 , 1fr);
    grid-gap: 4px;

  }
  &__coeff-item {
    background-color: #2D2D32;
    border-radius: 12px;
    padding: 4px;
    @include text(10px, 12px, null, $color-grey);
    text-align: center;
    overflow: hidden;
    & span {
      @include text-dots;
      display: block;
    }
    &:hover {
      background-color: $color-grey;
      color: $color-white;
    }
  }
  &__coeff-span {
    font-weight: bold;
    color: $color-white;
    margin-top: 4px;
  }


  @include bp1440 {
    &__img {
      width: 40px;
      height: 40px;
      border-width: 6px;
      margin-bottom: 11px;

      &.is-right {
        transform: translate(-6px);
      }
    }
    &__time {
      @include text(24px, 28px);
      margin-top: 4px;
      margin-bottom: 12px;
    }
    &__coeff-block {
      margin-top: 8px;
      grid-gap: 8px;
    }
    &__coeff-item {
      @include text(12px, 16px);
    }
  }
}
