$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.link-next {
    width: 48px;
    height: 48px;
    background-color: rgba(255, 220, 38, 0.1);
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50%;
    transition: background-color .2s;

    &:lang(ar) {
        transform: scale(-1, 1);
    }

    &:hover {
        background-color: rgba(255, 220, 38, 0.5);
        transition: background-color .2s;
    }
}
