$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.gh-fvstub{
  position:relative;
  border: 1px dashed #868A92;
  border-radius: 12px;
  box-shadow: none;
  font-size: 14px;
  line-height: 20px;
  color: #868A92;

  &::before{
    content: '';
    display: block;
    padding-top: 150%;
  }

  &__wrap{
    position:absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }

  &__title{
    text-align: center;
    max-height: 120px;
    overflow: hidden;
  }

  &__icon{
    margin:12px;
    width: 24px;
    height: 24px;
    background-image: var(--icon-favourite-add);
    filter: brightness(0) $filter-grey-light;
  }
}
