$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.form-box {
  position: relative;
  max-width: 100%;

  &:not(.is-cashbox) + .form-box,
  .link-dashed + & {
    margin-top: 10px;
  }

  &.is-cashbox:not(.cashbox-form__box){
    width: 300px;
    margin-bottom: 10px;
    &:nth-child(2n + 2) {
      margin-left: 20px;
    }
  }
  &.is-trustly {
    margin-left: 60px;
    margin-right: 60px;
    max-width: fit-content;
  }

  &.is-trustly-banner {
    margin-bottom: 8px;
  }
}