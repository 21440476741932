$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.icon-box {
  display: flex;
  flex-wrap: wrap;

  &__partners {
    height: 32px;
    margin-right: 20px;
    margin-bottom: 12px;
    max-width: 172px;

    &.is-hidden {
      display: none;
    }
  }

  &__payments {
    width: 88px;
    height: 24px;
    margin-right: 20px;
    margin-bottom: 12px;

    @include bp1440 {
      margin-right: 10px;
    }

    @include bp1680 {
      margin-right: 30px;
    }

    @include bp1920 {
      margin-right: 45px;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__trustly {
    width: 88px;
    height: 24px;
    margin-bottom: 8px;
    margin-right: 8px;
    opacity: 0.5;

    .trustly-page__icon-box & {
      margin-bottom: 16px;
    }
  }

  &__img {
    height: 100%;
    object-fit: contain;
    font-size: 10px;
    word-break: break-all;
    overflow: hidden;
  }

  &:hover {
    cursor: pointer;
  }

  .linda-has-remembered-login & {
    &.wasnt-logged {
      display: none;
    }

    &.was-logged {
      display: flex;
    }
  }

  &.was-logged {
    display: none;
  }
}
