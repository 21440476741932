.verification-content {
  background: #ffffff;
  font-size: 16px;
  border-bottom: 1px solid rgba(140, 135, 179, 0.2);
  padding: 20px 20px 20px 88px;
  position: relative;

  &.has-no-status {
    &:after {
      content: none;
    }
  }

  body.rtl-lang & {
    padding: 20px 88px 20px 20px;
    direction: rtl;
    &:before {
      right: 22px;
      left: 0;
    }
    &:after {
      right: 56px;
      left: 0;
    }
  }

  &:last-child {
    border-bottom: none;
  }

  &:before {
    background: #faf9ff no-repeat 50% 50%;
    border: 1px solid #eeecff;
    content: '';
    display: inline-block;
    height: 48px;
    left: 22px;
    position: absolute;
    top: 18px;
    width: 48px;
  }

  &:after {
    background: #ffffff no-repeat 0 0;
    background-size: cover;
    border-radius: 50%;
    content: '';
    display: inline-block;
    height: 22px;
    left: 56px;
    position: absolute;
    top: 10px;
    width: 22px;
  }

  &__title {
    color: #1f2126;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
    padding-bottom: 6px;
  }

  &__text {
    color: #524E72;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  &__status {
    font-style: italic;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    padding: 0 0 12px 0;
  }

  &__details {
    ul {
      list-style-type: disc;
      padding: 0 0 3px 24px;
      li {
        padding: 0 0 8px 0;
      }
    }
    p {
      padding: 0 0 6px 0;
    }
  }

  &__comment {
    background: rgba(251, 192, 45, 0.08);
    border: 1px solid rgba(251, 192, 45, 0.5);
    font-style: italic;
    margin: 0 0 12px 0;
    display: flex;
    flex-direction: column;
    padding: 10px 16px;
    overflow-wrap: anywhere;

    strong {
      font-weight: 600;
      padding-right: 6px;
    }
    ol {
      padding-left: 15px;
    }
    ul {
      list-style: disc;
      padding-left: 15px;
    }
  }

  &_proof_of_identity {
    &:before {
      background-image: url($cdn + '/dvs/i-identity-document.svg');
    }
  }

  &_proof_of_address {
    &:before {
      background-image: url($cdn + '/dvs/i-proof-address.svg');
    }
  }

  &_card_photo {
    &:before {
      background-image: url($cdn + '/dvs/i-card-photo.svg');
    }
  }

  &_proof_of_bank_account_owner {
    &:before {
      background-image: url($cdn + '/dvs/i-proof-bank.svg');
    }
  }

  &_e_wallet_ownership {
    &:before {
      background-image: url($cdn + '/dvs/i-proof-wallet.svg');
    }
  }

  &_transaction_history {
    &:before {
      background-image: url($cdn + '/dvs/i-transaction-history.svg');
    }
  }

  &_selfie_photo,
  &_additional {
    &:before {
      background-image: url($cdn + '/dvs/i-selfie-photo.svg');
    }
  }

  &_proof_of_wealth {
    &:before {
      background-image: url($cdn + '/dvs/i-proof-wealth.svg');
    }
  }

  &_proof_wallet {
    &:before {
      background-image: url($cdn + '/dvs/i-proof-wallet.svg');
    }
  }

  &_survey {
    &:before {
      background-image: url($cdn + '/dvs/i-survey.svg');
    }
  }

  &_not_requested,
  &_not-uploaded {
    &:after {
      background-image: url($cdn + '/dvs/i-status-not-verified.svg');
    }
    .verification-content__status {
      color: #55c9ff;
    }
  }

  &_requested {
    &:after {
      background-image: url($cdn + '/dvs/i-status-requires.svg');
    }
    .verification-content__status {
      color: #fbc02d;
    }
  }

  &_declined {
    &:after {
      background-image: url($cdn + '/dvs/i-status-reload.svg');
      background-position: -3px -3px;
      background-size: 28px;
    }
    .verification-content__status {
      color: #fbc02d;
    }
  }

  &_pending {
    &:after {
      background-image: url($cdn + '/dvs/i-status-pending.svg');
    }
    .verification-content__status {
      color: #ffa000;
    }
  }

  &_approved {
    &:after {
      background-image: url($cdn + '/dvs/i-status-verified.svg');
    }
    .verification-content__status {
      color: #00c853;
    }
  }

  &_survey {
    &:before {
      background-image: url($cdn + '/dvs/i-survey-rg.svg');
    }
    &.is-responsible_gambling {
      &:before {
        background-image: url($cdn + '/dvs/i-survey-rg.svg');
      }
    }
    &.is-anti_money_laundering {
      &:before {
        background-image: url($cdn + '/dvs/i-survey-aml.svg');
      }
    }
  }

  &__pending-description {
    margin-bottom: 12px;
  }
}
