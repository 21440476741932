$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.widget-events-home {

  &__wrap {
    background-color: rgba(255, 220, 38, 0.1);
    height: 96px;
    display: flex;
    align-items: center;
    overflow-x: scroll;
  }

  &__item {
    display: flex;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;

    &:first-of-type {
      padding-left: 24px;
    }

    &:not(:last-of-type) {
      background-image: linear-gradient(to bottom, rgba(234, 234, 234, 0) 0, rgba(234, 234, 234, 0.3) 51%, rgba(234, 234, 234, 0) 100%, rgba(234, 234, 234, 0) 100%);
      background-repeat: no-repeat;
      background-size: 1px 72px;
      background-position: right center;
    }
  }

  &__content {
    width: 168px;
    overflow: hidden;
  }

  &__amount {
    @include text-dots;
    @include text(24px, 28px, bold, $color-yellow);
    margin-bottom: 8px;
  }

  &__name {
    @include text-dots;
    @include text(14px, 28px, null, $color-white);
  }

  &__link {
    text-decoration: underline;
    color: $color-yellow;
  }

  &__img {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-left: 12px;
    flex-shrink: 0;
    width: 100px;
    height: 66px;
    border-radius: 8px;
    cursor: pointer;
  }

}
