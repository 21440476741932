$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.link-dashed {
  @include text(14px, 20px, null, #868A92);
  cursor: pointer;
  border-bottom: 1px dashed #868A92;
  width: fit-content;
  @include text-dots;

  &:hover {
    color: $color-black;
    border-bottom: 1px dashed $color-black;
  }
}
