$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.form-error,
.ng-untouched+.form-label .form-error,
.ng-valid+.form-label .form-error,
.datepicker-open+.form-label .form-error {
  display: none
}

.ng-invalid:focus+.form-label .form-error {
  display: none !important;
}

.ng-invalid.datepicker-open+.form-label .form-error {
  display: none;
}

.ng-invalid.ng-touched+.form-label .form-error {
  display: block;
}

.form-check.ng-untouched+label+.form-label .form-error {
  display: block;
}

.form-check.ng-touched.ng-invalid+label+.form-label .form-error {
  display: block;
}

.form-check.ng-dirty.ng-invalid+label+.form-label .form-error {
  display: block;
}


.g-minlength,
.g-maxlength,
.g-pattern,
.g-patternLetter,
.g-required,
.g-min,
.g-max,
.g-passwordVer {
  display: none;
}

.ng-invalid-minlength+.form-label .form-error .g-minlength,
.ng-invalid-maxlength+.form-label .form-error .g-maxlength,
.ng-invalid-min+.form-label .form-error .g-min,
.ng-invalid-max+.form-label .form-error .g-max,
.ng-invalid-pattern+.form-label .form-error .g-pattern,
.ng-invalid-postcode+.form-label .form-error .g-pattern,
.ng-invalid-letter+.form-label .form-error .g-patternLetter,
.ng-invalid-required+.form-label .form-error .g-required,
.ng-invalid-required+.form-label .form-error .g-required,
.ng-invalid-number+.form-label .form-error .g-pattern,
.ng-invalid-email+.form-label .form-error .g-pattern,
.ng-invalid-parse+.form-label .form-error .g-pattern {
  display: block;
}


.ng-invalid-password-verify+.form-label .form-error .g-passwordVer {
  display: block;
}

.ng-invalid-password-verify+.form-label .form-error {
  display: block;
}

//.ng-invalid-password-verify + .g-form-label .g-form-error .g-passwordVer{ display:flex;}
//.ng-invalid-password-verify + .g-form-label .g-form-error { display:flex;}

.form-check.ng-invalid+label+.form-label .g-required {
  display: block;
}



/* EXCHANGE VALIDATION */


.g-pay-min,
.g-pay-max,
.g-amount,
.g-pay-required {
  display: none;
}


.is-exchange.ng-invalid-required+.form-label .form-error .g-pay-required,
.is-exchange.ng-invalid-min+.form-label .form-error .g-pay-min,
.is-exchange.ng-invalid-max+.form-label .form-error .g-pay-max,
.is-exchange.ng-invalid-amount+.form-label .form-error .g-amount {
  display: block;
}

// registration btn
.registration__step.ng-invalid .btn.btn-44-yellow {
  opacity: 0.2;
  pointer-events: none;
}
