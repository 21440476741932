$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.home-reg {
  background-color: rgba(45, 45, 50, 0.5);
  background-image: url( $cdn + '/fezbet/main/reg.png' ), url( $cdn + '/fezbet/main/gradient-reg.png' );
  background-size: auto 100%, 100% 75px;
  background-position: right 588px center, center bottom;
  background-repeat: no-repeat;
  border-radius: 20px;
  display: flex;
  justify-content: flex-end;
  min-height: 312px;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    background-image: url( $cdn + '/fezbet/main/dark-circle.png' );
    background-size: 264px;
    background-position: center;
    background-repeat: no-repeat;
    width: 264px;
    height: 264px;
    bottom: -70px;
    right: -235px;
  }

  &__wrap {
    width: 540px;
    padding: 24px 32px 24px 16px;
    box-sizing: content-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__title {
    @include text-dots;
    @include text(36px, 42px, bold, $color-yellow);
    font-family: var(--font-family-main);
    padding-bottom: 20px;
  }

  &__form {
    overflow: hidden;

    &.is-white {
      & .form-name,
      & .form-check__text {
        color: $color-white;
      }
      & .link-dashed:hover {
        color: $color-white;
        border-bottom: 1px dashed $color-white;
      }

    }
  }
  &__form-block {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 24px;
    max-height: 102px;
    overflow: hidden;
  }
  &__btn {
    margin-top: 36px;
  }

  @include bp1440 {
    background-position: right 697px center, center bottom;
    min-height: 360px;

    &__wrap {
      width: 584px;
      padding: 36px 44px 36px 69px;
    }
    &__title {
      @include text(44px, 56px);
    }
  }

}
