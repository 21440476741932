$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.form-name {
  @include text(12px, 16px, null, $color-black);

  padding-inline-start: 8px;
  padding-bottom: 4px;

  &.is-big{
    @include text(14px, 20px, null, $color-black);
  }
}
