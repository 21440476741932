$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.cash-crab-wrap{
  min-height: calc(100vh - 72px);
  width: 100%;
  display: flex;
  & .crab-area-73aos {
    min-height: 100%;
    height: auto !important;
    display: flex;
  }
}