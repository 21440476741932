$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.popup-random-box {
  position: relative;
  margin-bottom: 24px;
  margin-top: 20px;
  height: 300px;

  &.has-preloader {
    > * {
      visibility: hidden;
    }
  }

  &__game {
    position: absolute;
    width: 200px;
    height: 300px;
    border-radius: 12px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    perspective: 1000px;

    top: 0;
    bottom: 0;
    margin: auto;
    left: 0;
    right: 0;
  }
}
