$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.registration {
  background: $color-gray-light;
  box-shadow: 0 4px 8px rgba(25, 25, 29, 0.5);
  border-radius: 20px;
  overflow: hidden;
  height: 100%;
  color: $color-black;
  display: flex;
  flex-direction: column;
  min-height: 460px;

  &__title {
    @include text(32px, 44px, bold);
    @include text-dots;

    background-color: $color-yellow;
    font-family: var(--font-family-main);
    padding: 10px;
    text-align: center;
  }

  &__step {
    padding-left: 48px;
    padding-right: 48px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding-top: 36px;
    min-height: 0;
  }

  &__accept {
    padding-top: 80px;
  }

  &__input-wrap {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  &__input-50 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px;
    align-items: baseline;
    margin-bottom: 10px;
  }

  & .link-dashed {
    margin-top: 28px;
    align-self: center;
  }

  &__link {
    padding-top: 42px;
    padding-left: 48px;

    &:lang(ar) {
      padding-left: 0;
      padding-right: 48px;
    }
  }

  &__currency {
    margin-left: 16px;
    min-width: 100px;
    margin-bottom: 20px;

    &:lang(ar) {
      margin-left: 0;
      margin-right: 16px;
    }
  }

  &__btn {
    margin-top: auto;
    margin-bottom: 52px;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      margin-top: 28px;
    }
  }
}
