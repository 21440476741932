$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.menu-link {
  @include text(18px, 133%, bold, $color-yellow-dark);

  position: relative;
  padding-inline-start: 24px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  font-family: var(--font-family-main);
  cursor: pointer;

  &::before {
    content: "";
    position: absolute;
    width: 6px;
    height: 6px;
    border: 1px solid #6B6B6E;
    background-color: transparent;
    border-radius: 50%;
    inset-inline-start: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  &:hover,
  &.active:not(.sidemenu__link),
  &:active:not(.sidemenu__link) {
    color: $color-white;
    opacity: 1;

    &::before {
      border: 1px solid $color-white;
    }
  }

  &.active:not(.sidemenu__link),
  &:active:not(.sidemenu__link) {
    @include text(24px, 100%, bold, $color-white);

    &::before {
      width: 8px;
      height: 8px;
      border: 2px solid #999999;
      background-color: $color-white;
    }

  }
}
