$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.sidemenu-promo {
  $root: &;
  min-height: 48px;
  border-radius: 8px;
  border: 1px solid rgba(207, 30, 30, 0.60);
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  padding-inline-end: 50px;
  background-color: rgba(207, 30, 30, 0.80);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: -140px 0;
  transition: filter 0.3s;

  &::after {
    content: '';
    width: 24px;
    height: 24px;
    position: absolute;
    top: calc(50% - 12px);
    inset-inline-end: 12px;
    filter: invert(100%);
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    border: 1px solid rgba(0, 0, 0, 0.6);
    background-image: var(--icon-arrow-right);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 12px 12px;
  }

  &:hover,
  &:active {
    #{$root}__text {
      color: $color-yellow;
    }
    filter: brightness(1.2);
  }

  &__img {
    width: 62px;
    height: 52px;
    border-radius: 8px;
  }

  &__text {
    width: 120px;
    transition: color 0.3s;
    font-family: $font-roboto;
    @include text(18px, normal, 700, $color-white);
    text-transform: uppercase;
    letter-spacing: 0.6px;
  }
}


:lang(ar) {
  .sidemenu-promo__img,
  .sidemenu-promo::after {
    transform: scaleX(-1);
  }
}
