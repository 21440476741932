$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.balance-table {
    margin-left: -4px;
    width: calc(100% + 8px);
    @include bp1280 {
        margin-left: -12px;
        width: calc(100% + 24px);
    }
    &__thead {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 40px 0 17px;
        height: 60px;
        @include text(14px, 20px, bold, $color-grey);
    }
    &__th {
        width: 20%;
        padding-right: 12px;
        @include text-dots;
        text-transform: capitalize;
    }
    &__item_wrap {
        .balance-table__item {
            background: $color-white;
            border-radius: 8px;
            display: block;
            &:hover {
                cursor: pointer;
            }
        }
        &:hover {
            position: relative;
            z-index: 2;
            box-shadow: 0px 4px 8px rgba(25, 25, 29, 0.2);
            border-radius: 8px;
        }
        &:nth-child(odd) {
            .balance-table__item {
                background: $color-gray-light;
            }
        }
    }
    &__tr {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        padding: 0 40px 0 17px;
        height: 60px;
        border-radius: 8px;
    }
    &__td {
        padding-right: 12px;
        width: 20%;
        @include text(14px, 20px, bold, $color-black);
        max-height: 40px;
        overflow: hidden;

        &.is-real {color: $color-green;}
        &.is-bonus {color: $color-red;}
    }
    &__arr {
        background-position: center;
        position:absolute;
        right: 16px;
        top: 20px;
        width: 20px;
        height: 20px;
        transition: 0.2s;
    }
    &__desc {
        display: none;
        margin-top: 8px;
        padding-left: 16px;
        padding-bottom: 16px;
    }
    &__desc-key {
        @include text(16px, 125%, bold, $color-grey);
        margin-right: 8px;
    }
    &__desc-val {
        @include text(14px, 143%, bold, $color-black);
    }
    &  [type=checkbox]:checked + label .balance-table__desc {
        display: flex;
    }

    &  [type=checkbox]:checked + label {
        .balance-table__arr{
            transform: rotate(180deg);
            filter: $filter-grey;
            transition: 0.2s;
        }
    }
}
