$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.sport-events {
  margin-top: 16px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  min-height: 160px;

  &.euro-events {
    grid-auto-flow: column;
    grid-template-columns: none;
    width: 100%;
    overflow-y: hidden;
    overflow-x: auto;
    white-space: nowrap;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
      background-color: transparent;
    }
    &::-webkit-scrollbar-track {
      width: 0;
      height: 0;
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      width: 0;
      height: 0;
      background-color: transparent;
    }
  }

  &__item {
    position: relative;
    background: #fff;
    border-radius: 20px;
    color: #19191D;
    padding: 14px 20px;

    &:nth-child(4) {
      display: none;

      .euro-events & {
        display: inline-block;
      }
    }

    .euro-events & {
      min-width: 326px;
      display: inline-block;

      @include bp1280 {
        min-width: 356px;
      }
    }
  }

  &__time {
    width: 50%;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    font-family: var(--font-family-main);
  }

  &__date {
    margin-top: 4px;
    font-size: 14px;
    line-height: 20px;
    height: 20px;
    letter-spacing: 0.03em;
    color: #868A92;
  }

  &__champ {
    margin-top: 4px;
    font-size: 12px;
    line-height: 16px;
    height: 16px;
    letter-spacing: 0.03em;
    color: #868A92;
  }

  &__row {
    display: flex;
    justify-content: space-between;
    margin-top: 4px;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    height: 20px;
    letter-spacing: 0.03em;
    color: #19191D;
  }

  &__key {
    margin-right: 10px;
    flex-shrink: 0;
  }

  &__val {
    flex-shrink: 0;
  }

  &__rate {
    margin-top: 12px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 8px;
  }

  &__coef {
    background: #F6F6F4;
    border-radius: 12px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;

    & span {
      margin-right: 10px;
    }

    &:nth-child(1) {
      border: 1px solid #15C187;
      color: #15C187;
    }

    &:nth-child(2) {
      border: 1px solid #868A92;;
      color: #868A92;
    }

    &:nth-child(3) {
      border: 1px solid #E12D2D;
      color: #E12D2D;
    }
  }


  &__logos {
    position: absolute;
    top: 14px;
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__logo {
    width: 49px;
    height: 49px;
    border: solid 1px #868A92;
    background-color: #F6F6F4;
    border-radius: 50%;

    &:first-child {
      margin-right: -6px;
    }

    padding: 9px;
    background-repeat: no-repeat;
    background-position: center;

    background-size: contain;
  }

  &__logo-i {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }


  @include bp1280 {
    grid-template-columns: repeat(4, 1fr);
    &__item {
      &:nth-child(4) {
        display: block;
      }
    }
  }
  @include bp1440 {
    &__item {
      padding: 20px 28px;
    }
    &__time {
      font-size: 28px;
      line-height: 32px;
      height: 32px;
    }
    &__date {
      font-size: 18px;
      line-height: 24px;
      height: 24px;
    }
    &__champ {
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 7px;
      height: 16px;
    }
    &__row {
      font-size: 16px;
      line-height: 24px;
      height: 24px;
    }

    &__logos {
      top: 20px;
      right: 28px;
    }
    &__logo {
      width: 64px;
      height: 64px;

      &:first-child {
        margin-right: -8px;
      }
    }
  }
}
