$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.crab-content {
  position: relative;
  left: 14%;
  display: flex;
  flex-direction: column;

  &:lang(ar) {
    direction: rtl;
  }

  &__title {
    display: grid;
    align-items: center;
    font-size: 32px;
    font-weight: 700;
    line-height: 36px;
    text-transform: uppercase;
    color: $color-white;
    margin-bottom: 16px;
  }

  &__text {
    display: inline-flex;
    align-items: center;

  }

  &__icon {
    display: block;
    width: 32px;
    height: 32px;
    margin-right: 4px;
    margin-left: 10px;
    background-image: url($cdn + '/fezbet/shop/shop-coin.webp');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  a {
    align-items: center;
    display: flex;
    justify-content: center;
    min-width: 150px;
    width: fit-content;
    height: 44px;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;

    .linda-has-remembered-login & {
      &.wasnt-logged {
        display: none;
      }

      &.was-logged {
        display: flex;
      }
    }

    &.was-logged {
      display: none;
    }
  }
}

.crab-wrap-banner {
  position: absolute;
  right: 10px;
  bottom: 0;
  width: 400px;
  height: 250px;
  z-index: 2;
  background-image: url($cdn + '/fezbet/shop/shop-crab-logo.webp');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;

  @include bp1280 {
    right: 30px;
  }
}

.sphere-icons-wrap {
  position: absolute;
  width: 235px;
  height: 100%;
  bottom: 0;
  left: 48%;
  transform: translateX(-50%);
}

.sphere-icon {
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  &._owl {
    right: 0;
    background-image: url($cdn + '/fezbet/shop/owl-sphere.webp');
    width: 150px;
    height: 150px;
    top: 10px;
  }

  &._bear {
    width: 140px;
    height: 140px;
    bottom: 0;
    left: 0;
    background-image: url($cdn + '/fezbet/shop/sphere-bear.webp');
  }
}

.shop-item-cash-crab {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  margin: 24px auto 48px;
  max-width: 1200px;
  background-image: url($cdn + '/fezbet/shop/crab-bg-1024.webp');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 20px;
  height: 250px;
  z-index: 1;

  &:lang(ar) {
    direction: ltr;
  }

  .crab-gradient {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    border-radius: 20px;
    background: linear-gradient(90deg, #924215b3 0%, #000000b3 100%) 0 0 / cover;
    z-index: -1;
    pointer-events: none;

    &::before {
      content: "";
      position: absolute;
      left: -17px;
      bottom: 20px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url($cdn + '/fezbet/shop/sphere-octopus.webp');
      width: 150px;
      height: 150px;
    }
  }

  .crab-border {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    border-radius: 20px;
    border: 4px solid rgba(255, 220, 38, 0.5);
    pointer-events: none;
  }
}
