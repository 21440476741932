$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
// exchange card

.shop-exchange-card {
  position: relative;
  z-index: 1;
  display: grid;
  width: 100%;
  height: 216px;
  align-content: space-between;
  background: $color-white;
  border-radius: 20px;
  color: $color-grey-dark;
  padding: 0 16px 12px;
  transition: background .2s ease;

  &::before {
    content: "";
    position: absolute;
    border-radius: 24px;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2px solid $color-yellow;
    pointer-events: none;
    opacity: 0;
    transition: all .2s ease;
  }

  &__content {
    display: grid;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  &__icon-wrapper {
    position: relative;
    height: 100px;
    width: 150px;
    margin: 16px auto 0;
    transition: all .2s ease;
  }

  &__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 150px;
    width: 200px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    transition: all .2s ease;
    pointer-events: none;
  }

  &__title {
    direction: ltr;
    font-size: 24px;
    font-weight: 700;
    line-height: 85%;
    text-align: center;
    transition: transform .2s ease;
  }

  &__btn {
    min-width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 32px;
    margin-top: 12px;
    box-shadow: 0 10px 12px 0 rgba(255, 255, 255, 0.80) inset, 0 -10px 12px 0 #E88E0F inset, 0 0 0 4px rgba(0, 0, 0, 0.08);

    &:hover {
      box-shadow: none;
      transition: none;
      transform: none;
    }

    .linda-has-remembered-login & {
      &.wasnt-logged {
        display: none;
      }

      &.was-logged {
        display: flex;
      }
    }

    &.was-logged {
      display: none;
    }
  }

  &__btn-icon {
    display: block;
    width: 24px;
    height: 24px;
    margin-right: 4px;
    background-image: url($cdn + '/fezbet/shop/shop-coin.webp');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  &:hover {
    background: linear-gradient(180deg, #EEA315 0%, #FFDC26 100%), #F6F6F4;

    &::before {
      opacity: 1;
      width: calc(100% + 12px);
      height: calc(100% + 12px);
      top: -6px;
      left: -6px;
    }

    .shop-exchange-card__icon-wrapper {
      margin-top: 13px;
    }

    .shop-exchange-card__icon {
      height: 160px;
      width: 214px;
    }

    .btn-44-yellow {
      transition: all .3s;
      transform: translateY(-2px);
      box-shadow: 0 10px 12px 0 rgba(255, 255, 255, 0.80) inset, 0 -10px 12px 0 #E88E0F inset, 0 0 0 4px rgba(255, 255, 255, 0.50), 0 2px 2px 0 rgba(0, 0, 0, 0.20);
    }

    .shop-exchange-card__title {
      transform: translateY(-2px);
    }
  }
}

._free-bet {
  .shop-exchange-card {
    padding-bottom: 12px;
  }
}
