$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.header {
  background: #2D2D32;
  display: flex;
  align-items: center;
  height: 56px;
  max-width: 100%;

  &__burger {
    cursor: pointer;
    display: block;
    width: 36px;
    height: 36px;
    background: var(--icon-burger);
    margin: 10px;
    filter: $filter-grey;
    flex-shrink: 0;
    @include bp1440 {
      margin: 18px 20px 18px 18px;
    }

    &:hover {
      filter: $filter-white;
    }

    .has-sidebar & {
      display: none;
    }
  }

  &__logo {
    flex-shrink: 0;
    width: 84px;
    height: 28px;
    background: var(--icon-logo);
    background-size: contain;
    background-position: left center;
    &:hover {
      opacity: 0.5;
    }
  }

  &__nav {
    flex-shrink: 1;
    flex-grow: 1;
  }

  &__right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-shrink: 0;
    padding-right: 21px;
    min-width: 400px;

    &:lang(ar) {
      padding-right: 0;
      padding-left: 21px;
    }
  }

  &__right-block {
    display: flex;
  }

  &__lambo {
    position:relative;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    margin-right: 15px;
    flex-shrink: 0;
    background-color: rgba(255, 220, 38, 0.1);
    background-image: var( --icon-nav_lambo);
    background-size: 26px;
    background-position: center;
    background-repeat: no-repeat;
  }

  &__crab{
    position:relative;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    margin-right: 12px;
    flex-shrink: 0;
    background-color: rgba(255, 220, 38, 0.1);
    background-image: var( --icon-nav_crab);
    background-size: 26px;
    background-position: center;
    background-repeat: no-repeat;

    &:lang(ar) {
      margin-right: 0;
      margin-left: 12px;
    }
  }
  &__crab-count{
    display: block;
    position:absolute;
    top: -8px;
    right: 23px;
    background-color: #FFDC26;
    height: 24px;
    font-weight: 700;
    font-size: 16px;
    border-radius: 12px;
    line-height: 24px;
    color: #19191D;
    padding:0 8px;
    letter-spacing: -1px;
  }
  &__promo {
    @include text(16px, 28px, null, $color-white);
    background: rgba(255, 220, 38, 0.1);
    flex-shrink: 0;
    display: flex;
    align-items: center;
    font-family: $font-roboto;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    padding: 4px 8px 4px 4px;
    min-height: 28px;
    border-radius:  35px;
    margin-right: 16px;

    &:lang(ar) {
      margin-right: 0;
      margin-left: 16px;

      &::before{
        margin-right: 0;
        margin-left: 4px;
      }
    }

    &::before{
      flex-shrink: 0;
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      margin-right: 4px;
      background-image: var(--icon-nav_lambo);
    }

    &:hover,
    &.active,{ color: $color-yellow; }

    &-text {
      display: block;
      width: 74px;
      height: 24px;
      margin-right: 8px;
      background: var(--icon-nav_ferrari-text) no-repeat left center;
      background-size: contain;

      &:lang(ar) {
        margin-right: 0;
        margin-left: 8px;
      }
    }

    &-img {
      width: 35px;
      height: 29px;
      margin-right: -2px;
      position: relative;
      left: -6px;

      &:lang(ar) {
        margin-left: 2px;
        margin-right: 0;
        left: auto;
        right: -6px;
        transform: scaleX(-1);
      }
    }

    &.is-santa {
      font-size: 14px;
      min-height: 36px;
      padding-inline: 4px 10px;

      &::before {
        content: none;
      }
    }
  }

  &__euro {
    &-btn {
      @include text(10px, 120%, 400, $color-white);

      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 28px;
      text-transform: uppercase;
      letter-spacing: 0.3px;
      border-radius: 35px;
      background: rgba(255, 220, 38, 0.1);
      padding-inline-end: 10px;
      padding-inline-start: 28px;
      margin-inline-end: 16px;

      @include bp1440 {
        padding-inline-start: 38px;
        height: 36px;
      }

      &::before {
        content: '';
        position: absolute;
        inset-inline-start: 4px;
        width: 20px;
        height: 20px;
        background-image: url($cdn + '/fezbet/tournaments/icon-euro2024.svg');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;

        @include bp1440 {
          width: 30px;
          height: 30px;
        }
      }

      &:hover,
      &.active {
        color: $color-yellow;

        &::before {
          background-image: url($cdn + '/fezbet/tournaments/icon-euro2024-hover.svg');
        }
      }
    }

    &-name {
      font-size: 12px;
      font-weight: 700;

      @include bp1440 {
        font-size: 14px;
      }
    }
  }

  .header__btn {
    margin-left: 12px;

    &:lang(ar) {
      margin-left: 0;
      margin-right: 12px;
    }
  }


  @include bp1440 {
    height: 72px;
    &__logo {
      width: 120px;
      height: 40px;
      margin-left: 2px;

      &:lang(ar) {
        margin-left: 0;
        margin-right: 2px;
      }
    }
    &__right {
      padding-right: 37px;
      min-width: 450px;

      &:lang(ar) {
        padding-right: 0;
        padding-left: 37px;
      }
    }
    &__btn {
      margin-left: 21px;
    }
    &__promo {
      height: 36px;
      @include text(18px, 36px);

      &::before {
        width: 28px;
        height: 28px;
      }
    }
  }
}
