$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.shop-spin-card {
  display: grid;
  grid-template-columns: 134px 1fr;
  grid-gap: 16px;
  height: 249px;
  padding: 24px;
  background-color: $color-white;
  border-radius: 20px;

  &__bg {
    height: 100%;
    width: 100%;
    border-radius: 12px;
    background-position: bottom left;
    background-size: contain;
    background-repeat: no-repeat;
    box-shadow: 0 4px 8px 0 rgba(25, 25, 29, 0.5);
  }

  &__content {
    position: relative;
    width: 100%;
    font-size: 14px;
    font-weight: 700;
    line-height: 100%;
    display: flex;
    color: $color-black;
    flex-direction: column;
  }

  &__game {
    text-transform: uppercase;
    line-height: 114%;
    font-size: 24px;
    margin-bottom: 16px;
  }

  &__text {
    margin-bottom: 6px;
  }

  &__price {
    margin: 0;
    width: max-content;
    display: flex;
    align-items: center;
    gap: 4px;
  }

  &__price-value {
    display: inline-flex;
    direction: ltr;
  }

  a {
    position: absolute;
    right: 0;
    bottom: 0;
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 44px;
    font-size: 18px;
    font-weight: 700;
    line-height: 19px;

    .linda-has-remembered-login & {
      &.wasnt-logged {
        display: none;
      }

      &.was-logged {
        display: flex;
      }
    }

    &.was-logged {
      display: none;
    }
  }
}
