$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.cashbox-qamount {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 0 10px;
  margin-bottom: 28px;
  transform: translateX(-24px);

  .rtl-lang & {
    transform: translateX(24px);
  }

  &__item {
    font-family: var(--font-family-main);
    cursor: pointer;
    background: #FFFFFF;
    box-shadow: inset 0 0 1px 1px #E7E7E4;
    border-radius: 12px;
    min-width: 150px;
    height: 52px;
    @include text(22px, 52px, 700, $color-black);
    text-align: center;

    .rtl-lang & {
      direction: ltr;
    }

    &.is-active {
      background: #FFDC26;
      box-shadow: inset 0 12px 15px rgba(255, 255, 255, 0.8), inset 0 -12px 15px #E88E0F, inset 0 1px 1px rgba(233, 145, 16, 0.2);
    }
  }
}
