$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.asb-livenow,
.asb-highlights,
.asb-upcoming {

    &__header {
        padding: 0 24px;
    }

    &__header-label {
        position: relative;
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: .02em;
        text-transform: uppercase;
        color: $color-black;
        font-family: Fez;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    &__tabs {
        & > div{
            padding: 0 24px;
            overflow-y: scroll;
            &:first-child {
                border-bottom: solid 1px #f6f6f4;
                margin-bottom: 20px;
            }
        }
    }
    & &__tabs-content {
        display: block;
        height: 240px;
        overflow-y: scroll;
    }
}

.asb-livenow {
    .asb-event-row {
        display: inline-block;
    }
    & &__tabs-content {
        columns: 2;
        column-gap: 20px;
        height: auto;
    }
    &__tabs {
        & > div {
            &:nth-child(2) {
                & > div {
                    height: 240px;
                    overflow-y: scroll;
                    align-items: flex-start;
                }
            }
        }
    }
    &__header-icon {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background-color: #e12d2d;
        background-image: var(--icon-sport-live-i),var(--icon-sport-live);
        background-repeat: no-repeat;
        background-position: center 6px,center 17px;
        vertical-align: middle;
        margin: 0 8px 0 0;
        box-shadow: inset 0 4px 6px rgba(255,255,255,.3), inset 0 -4px 6px rgba(60,18,5,.4);
    }
    .asb-prefix {
        &__live-time {
            display: block !important;
            flex: 0 0 calc(38% - 22px);
            font-size: 16px;
            line-height: 28px;
            color: $color-red;
            font-weight: bold;
            & + div {
                display: none;
            }
        }
    }
    .asb-live {
        flex: 0 0 16px;
        margin-right: 6px;
        width: 16px;
        height: 16px;
        font-size: 0;
        align-self: center;
        border-radius: 100%;
        background-color: #e12d2d;
        background-image: var(--icon-sport-live-i);
        background-repeat: no-repeat;
        background-position: center 3px;
        background-size: 12px;
        vertical-align: middle;
        margin: 0 8px 0 0;
        box-shadow: inset 0px 2px 3px rgba(255, 255, 255, 0.3), inset 0px -2px 3px rgba(60, 18, 5, 0.4);
    }
    @include bp1280 {
        & &__tabs-content {
            columns: 1;
        }
    }
}
.asb-highlights {

}
.asb-upcoming {

}

.asb-tab {
    margin-bottom: -1px;
    font-weight: 700;
    font-size: 14px;
    color: $color-grey;
    margin-right: 24px;
    height: 40px;
    line-height: 40px;
    box-sizing: border-box;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &_selected {
        color: $color-black;
    }
    &_selected > &__indicator {
        height: 4px;
        background-color: $color-yellow;
    }
}

.asb-event-row {
    border-radius: 12px;
    padding: 8px 16px;
    vertical-align: top;
    width: 100%;

    &:nth-child(odd) {
        background-color: $color-gray-light;
    }
    & > div {
        &:first-child {
            margin-bottom: 8px;
        }
        justify-content: flex-start;
    }
    & > :nth-child(2) {
        & > :first-child {
            flex: 0 0 38%;
        }
        & > :nth-child(2) {
            flex: 1 1 auto;
            & > div {
                display: grid;
                grid-template-columns: repeat(3,1fr);
                grid-gap: 8px;
                & >div {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    background: #e7e7e4;
                    border: 1px solid #e7e7e4;
                    border-radius: 4px;
                    padding: 4px;
                    text-align: center;
                    height: 28px;
                    line-height: 20px;
                    box-sizing: border-box;
                    cursor: pointer;
                    &:hover {
                        border: 1px solid #868a92;
                    }
                }
            }
        }
    }
}

.asb-competitor {
    margin-bottom: 4px;
}

.asb-odd {
    &__label {
        font-weight: 300;
        margin-right: 10px;
    }
}

.asb-nodata {
    font-weight: 700;
    font-size: 14px;
    color: $color-black;
}

.asb-prefix {
    & &__wrapper {
        color: $color-grey;
        font-weight: 400;
        flex-wrap: nowrap;
        & div:nth-child(2){
            display: none;
        }

    }
    &__prelive-time {
        flex: 0 0 38%;
        color: $color-black;
        font-weight: 700;
    }
}

.asb-flag-icon {
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 4px;
    i.flag-icon {
        border-radius: 100%;
    }
}

.asb-score {
    order: 10;
    margin-left: auto;
    margin-right: 14px;
    margin-bottom: 4px;
}



// .asb-livenow-text,
// .asb-upcoming-text {
//     position: relative;
//     font-weight: 700;
//     font-size: 24px;
//     line-height: 32px;
//     letter-spacing: .02em;
//     text-transform: uppercase;
//     color: $color-black;
//     font-family: Fez;
//     white-space: nowrap;
//     overflow: hidden;
//     text-overflow: ellipsis;
// }

// .asb-highlights {

//     &__header-text {
//         position: relative;
//         font-weight: 700;
//         font-size: 24px;
//         line-height: 32px;
//         letter-spacing: .02em;
//         text-transform: uppercase;
//         color: $color-black;
//         font-family: Fez;
//         white-space: nowrap;
//         overflow: hidden;
//         text-overflow: ellipsis;
//     }
// }

// .asb-livenow-icon {
//     display: inline-block;
//     left: 0;
//     width: 32px;
//     height: 32px;
//     border-radius: 50%;
//     background-color: #e12d2d;
//     background-image: var(--icon-sport-live-i),var(--icon-sport-live);
//     background-repeat: no-repeat;
//     background-position: center 6px,center 17px;
//     vertical-align: middle;
//     margin: 0 8px 0 0;
//     box-shadow: inset 0 4px 6px rgba(255,255,255,.3), inset 0 -4px 6px rgba(60,18,5,.4);
// }



// .asb {
//   background-color: white;
//   width: 500px;
//   max-width: 100%;
//   border-radius: 8px;
//   font-size: 14px;
//   font-family: 'Inter', sans-serif;
// }

// .asb-event {
//   padding: 23px 20px 20px 24px;
// }

// .asb-nodata {
//   font-size: 16px;
//   padding: 20px;
//   font-weight: bold;
// }

// .asb-nodata-icon {
//   padding: 10px;
//   font-size: 30px;
// }
// .asb-event:nth-child(even) {
//   background: #F2F2F2;
// }

// .asb-odd {
//   background-color: #E0E0E0;
//   margin: 5px;
//   width: 48px;
//   position: relative;
//   height: 32px;
//   border-radius: 8px;
// }

// .asb-odd--selected {
//   color: #292929;
//   background-color: #FFCC00;;
//   margin: 5px;
//   cursor: pointer;
// }

// .asb-odd-label {
//   font-weight: 600;
//   font-size: 12px;
//   color:  #333333;
// }

// .asb-odd-price {
//   font-weight: bold;
// }

// .asb-betting {
//   background-color: white;
//   padding: 5px;
// }

// .asb-stake-input {
//   max-width: 177px;
// }

// .asb-placebet-button {
//   padding: 0 10px;
//   margin: 0 5px;
//   border: 1px solid #15C187;
//   width: 145px;
//   border-radius: 30px;
//   color: #FFFFFF;
//   font-weight: bold;
//   background: #15C187;
//   text-transform: uppercase;
//   text-shadow: 1px 1px 1px #ffd7d7;
//   outline: 0;
// }

// .asb-stake-input {
//   padding: 5px;
//   outline: 0;
//   border-radius: 30px;
//   border: 1px solid #858A92;
// }

// .asb-prefix {
//   padding-bottom: 10px;
// }

// .asb-prefix__wrapper {
//   align-content: center;
// }

// .asb-prefix,
// .asb-prefix__prelive-time {
//   color: #333333;
//   font-size: 12px;
// }

// .asb-flag-icon {
//   margin: 0px 4px;
//   border-radius: 100%;
//   font-size: 11px;
// }

// .asb-competitor {
//   padding: 4px 0;
//   font-weight: bold;
// }

// .asb-score {
//   padding: 4px 10px 4px 0;
//   font-weight: bold;
//   color: #E63535;
// }

// .asb-live {
//   border-radius: 2px;
//   font-size: 80%;
//   font-weight: bold;
//   padding: 0.2em 0.3em;
//   background-color: red;
//   color: white;
//   text-transform: uppercase;
//   margin-right: 5px;
//   height: 17px;
// }
// .asb-prefix__teams-icons {
//   background: no-repeat center url('../demo-icons/team-icons.png');
//   background-size: 700px;
//   width: 61px;
//   height: 32px;
// }

// .asb-odd--selected>.asb-odd-label {
//   color: white;
// }

// .icon-caret-up {
//   position: absolute;
//   top: 0;
//   left: calc(50% - 4px);
//   color: green;
// }

// .icon-caret-down {
//   top: 0;
//   left: calc(50% - 4px);
//   position: absolute;
//   color: red;
// }
// .asb-highlights__header-text,
// .asb-livenow-text,
// .asb-upcoming-text {
//   text-transform: uppercase;
//   padding: 20px 8px 8px 15px;
//   font-weight: bold;
//   font-size: 18px;
// }
// .asb-ui-tabs-header-item {
//   padding: 8px 16px;
//   outline: 0;
//   text-transform: uppercase;
//   font-weight: 500;
//   position: relative;
//   color: #BDBDBD;
// }
// .asb-ui-tabs-header-item--selected {
//   color: #333333;
// }
// .asb-ui-tabs-header-item__indicator {
//   height: 1px;
//   position: absolute;
//   bottom: 0px;
//   left: 0px;
//   width: 100%;
//   background: #E0E0E0;
// }
// .asb-ui-tabs-header-item--selected>.asb-ui-tabs-header-item__indicator {
//   background: #FFCC00;
//   height: 2px;
// }

// .widget-example {
//   display: flex;
//   flex-direction: row;
//   align-items: flex-start;
//   flex-wrap: wrap;
// }
// .widget-example > div:first-child {
//   margin-right: 50px;
//   margin-bottom: 10px;
// }
// .code-example {
//   display: flex;
// }
// .hljs {
//   background: white;
//   border-radius: 8px;
//   padding: 25px 20px 30px;
//   width: 460px;
// }

// pre {
//   margin-top: 0;
//   white-space: pre-line;
// }
