$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
linda-promos,
batman-promo-list2,
linda-promo-page{
  display: contents;
}



.promos {
  $this: &;

  margin-top: 40px;

  &__body {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
  }

  &__menu {
    flex: 0 0 132px;
    margin-right: 16px;
    margin-top: 96px;
    position: sticky;
    top: 40px;

    &:lang(ar) {
      margin-right: 0;
      margin-left: 16px;
    }
  }

  &__list {
    flex: 1 1 auto;
    margin-top: -8px;

  }

  &__cards {
    columns: 2;
    column-gap: 20px;
    min-height: 500px;
  }

  &__title {
    @include text(64px, 80px, 700, rgba(134, 138, 146, 0.3));
    font-family: var(--font-family-main);
    text-transform: uppercase;
    text-align: right;
    letter-spacing: 0.04em;
    margin-bottom: -20px;
    padding-right: 24px;

    &:lang(ar) {
      font-size: 52px;
    }
  }

  &__divider {
    background-repeat: no-repeat;
    background-image:
      linear-gradient(270deg, var(--grLineWhite)),
      linear-gradient(270deg, var(--grLineBlack));
    background-size: 100% 2px,
      100% 2px;
    background-position: 0 0, 0 2px;

    height: 0;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  @include bp1280 {
    #{$this} {
      &__cards {
        columns: 3;
      }
    }
  }

  @include bp1440 {
    #{$this} {
      &__menu {
        margin-right: 24px;
      }

      &__list {
        margin-top: 4px;
      }

      &__cards {
        column-gap: 24px;
      }

      &__title {
        font-size: 84px;
        letter-spacing: 0.08em;
        margin-bottom: -11px;
        padding-right: 40px;
      }

      &__divider {
        height: 4px;
        margin-top: 18px;
        margin-bottom: 24px;
      }
    }

  }
}
