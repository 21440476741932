$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.shop-exchange {
  position: relative;
  z-index: 1;
  width: 968px;
  margin: 0 auto;
  padding: 40px 56px 0 56px;
  background-image: linear-gradient(#FDFCFF 0%, #FDFCFF calc(100% - 28px), transparent calc(100% - 28px));
  border-radius: 16px;

  &__header {
    display: flex;
    justify-content: center;
    padding-left: 250px;
  }

  &__title {
    font-size: 14px;
    font-weight: 900;
    line-height: 20px;
    letter-spacing: .02em;

    display: flex;
    align-items: center;
    margin: 0 auto;
  }

  &__title-value {
    font-size: 20px;
    display: inline-flex;
    align-items: center;
    margin-inline-start: 5px;

    &::after {
      content: '';
      width: 24px;
      height: 24px;
      background-size: 24px;
      margin-inline-start: 7px;
    }
  }

  &__subtitle {
    font-size: 24px;
    font-weight: 900;
    line-height: 29px;
    letter-spacing: .02em;
    text-align: center;
    margin-top: 40px;
  }

  &__text {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: .02em;
    text-align: center;
    margin-top: 17px;
  }

  &__items {
    display: grid;
    grid-gap: 20px;
    position: relative;
    margin-right: -27px;
    padding-right: 27px;
    margin-left: -27px;
    padding-left: 27px;
    margin-bottom: 32px;
    min-height: 125px;

    &._bonus {
      grid-template-columns: repeat(4, 1fr);
    }

    &._free-bet {
      grid-template-columns: repeat(6, 1fr);
      overflow-x: auto;
      padding-top: 6px;
      padding-bottom: 6px;

      @include bp1440 {
        overflow-x: unset;
      }

      &::-webkit-scrollbar-track {
        background-color: transparent;
      }

      &::-webkit-scrollbar {
        background-color: transparent;
        width: 0;
        height: 0;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #000000;
      }
    }

    @include bp1280 {
      margin-right: -6px;
      padding-right: 6px;
      margin-left: -6px;
      padding-left: 6px;
    }
  }
}
