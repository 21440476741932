$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.bonus-wgt {
  width: 100%;
  height: 214px;
  border-radius: 16px;
  flex-shrink: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  position:relative;
  color:#fff;

  .profile-board & {
    margin-top: 0;
  }

  &.status-active{ border: 4px solid #FFDC26; }
  &.status-new{ border: 4px solid #E12D2D; }

  &__img {
    position:absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    filter: blur(2px);
    background-size: cover;
    background-position: center center;
    z-index: 10;

    .is-money & { background-image:  url( $cdn + '/fezbet/bonuses/casino.jpg') }
    .is-sport & { background-image:  url( $cdn + '/fezbet/bonuses/sport.jpg') }
  }

  &__content {
    position:absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 20;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(180deg, #2D2D32 0%, rgba(45, 45, 50, 0.6) 100%);
    background-size: 100%;
    padding: 10px 20px;
    text-align: center;
  }

  &.is-casino { background-image: linear-gradient(180deg, #2D2D32 0%, rgba(45, 45, 50, 0.6) 100%) , url( $cdn + '/fezbet/bonuses/casino.jpg')}
  &.is-sport { background-image: linear-gradient(180deg, #2D2D32 0%, rgba(45, 45, 50, 0.6) 100%) , url( $cdn + '/fezbet/bonuses/casino.jpg')}

  &__label {
    position:absolute;
    z-index: 50 ;
    width: 115px;
    height: 31px;
    @include text-dots;
    transform: rotate(-45deg);
    transform-origin: left bottom;
    left: 0;
    top: 50px;
    line-height: 31px;
    font-weight: bold;
    font-size: 16px;
    padding: 0 21px;
    text-align: center;
    text-transform: uppercase;
    font-family: var(--font-family-main);

    &:lang(ar) {
      transform: rotate(45deg);
      top: -32px;
      right: -32px;
      left: unset;
    }

    .status-active & {
      color: #19191D;
      background: #FFDC26;
    }

    .status-new & {
      color: #FFFFFF;
      background: #E12D2D;
    }
  }

  &__type {
    width: 32px;
    height: 32px;
    filter: $filter-white;
    &.is-casino{ background-image: var(--icon-type-casino);}
    &.is-sport{ background-image: var(--icon-type-sport);}
    &.is-crab{
      background-image: var(--icon-nav_crab);
      filter: none;
      background-repeat: no-repeat;
      background-position: center center;
    }
    &.is-coin {
      filter: none;
      background-image: url($cdn + '/fezbet/shop/shop-coin.webp');
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
    }

    &.is-right {
      position: absolute;
      inset-inline-end: 15px;
      top: 16px;
      z-index: 30;
    }
  }

  &__offer {
    @include text-dots;

    max-width: 100%;
    padding: 18px 0 18px 0;
    font-weight: bold;
    font-size: 42px;
    line-height: 52px;
    text-align: center;
    color: #FFCD1C;
    font-family: var(--font-family-main);

    &:lang(eu),
    &:lang(fr) {
      font-size: 32px;
    }

    &.is-coin {
      text-transform: capitalize;
    }

    &-amount {
      display: grid;
      grid-template-columns: auto auto;

      &._ltr {
        direction: ltr;
      }
    }
  }

  &__name {
    font-size: 14px;
    line-height: 20px;
    max-height: 40px;
    @include text-dots;
    overflow: unset;
    text-transform: capitalize;
  }

  &__name-link {
    color:$color-yellow;
    text-decoration: underline;
  }

  &__btns {
    padding:0 10px;
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    max-width: 100%;

    & .btn{ margin:10px;}
    & .link-btn{ margin: 0 10px;}
  }
}
