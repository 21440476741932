$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.tournament {
  $this: &;

  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 48px;

  &.euro-tournament {
    width: calc(100% - 296px);

    @include bp1680 {
      width: calc(100% - 424px);
    }
  }

  &__events-name {
    @include text(24px, 130%, 700, $color-white);

    position: relative;
    display: flex;
    justify-content: space-between;
    margin: 30px 0 20px;
    padding: 0 20px;
    background-image:
      linear-gradient(180deg, var(--grLineWhite), var(--grLineWhite)),
      linear-gradient(180deg, var(--grLineBlack), var(--grLineBlack));
    background-repeat: no-repeat;
    background-size: 2px 100%;
    text-transform: uppercase;
    background-position: 0 0, 2px 0;
  }

  &__events-list {
    direction: ltr;
  }

  &__img {
    z-index: -2;
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 0;
    width: 336px;
    background-image: url(/img/tournament.png);
    background-size: 400px 512px;
    background-position: right top;
    background-repeat: no-repeat;

    .euro-wrapper & {
      z-index: 2;
      top: -20px;

      @include bp1280 {
        top: 0;
      }
    }

    &_2 {
      filter: grayscale(100%);
    }
    @include bp1280 {
      top: 0;
      width: 436px;
      background-size: 500px 640px;
    }

    @include bp1440 {
      width: 448px;
    }

    @include bp1680 {
      width: 500px;
    }
  }

  &__name {
    @include text(18px, 130%, 400);

    color: rgba(255, 255, 255, 0.8);
  }

  &__bg-wrapper {
    position: absolute;
    z-index: -2;
    left: 0;
    top: 92px;
    width: 100%;
    height: calc(100% - 92px);
    border-radius: 20px;
    overflow: hidden;

    &.euro-wrapper {
      height: 556px;
      position: relative;
      top: unset;
      margin-top: 92px;
      overflow: visible;

      @include bp1280 {
        height: 420px;
      }

      &__active::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 324px;
        clip-path: polygon(0 0, 100% 0, 31% 100%, 0% 100%);
        background: linear-gradient(180deg, #03B95E 0%, #0D7141 40%, #143F2C 100%);
        border-bottom-left-radius: 20px;
        border-top-left-radius: 20px;
      }
    }
  }

  &__bg {
    height: 100%;
    position: absolute;
    z-index: -2;
    left: 0;
    top: 0;
    width: 100%;
    background-image: linear-gradient(180deg, var(--color1), var(--color2));
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: 0 0;
    border-radius: 20px;

    &_2 {
      background-image: linear-gradient(180deg,#b5b5b5 0,#252525 100%);
    }

    .euro-wrapper__active & {
      background: linear-gradient(180deg, #D94453 0%, #602340 60.91%, #2D1A2B 100%);
    }
  }
  &__gradient-dif {
    --height: 1311px;
    position: absolute;
    z-index: -3;
    left: 0;
    top: 0;
    width: 100%;
    height: calc(var(--height) - 92px);
    background-image: linear-gradient(180deg, rgba(25, 25, 29, 0) 0%, #19191D 100%), linear-gradient(180deg, var(--color1), var(--color2));
    background-repeat: no-repeat;
    background-size: 100% 120px, 100% 100%;
    background-position: 0 bottom, 0 0;
    border-radius: 20px;

    &_2 {
      background-image: linear-gradient(180deg, rgba(25, 25, 29, 0) 0%, #19191D 100%), linear-gradient(180deg, #b5b5b5, #252525);
      //background-image: linear-gradient(180deg,#b5b5b5 0,#252525 100%);
    }

    @include bp1280 {
      --height: 1346px;
    }

    @include bp1440 {
      --height: 1390px;
    }

    @include bp1680 {
      --height: 1510px;
    }

  }
  &__circle {
    position: absolute;
    width: 60px;
    height: 60px;
    top: 232px;
    right: 0;
    transform: translateX(50%);
    background-image: url($cdn + '/fezbet/tournaments/tournament.png');
    background-position: calc(var(--status) * (-60px)) 0;

    &:lang(ar) {
      transform: translateX(30%);
    }
  }

  &__status {
    position: absolute;
    z-index: 1;
    left: 19px;
    top: 81px;

    @include text(14px, 20px, 700, $color-black);
    font-family: var(--font-family-main);

    border-radius: 8px;
    overflow: hidden;
    padding: 0 12px;

    &_0 {
      background-color: $color-white;
    }

    &_1 {
      background-color: $color-yellow;
    }

    &_2 {
      background-color: $color-grey;
      color: $color-white;
    }

  }

  &__info {
    display: flex;
    flex-wrap: wrap;
    margin-left: 330px;
    margin-top: 132px;

    &:lang(ar) {
      position: relative;
      right: 60px;
    }

    @include bp1280 {
      flex-wrap: nowrap;
      margin-left: 384px;
    }

    @include bp1440 {
      margin-left: 436px;
    }

    @include bp1680 {
      margin-left: 468px;
    }

    @include bp1920 {
      margin-left: 564px;
    }

    &.euro-info {
      flex-direction: column;
      margin-top: unset;
      justify-content: center;
      height: 100%;

      @include bp1280 {
        flex-direction: row;
        justify-content: space-around;
        padding-top: 42px;
      }
    }
  }

  &__info-main {
    min-height: 30px;
    overflow: hidden;
    max-width: 325px;
    height: auto;
    flex: 0 0 325px;
    display: flex;
    flex-direction: column;

    @include bp1920 {
      flex-basis: 420px;
      max-width: 420px;
    }

    .euro-tournament & {
      flex: unset;
    }
  }

  &__info-main.tp_single {
    height: auto;
  }

  &__info-wrapper {
    display: grid;
    gap: 8px;
  }

  &__info-secondary {
    display: none;
    &.is-sport-single {
      display: grid;
      grid-template-columns: repeat(2, 164px);
      grid-gap: 0 16px;
      margin-right: 20px;

      @include bp1280 {
        min-height: 30px;

        display: flex;
        flex: 0 0 340px;
        flex-basis: 164px;
        flex-direction: column;
        margin-left: 16px;
        margin-top: 0;
        max-width: 164px;
      }

      @include bp1440 {
        margin-left: 24px;
      }
      @include bp1680 {
        flex-basis: 224px;
        max-width: 224px;
        margin-left: 32px;
      }
    }

    &.is-euro-info {
      display: flex;
      gap: 40px;

      @include bp1280 {
        flex-direction: column;
        gap: 8px;
      }
    }

    @include bp1280 {
      min-height: 30px;

      display: flex;
      flex: 0 0 340px;
      flex-basis: 164px;
      flex-direction: column;
      margin-left: 16px;
      margin-top: 0;
      max-width: 164px;
    }

    @include bp1440 {
      margin-left: 24px;
    }
    @include bp1680 {
      flex-basis: 224px;
      max-width: 224px;
      margin-left: 32px;
    }

  }

  &__animation-content {
    display: inherit;
    flex-direction: inherit;
    height: auto;

    &_after {
      display: none;
      opacity: 0;
    }

    &_after.tp_single {
      display: block;
      opacity: 1;
      margin-bottom: 40px;
      margin-top: 20px;
      @include bp1280 {
        margin-bottom: initial;
      }
    }
  }

  &__animation-content_before.tp_single {
    display: none;
    height: 0;
  }

  &__category {
    display: none;
    margin-bottom: 4px;
    @include text(22px, 26px, 400, $color-white);
    .tournament-sport & {
      display: block;
    }
  }

  &__title {
    @include text(32px, 40px, 700, null);
    font-family: var(--font-family-main);
    cursor: pointer;
  }

  &__title-start {
    @include text(22px, 22px, 700, null);
    font-family: var(--font-family-main);
  }

  &__subtitle {
    @include text(60px, 72px, 700, $color-yellow);
    @include text-dots;
    font-family: var(--font-family-main);
    text-shadow: 0 4px 15px rgba(40, 39, 42, 0.1);

    margin-top: 12px;

    &-icon {
      display: inline-block;
      width: 44px;
      height: 44px;
      margin-left: 4px;
      background-image: url($cdn + '/fezbet/shop/shop-coin.webp');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }

    &_2 {
      color: $color-white;
    }
  }

  &__date {
    @include text(18px, 24px, 700, rgba($color-white, .5));
    @include text-dots;
    font-family: var(--font-family-main);
    margin-top: 12px;
  }

  &__btn {
    align-self: flex-start;
    position: relative;
    z-index: 1;
    height: 44px;
    margin-top: 36px;
    overflow: hidden;
    transition: transform .3s;

    &:hover {
      transition: transform .3s;
    }

    &.unlogged__btn {
      .linda-has-remembered-login & {
        &.wasnt-logged {
          display: none;
        }

        &.was-logged {
          display: inline-block;
        }
      }

      &.was-logged {
        display: none;
      }
    }

    .euro-wrapper & {
      margin-top: 16px;
    }
  }

  &__countdown {
    margin-top: 20px;
  }

  &__link {
    align-self: flex-start;
    height: 0;
    margin-top: 0;
    height: 24px;
    margin-top: 16px;
    overflow: hidden;

    .euro-wrapper & {
      margin: 24px 0 36px;

      @include bp1280 {
        margin: 36px 0 0;
      }
    }
  }

  &__info-block {
    &_providers {
      .tournament-sport & {
        display: none;
      }
      @include maxbp1280 {
        display: none;
      }
    }
    &_leagues {
      display: none;
      .tournament-sport & {
        display: block;
      }
      @include maxbp1280 {
        display: none;
      }
    }

    @include bp1280 {
      &:not(:first-child) {
        margin-top: 28px;

        .tournament-euro2024 & {
          margin-top: 8px;
        }
      }
    }
  }

  &__label {
    @include text(18px, 24px, 700, rgba($color-white, .5));
    @include text-dots;
    max-height: 24px;
    font-family: var(--font-family-main);
  }

  &__value {
    @include text(18px, 24px, 700, $color-white);
  }

  &__providers {
    display: inline-grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 40px;
    gap: 10px 16px;
    margin-top: 8px;

    @include bp1680 {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  &__provider {
    width: 44px;
    height: 44px;
    background-image: var(--img);
    background-repeat: no-repeat;
    background-size: contain;
  }

  --game-height: 221px;
  --game-gap: 16px;

  @include bp1280 {
    --game-gap: 20px;
    --game-height: 251px;
  }

  @include bp1440 {
    --game-gap: 24px;
    --game-height: 263px;
  }

  @include bp1680 {
    --game-height: 282px
  }

  &__games {
    display: flex;
    flex-direction: column;
    padding: 12px 20px 0 20px;
    margin: 20px 0 0 auto;

    width: 100%;
    max-width: 676px;
    height: auto;
    max-height: calc(22px + 48px + 28px + 22px + var(--game-height) * 3 + var(--game-gap) * 2);

    background-color: $color-white;
    border-radius: 16px;
    opacity: 0;

    @include bp1280 {
      padding-left: 24px;
      padding-right: 24px;

      max-width: 776px;
    }

    @include bp1440 {
      padding-left: 32px;
      padding-right: 32px;

      max-width: 836px;
    }

    @include bp1680 {
      max-width: 932px;
    }
    @include bp1920 {
      max-width: 1100px;
    }
  }

  &__games.tp_single {
    opacity: 1;
  }

  &__games-grid-container {
    position: relative;
    flex: 0 0 calc(100% - 10px);
    overflow: hidden;
    padding-bottom: 95px;

    batman-game-box2 {
      display: contents;
    }
  }

  &__games-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: var(--game-gap);
    align-content: start;
    max-height: 100%;
    overflow-y: scroll;
    overscroll-behavior: none;

    @include bp1920 {
      grid-template-columns: repeat(5, 1fr);
    }
  }

  &__games-more {
    bottom: 0;
  }

  &__games-title {
    @include text(24px, 28px, 700, $color-black);
    font-family: var(--font-family-main);
    letter-spacing: .02em;
    text-transform: uppercase;
    margin-bottom: 22px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__leagues {
    display: flex;
    align-items: center;
    margin-top: 8px;
  }
  &__league-item {
    min-height: 44px;
    min-width: 84px;
    padding: 14px 8px;
    margin-right: 8px;
    border-radius: 8px;
    background-repeat: no-repeat;
    background-position: center left, center;
    background-size: contain;
    cursor: pointer;
    &:hover {
      opacity: .7;
    }
    &:last-child {
      display: none;
      @include bp1920 {
        display: block;
      }
    }
    &.is-laliga {
      background-image: var(--icon-laliga),linear-gradient(180deg, #FFD43E 0%, #FF4E4E 100%) ;
    }
    &.is-seriaA {
      background-image: var(--icon-seriaA),linear-gradient(180deg, #FFD43E 0%, #FF4E4E 100%) ;
    }
    &.is-bundesliga {
      background-image: var(--icon-bundesliga),linear-gradient(180deg, #FFD43E 0%, #FF4E4E 100%) ;
    }
  }
  &__league-item-qatar {
    min-height: 60px;
    min-width: 142px;
    padding: 14px 8px;
    margin-right: 8px;
    border-radius: 8px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto;
    background-image: var(--icon-world-cup),linear-gradient(180deg, #FFD43E 0%, #FF4E4E 100%) ;
    cursor: pointer;
    &:hover {
      opacity: .7;
    }
  }
  &__leagues-box {
    margin-top: 28px;
    padding: 20px;
    background-color: $color-white;
    border-radius: 16px;
    max-width: 836px;
    margin-left: auto;
    @include bp1440 {
      padding: 20px 20px 32px 32px;
    }
    @include bp1920 {
      padding: 20px 20px 18px 32px;
      max-width: 1172px;
    }
  }
}
