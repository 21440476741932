$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.game{
  position:relative;
  border-radius: 12px;
  background-size: cover;
  background-color: rgba(0,0,0,0.1);
  box-shadow: 0 4px 8px rgba(25, 25, 29, 0.5);
  &::before{
    content: '';
    display: block;
    padding-top:150%;
  }

  &__wrap{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: none;
    z-index: 1;

    .game:hover & {
      display: block;
    }
  }

  &__row{
    position:relative;
    display: flex;
    align-items: flex-end;
    background-color: #19191D;
    padding:0 4px 8px 12px;
    border-radius: 0 0 12px 12px;
    box-shadow: 0 4px 8px rgba(25, 25, 29, 0.5);
  }
  &__i{
    width: 32px;
    height: 40px;
    flex-shrink: 0;
    &::before{
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background-image:  var(--icon-dots);
      filter: $filter-grey;
      background-size: 32px;
      background-position: center bottom;
    }
    &:hover::before{ filter: $filter-white;}
  }

  &__gb{
    display: none;
    flex-direction: column;
    align-items: center;
    position:absolute;
    bottom: 100%;
    right: 0;
    z-index: 1;
    background: #2D2D32;
    box-shadow: 0 4px 12px #19191D;
    border-radius: 12px 0 0 12px;
    width: max-content;
    padding: 8px 8px 9px;

    .gh__items .game:not(:lang(ar)):nth-child(8n + 1) & {
      left: 0;
      border-radius: 0 12px 12px 0;
    }

    &:lang(ar) {
      .gh__items .game:nth-child(8n + 8) & {
        left: 0;
        right: unset;
        border-radius: 0 12px 12px 0;
      }
    }

    .game__i:hover &{display: flex;}

    & * + *{
      margin-top:8px;
    }
  }

  &__play{
    flex-grow: 1;
    display: block;
  }

  &__label{
    &.is-new {
      position:absolute;
      bottom: 6px;
      left: 6px;
      width: 32px;
      height: 32px;
      border-radius: 50% ;
      background-color: #E12D2D;
      box-shadow: inset 0 2px 3px rgba(255, 255, 255, 0.3), inset 0px -2px 3px rgba(60, 18, 5, 0.4);
      background-image: var(--icon-new);
      background-repeat: no-repeat;
      background-position: center;
      line-height: 32px;
    }
    &.is-exclusive {
      position:absolute;
      top: 0;
      left: 0;
      width: 81px;
      height: 81px;
      background-image: var(--icon-exclusive);
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  &__label.is-dropAndWins {
    position: absolute;
    top: 0;
    left: 0;
    width: 57px;
    height: 52px;
    background-image: var(--icon-drops-wins);
    background-repeat: no-repeat;
    border-top-left-radius: 12px;
  }
}
