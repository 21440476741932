$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.popup__wrap.shop-item-crab-confirm-popup {
  padding: 56px 40px 60px;

  .popup__title {
    font-family: 'Roboto Condensed', Helvetica, Arial, sans-serif;
    text-transform: none;
    margin-bottom: 16px;
  }
}

.shop-item-crab-confirm {
  margin-bottom: 28px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__subtitle {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: $color-black;
    margin-bottom: 16px;
    text-align: center;
  }

  &__count {
    font-family: 'Roboto Condensed', Helvetica, Arial, sans-serif;
    align-items: center;
    display: flex;
    color: $color-black;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-transform: uppercase;
  }

  &__icon {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-image: url($cdn + '/fezbet/shop/shop-coin.webp');
    display: block;
    width: 24px;
    height: 24px;
    margin: 0 4px 0 8px;
  }

  &__banner {
    position: relative;
    display: block;
    width: 100%;
    height: 205px;
    border-radius: 20px;
    background: linear-gradient(90deg, #924215 0%, #000 100%);
    margin-bottom: 16px;

    &-border {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 20px;
      border: 3px solid rgba(255, 220, 38, 0.5);
      pointer-events: none;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 145px;
      width: 100%;
      background: rgba(255, 255, 255, 0.10);
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      pointer-events: none;
    }

    &-icon {
      position: relative;
      background-image: url($cdn + '/fezbet/shop/shop-popup-crab.webp');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      display: block;
      width: 207px;
      height: 141px;
      margin: 16px auto 0;
    }

    &-text {
      font-family: 'Roboto Condensed', Helvetica, Arial, sans-serif;
      color: $color-white;
      font-size: 32px;
      line-height: 28px;
      font-weight: 700;
      text-align: center;
      margin-bottom: 16px;
    }
  }

  &__btn {
    a {
      display: flex;
      justify-content: center;
      margin: 0 auto;
      padding: 0 42px;
      width: fit-content;
    }
  }
}
