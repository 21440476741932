$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.tournament-howto {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 836px;
  width: 100%;
  padding: 0 32px;
  margin: 36px 0 0 auto;

  &__title {
    @include text(24px, 28px, 700, $color-white);

    margin-bottom: 12px;
    text-transform: uppercase;
    font-family: $font-roboto;
  }

  &__description {
    @include text(18px, 28px, 400, $color-white);

    margin-bottom: 24px;
  }

  &__item {
    @include text(17px, 27px, 400, $color-black);

    display: flex;
    align-items: center;
    min-height: 80px;
    max-width: 444px;
    padding: 8px 20px 8px 64px;
    margin-bottom: 24px;
    background-color: $color-white;
    border-radius: 16px;
    background-position: left center;
    background-repeat: no-repeat;
    background-size: auto;
    letter-spacing: -0.2px;

    &:lang(ar) {
      padding: 8px 64px 8px 20px;
      background-position: right center;
    }

    @include bp1440 {
      padding: 8px 14px 8px 70px;
      margin-bottom: 0;
      font-size: 15px;

      &:lang(ar) {
        padding: 8px 70px 8px 14px;
      }
    }
  }

  &__wrap {
    @include bp1440 {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 24px;
    }
  }

  @include bp1920 {
    max-width: 1174px;
  }
}

.tournament-euro2024 {
  .tournament-howto {
    margin: 46px auto 0;
    max-width: unset;

    &__title {
      margin-bottom: 16px;
    }

    &__wrap {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 16px;
    }

    &__item {
      border-radius: 8px;
      margin-bottom: 0;
      max-width: unset;
    }
  }

  .tournament__category {
    display: block;
  }
}
