$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.accordion {
  p {
    color: $color-white;
    padding-bottom: 8px;
  }

  section {
    position: relative;
    counter-increment: section;

    &::before {
      @include text(18px, 36px, bold, $color-black);

      position: absolute;
      top: 20px;
      left: 26px;
      width: 36px;
      height: 36px;
      text-transform: uppercase;
      text-align: center;
      background-color: #E7E7E4;
      border-radius: 50%;
      font-family: var(--font-family-main);
      content: counter(section);
    }

    &:lang(ar) {
      &::before {
        left: unset;
        right: 26px;
      }
    }

    p {
      color: $color-black;

      &:last-child {
        padding-bottom: 0;
      }
    }
  }

  h2 {
    cursor: pointer;
    padding: 24px 50px 0 44px;

    &::after {
      content: '';
      background-image: var(--icon-arrow-down);
      background-size: cover;
      background-repeat: no-repeat;
      width: 24px;
      height: 24px;
      position: absolute;
      inset-inline-end: 32px;
      top: 26px;
      transform: rotate(0deg);
      transition: .3s ease;
    }

    & ~ * {
      display: none;
    }

    &.open {
      padding-bottom: 16px;

      & ~ * {
        display: block;
      }

      & ~ ul {
        padding-left: 24px;

        &:lang(ar) {
          padding-left: 0;
          padding-right: 24px;
        }

        li {
          list-style-type: disc;
        }
      }

      &::after {
        transform: rotate(180deg);
        filter: $filter-grey;
      }
    }
  }

  @include bp1440 {
    section {
      padding-bottom: 28px;

      &::before {
        top: 24px;
        left: 34px;
      }

      &:lang(ar) {
        &::before {
          left: unset;
          right: 34px;
        }
      }
    }

    h2 {
      padding: 28px 40px 0 52px;

      &.open {
        padding-bottom: 20px;
      }

      &::after {
        right: 40px;
        top: 30px;
      }

      &:lang(ar) {
        &::after {
          right: unset;
          left: 40px;
        }
      }
    }
  }

  .cookie_link {
    font-size: 18px;
    font-weight: 400;
    color: $color-yellow;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}
