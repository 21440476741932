$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.bonus-wrap {
  display: flex;
  align-items: center;
  background: #F6F6F4;
  border-radius: 20px;
  padding: 24px;

  &.on-board {
    @include maxbp1280 {
      justify-content: center;

      .bonus-wrap__wgt {
        width: 100%;
      }

      .bonus-wrap__table {
        display: none;
      }
    }
  }

  &__wgt {
    width: 336px;
    flex-shrink: 0;
  }

  &__table {
    padding-left: 16px;
    flex-grow: 1;

    &:lang(ar) {
      padding-left: 0;
      padding-right: 16px;
    }
  }

  &__tr {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 16px;
    align-items: center;
    height: 44px;

    font-size: 13px;
    line-height: 16px;
    color: #19191D;

    &:not(:first-of-type) {
      background-image: linear-gradient(to right, var(--grLineBlack));
      background-size: 100% 1px;
      background-repeat: no-repeat;
      background-position: center top;
    }
  }

  &__key,
  &__val {
    max-height: 32px;
    overflow: hidden;
  }

  &__key {
    font-size: 13px;
  }

  &__val {
    @include text(14px, 16px, bold, $color-grey);

    display: flex;
    justify-content: flex-end;
    text-align: right;

    &:lang(ar) {
      text-align: left;
    }
  }

  @include bp1440 {
    padding: 30px 32px 36px;

    &__table {
      padding-left: 24px;

      &:lang(ar) {
        padding-left: 0;
        padding-right: 24px;
      }
    }
  }
}
