$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.cash-crab-countdown{
  margin-top:8px;
  display: flex;
  justify-content: center;

  &__item{
    position:relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    &:not(:last-child){
      margin-right: 20px;
      &::after{
        content: ':';
        display: block;
        position:absolute;
        right: -10px;
        font-size: 32px;
        font-weight: 900;
        line-height: 48px;
      }
    }
  }
  &__time{
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    color: #19191D;
  }
  &__label{
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    color: #19191D;
  }
}