$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.home-providers {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 20px;

  &__item {
    padding-left: 52px;
    padding-right: 8px;
    height: 60px;
    background-size: 44px;
    background-position: left center;
    background-repeat: no-repeat;
    @include text-dots;
    @include text(16px, 60px, null, $color-white);
    letter-spacing: 0.02em;
    text-transform: capitalize;

    &:hover {
      color: $color-yellow;
    }
  }

  @include bp1280 {
    grid-template-columns: repeat(6, 1fr);
  }
  @include bp1440 {
    grid-template-columns: repeat(7, 1fr);
    grid-column-gap: 24px;
  }
}
