$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.cashbox-form {
  position: relative;
  width: 100%;
  margin-top: 20px;
  padding-bottom: 40px;

  &._crypto {
    margin-top: 0;
    padding-bottom: 4px;
  }

  &._preloader-payment {
    pointer-events: none;

    .payment-preloader {
      display: flex;
    }
  }

  &.hide-amount .if-hide-amount {
    display: none !important;
  }

  &__flex {
    display: flex;
    margin-bottom: 16px;
  }

  &__back.link-back {
    position: absolute;
    top: 0;
    left: 0;
    filter: none;
    z-index: 1;

    &:lang(ar) {
      right: 0;
      left: unset;
    }

    &:hover {
      color: $color-grey;
    }
  }

  &__img {
    width: 168px;
    height: 68px;
    display: block;
    margin: 0 auto;
    margin-bottom: 16px;

    &._crypto {
      width: 115px;
      height: auto;
      margin-bottom: 24px;
    }
  }

  &__amount {
    margin-top: 8px;
  }

  &__box.is-cashbox {
    width: 300px;
    margin: 0 auto;
    text-align: center;

    .form-input {
      text-align: center;
      border-radius: 12px;
      @include text(24px, 48px, 700);
      height: 48px;
    }
  }

  &__deposit-field {
    .hide-amount & {
      display: none !important;
    }
  }

  &__fields {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 8px;
  }

  &__text {
    @include text(14px, 20px, 400, $color-black);
    letter-spacing: 0.03em;
    text-align: center;

    &.is-desc {
      margin-top: 16px;
      font-weight: 700;
      color: $color-grey;

      .rtl-lang & span {
        display: inline-flex;
        direction: ltr;
      }
    }

    &.has-icon::before {
      content: '';
      display: inline-block;
      width: 20px;
      height: 20px;
      margin-inline-end: 5px;
      filter: $filter-grey;
      vertical-align: middle;
    }

    &._crypto {
      margin-top: 24px;
    }
  }

  &__btn {
    display: block;
    width: 100%;
    max-width: 300px;
    margin: 40px auto 0;

    .rtl-lang & span {
      display: inline-flex;
    }
  }

  &__payment-cop {
    color: #868a92;
    font-weight: 700;
    letter-spacing: .03em;
    padding-top: 16px;
    text-align: center;
  }

  &__guide {
    font-family: 'Montserrat', -apple-systemm, "Segoe UI", sans-serif;
    border: 2px solid $color-grey-middle;
    background-color: $color-white;
    border-radius: 12px;
    color: #131E3C;
    font-weight: 400;
    font-size: 14px;
    max-width: 600px;
    margin: 0 auto 20px;
    padding: 20px;

    ol {
      list-style-position: inside;
      display: grid;
      row-gap: 5px;

      ::marker {
        color: #5B667C;
      }
    }
  }

  .payment-preloader {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: $color-gray-light;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.05), inset 0 1px 1px rgba(195, 195, 195, 0.2), inset 0 -3px 6px rgba(195, 195, 195, 0.5), inset 0 6px 6px rgba(255, 255, 255, 0.8);
    border-radius: 12px;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    &__text {
      @include text(14px, 20px, 400, $color-black);
      max-width: 240px;
      margin-bottom: 12px;
    }

    &__icon {
      width: 24px;
      height: 24px;
      animation: spin 2s linear infinite;
    }
  }
}

@keyframes spin {
  to {
    transform: rotate(1turn);
  }
}
