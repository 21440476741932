$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.date-picker-wrapper {
    position: absolute;
    z-index: 4;
    background: $color-gray-light;
    box-shadow: 0px 8px 28px rgba(25, 25, 29, 0.3);
    border-radius: 20px;
    max-width: none;

    .month-wrapper {
        position: relative;
        overflow: hidden;
        width: 640px !important;

        table {
            float: left;
            width: 258px;
            margin-bottom: 33px;
        }

        tbody td {
        cursor: pointer;
        }

        .day {
            @include text(12px, 14px, normal, $color-black);
            height: 36px;
            padding: 10px 0;
            margin: 1px 0;
            text-align: center;
            background-image: none;
            border-radius: 50%;
        }

        // current day
        .day.real-today:not(.checked),
        .day.real-today:not(.hovering) {
            background-color: transparent;
            text-decoration: underline;
            font-weight: bold;

        }

        .day.checked,
        .day.toMonth.hovering {
            background-image: linear-gradient(to left, rgba(255, 220, 38, 0.3) 0%, rgba(255, 220, 38, 0.3) 100%);
        }

        .first-date-selected,
        .last-date-selected {
            font-weight: 700;
            background-color: $color-yellow;
            background-repeat: no-repeat;
            background-size: 50% 100%, 100%;
            background-position: left top, center;
            color: $color-black !important;
        }
        .first-date-selected {
            background-position: right top, center;
        }
        .day:hover{
            font-weight: 700;
            //background-image: radial-gradient(at center, $color-white 0, $color-white 69%, $color-gray-light 72%)!important;
            background-color: $color-white;
            background-size: 100%;
            background-position: center;
        }

    }

    &.single-date.single-month .month-wrapper {
        width: 320px !important;
    }

    &.single-month {
        .month-wrapper .first-date-selected,
        .month-wrapper .last-date-selected {
            //background-image: radial-gradient(at center, $color-yellow 0, $color-yellow 69%, $color-gray-light 72%) !important;
            background-size: 100%;
            background-position: center;
            background-color: $color-yellow;
        }

    }

    .month-name {
        @include text(20px, 24px, bold, $color-black);
        font-family: var(--font-family-main);
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 0.02em;
        height: 62px;
    }

    .prev,
    .next {
        float: left;
        height: 40px;
        width: 25px;
        font-size: 0;
        cursor: pointer;

        background-position: center;
        background-size: 20px;
        background-repeat: no-repeat;


        opacity: 0.5;
        margin-top: 11px;
    }

    .prev {
        transform: translateX(20px);
    }
    .next {
        transform: translateX(-20px);
    }

    .week-name {
        @include text(12px, 14px, bold, $color-black);
        text-transform: uppercase;
        height: 28px;
        th {
            height: 14px;
            width: 14px;
            color: $color-black;
            opacity: .8;
            text-align: center;
        }
    }

    .date-range-length-tip {
        display: none;
        position: absolute;

        font-size: 12px;
        line-height: 16px;
        color: #fff;

        margin-top: -4px;
        margin-left: -8px;
        padding: 0 6px;

        background-color: #686B6C;
        border-radius: 2px;

        &::after {
            content: '';

            position: absolute;
            left: 50%;
            bottom: -4px;

            margin-left: -4px;

            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            border-top: 4px solid #686B6C;
        }
    }
}

.date-picker-wrapper .select-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
}

.date-picker-wrapper .month-element {
    display: inline-block;
    vertical-align: middle
}

.date-picker-wrapper .select-wrapper select {
    position: absolute;
    margin: 0;
    padding: 0;
    left: 0;
    top: -1px;
    font-size: inherit;
    font-style: inherit;
    font-weight: inherit;
    text-transform: inherit;
    color: inherit;
    cursor: pointer;
    appearance: none;
    background: 0 0;
    border: 0;
    outline: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=1)";
    filter: alpha(opacity=1);
    opacity: .01
}

  .date-picker-wrapper .month-wrapper table div.day.lastMonth,
  .date-picker-wrapper .month-wrapper table div.day.nextMonth {
    cursor: default
  }


  .date-picker-wrapper .month-wrapper table .day.has-tooltip {
    cursor: help !important
  }

  .date-picker-wrapper .month-wrapper table .day.has-tooltip .tooltip {
    white-space: nowrap
  }

  .date-picker-wrapper .time label {
    white-space: nowrap
  }

  .date-picker-wrapper .month-wrapper table .day.lastMonth,
  .date-picker-wrapper .month-wrapper table .day.nextMonth {
    display: none
  }


  .date-picker-wrapper .selected-days {
    display: none
  }



  .date-picker-wrapper .time {
    position: relative
  }

  .date-picker-wrapper.single-month .time {
    display: block
  }


  .date-picker-wrapper .hide {
    display: none
  }
