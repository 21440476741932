$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.game-search {
    background-image: linear-gradient(to left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 51.04%, rgba(0, 0, 0, 0) 100% );
    background-repeat: no-repeat;
    background-size: 100% 1px;
    background-position: center bottom;
    margin-right: 16px;

    &:lang(ar) {
        margin-right: 0;
        margin-left: 16px;
    }

    &__item {
        display: flex;
        padding: 12px 5px 12px 17px;
        border-radius: 12px;

        &:lang(ar) {
            padding: 12px 17px 12px 5px;
        }

        &:hover {
            cursor: pointer;
            background: rgba(255, 220, 38, 0.5);
            padding: 8px 5px 8px 7px;
            margin: 4px 10px;
        }
    }
    &__img {
        width: 90px;
        height: 60px;
        border-radius: 8px;
        margin-right: 20px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        &:lang(ar) {
            margin-right: 0;
            margin-left: 20px;
        }
    }
    &__content {
        width: calc(100% - 110px);
    }
    &__title {
        max-width: 100%;
        @include text(18px, 28px, bold, $color-black);
        font-family: var(--font-family-main);
        text-transform: uppercase;
        margin-bottom: 4px;
        @include text-dots;
        span {
            color: $color-grey;
        }
    }
    &__text {
        max-width: 100%;
        @include text(14px, 20px, normal, $color-black);
        @include text-dots;
    }
}
