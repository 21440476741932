$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.profile-avatar{
  position:relative;
  height: 192px;
  background: #2D2D32;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px 26px 0 28px;

  &__num{
    margin-left: -5px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    color: #19191D;
    font-family: var(--font-family-main);
    letter-spacing: 0em;
    font-size: 120px;
    line-height: 100px;
    margin-top: 7px;
    transform: translateY(5px);
  }
  &__lvl{
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.03em;
    color: #868A92;
    text-transform: capitalize;
    @include text-dots;
  }
  &__name{
    font-size: 28px;
    line-height: 35px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #FFFFFF;
    font-family: var(--font-family-main);
    font-weight: bold;
    margin-bottom: 4px;
    @include text-dots;

    &:lang(el) {
      font-size: 18px;

      @include bp1440 {
        font-size: 24px;
      }
    }
  }

  &__img{
    width: 200px;
    height: 200px;
    position:absolute;
    right: 0;
    bottom: -32px;
    background-size: contain;

    &:lang(ar) {
      transform: scale(-1, 1);
      right: unset;
      left: -30px;
    }
  }
}
