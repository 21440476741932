$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.season-banner {
    position: relative;
    height: 156px;
    overflow: hidden;
    width: auto;
    padding-right: 20px;
    margin-top: 28px;
    display: flex;
    justify-content: end;
    align-items: center;
    background-color: #4491CC;

    @include bp1280 {
        height: 197px;
        margin-left: 168px;
        margin-top: 22px;
        border-radius: 16px 0 0 16px;
    }

    @include bp1920 {
        padding-right: 40px;
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        background-position: right;
        z-index: 2;
        width: 114px;
        filter: invert(91%) sepia(99%) saturate(5370%) hue-rotate(9deg) brightness(107%) contrast(103%);
        background-image: url( $cdn + '/fezbet/tennis-tournament/australian-open-banner-left.svg');
        background-repeat: no-repeat;
        background-size: cover;

        @include bp1280 {
            width: 130px;
        }

        @include bp1920 {
            width: 200px;
        }
    }

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        height: 100%;
        background-image: url( $cdn + '/fezbet/tennis-tournament/australian-open-banner.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    &__img {
        width: 172px;
        height: 106px;
        background-image: url( $cdn + '/fezbet/tennis-tournament/australian-open-logo.svg');
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        transform: translateX(-50%);
        left: 50%;
        z-index: 2;
        filter: invert(91%) sepia(93%) saturate(24%) hue-rotate(251deg) brightness(108%) contrast(100%);

        @include bp1280 {
            height: 134px;
            width: 216px;
        }
    }

    &__btn {
        z-index: 2;
        min-width: 200px;

        @include bp1440 {
            min-width: 238px;
        }
    }
}
