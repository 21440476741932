$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.trustly-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;

  &__wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    max-width: 910px;

    &::before {
      content: "";
      position: absolute;
      width: 132px;
      height: 132px;
      background-image: url( $cdn + '/fezbet/elements/circle.png');
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      bottom: 0px;
      right: -30px;
      z-index: 1;
    }
    &::after {
      content: "";
      position: absolute;
      width: 25px;
      height: 25px;
      background-image: url( $cdn + '/fezbet/elements/circle.png');
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      bottom: 175px;
      right: -85px;
      z-index: 1;
    }
  }
  &__left {
    max-width: 288px;
  }
  &__right {
    margin-left: 32px;
    flex-shrink: 0;
    color: $color-black;
    width: 590px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(25, 25, 29, 0.5);
    border-radius: 20px;
    background-color: $color-gray-light;
    z-index: 1;
  }
  &__steps {
    margin-bottom: 40px;
  }
  &__title {
    @include text(44px, 56px, bold, $color-yellow);
    font-family: var(--font-family-main);
  }
  &__terms {
    width: 100%;
    margin-top: 24px;
    @include text(18px, 24px, null, $color-white);
    text-align: center;
    z-index: 1;
  }
  &__link {
    color: $color-yellow;
  }
  &__frame {
    height: 428px;
     & iframe {
       border-radius: 20px;
       background-color: $color-white;
     }
  }

  @include bp1280 {
    &__terms {
      margin-top: 40px;
    }
  }
  @include bp1440 {
    &__wrap {
      &::before {
        width: 154px;
        height: 154px;
        right: -65px;
      }
    }
  }
  @include bp1680 {
    &__wrap {
      &::before {
        width: 190px;
        height: 190px;
        right: -95px;
      }
      &::after {
        width: 31px;
        height: 31px;
        bottom: 230px;
        right: -145px;
      }
    }
  }

}
