$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.widget-big-win {
  overflow: hidden;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(25, 25, 29, 0.5);
  background: rgba(225, 45, 45, 0.3);
  grid-column: 1/1;
  grid-row: 2/2;
  cursor: pointer;

  &:hover {
    & .widget-big-win__btn {
      box-shadow: 0 2px 8px rgba(25,25,29,.25), inset 0 10px 12px rgba(255,255,255,.8), inset 0 -10px 12px #e88e0f;
      transform: translateY(-2px);
      transition: .3s;
    }

  }

  &__wrap {
    padding: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }

  &__img {
    width: 100%;
    height: auto;
    border-radius: 12px;
    margin-bottom: 4px;
  }

  &__title {
    @include text(16px, 24px, bold, $color-white);
    @include text-dots;
    font-family: var(--font-family-main);
    letter-spacing: 0.02em;
    text-transform: uppercase;
    margin-bottom: 4px;
    max-width: 100%;
  }

  &__amount {
    @include text(24px, 28px, bold, $color-yellow);
    @include text-dots;
    font-family: var(--font-family-main);
    max-width: 100%;
    margin-bottom: 8px;
  }

  &__name {
    @include text(12px, 16px, bold, $color-white);
    @include text-dots;
    margin-bottom: 8px;
    max-width: 100%;
  }
  &__link {
    color: $color-yellow;
    text-decoration: underline;
  }


  & .show-month {
    display: none;
  }
  &.is-month {
    grid-column: 4/4;
    grid-row: 1/1;

    & .hide-month {
      display: none;
    }
    & .show-month {
      display: block;
    }
  }

}
