$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.link-black {
  @include text(18px, 20px, null, $color-black);
  text-transform: uppercase;
  font-family: var(--font-family-main);

  &:hover {
    color: $color-grey;
  }
}
