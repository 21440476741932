$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.lang {
  display: inline-flex;
  position: relative;
  height: 33px;
  min-width: 160px;

  &__list {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0 12px;
    justify-content: space-between;
    position: absolute;
    bottom: -14px;
    width: 100%;
    min-height: 32px;
    padding: 12px;
    background-color: $color-grey-dark;
    border-radius: 20px;
    box-sizing: content-box;

    & > .lang__list-item:hover {
      .lang__list-text {
        color: $color-yellow;
      }

      .lang__list-img {
        box-shadow: 0 0 0 4px $color-yellow;
      }
    }

    &-item {
      display: none;

      &.is-active {
        display: flex;
        align-items: center;
        width: 100%;
      }
    }

    &-img {
      width: 24px;
      height: 24px;
      box-shadow: 0 0 0 2px $color-black;
      border-radius: 50%;
    }

    &-text {
      @include text(18px, 24px, normal, $color-white);

      padding: 0 12px;
      font-family: var(--font-family-main);
      letter-spacing: 0.05em;
      text-transform: uppercase;
    }
  }

  &:hover {
    cursor: pointer;

    .lang__list {
      grid-template-columns: 1fr 1fr;
      bottom: -10px;
      z-index: 99;
      width: fit-content;
      min-width: 280px;
      padding: 4px 12px;
      border-radius: 20px;
      box-shadow: 0 4px 20px #19191D;
    }

    .lang__list-item {
      display: flex;
      align-items: center;
      margin: 8px 0;

      &.is-active {
        .lang__list-text {
          color: $color-yellow;
        }

        .lang__list-img {
          box-shadow: 0 0 0 4px $color-yellow;
        }
      }
    }
  }
}
