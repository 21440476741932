$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.spin-filter {
  position: relative;
  width: 560px;
  display: flex;
  padding-top: 24px;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto 24px;

  &__item {
    text-transform: uppercase;
    z-index: 1;
    color: $color-grey;
    position: relative;
    display: flex;
    flex: 1 1;
    justify-content: center;
    padding: 12px 4px 16px;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
    opacity: 0.8;

    &::after {
      content: "";
      position: absolute;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: $color-black;
      border: 1px solid $color-grey-dark;
      left: 50%;
      transform: translateX(-50%);
      bottom: 7px;
      z-index: 1;
      pointer-events: none;
    }

    &.is-active {
      color: $color-white;
      opacity: 1;

      &::after {
        width: 6px;
        height: 6px;
        background-color: $color-white;
        border-color: $color-grey;
        bottom: 6px;
      }
    }
  }

  &.is-profile {
    display: flex;
    width: 100%;
    padding-top: 4px;
    padding-left: 5px;
    gap: 4px;
  }
  .is-profile & {

    &__item {
      padding: 4px 12px;
      width: fit-content;

      &:hover {
      }
      &.is-active,
      &:active {
      }
    }
  }

  .gradient-lines {
    position: absolute;
    bottom: 8px;
    height: 2px;

    .line1,
    .line2 {
      height: 1px;
    }
  }
}

.spin-filtered__grid {
  position: relative;
  display: grid;
  min-height: 125px;
  grid-gap: 20px;
  grid-template-columns: repeat(2, 1fr);

  @include bp1280 {
    grid-template-columns: repeat(3, 1fr);
  }

  @include bp1920 {
    grid-template-columns: repeat(4, 1fr);
  }
}
