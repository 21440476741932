$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.promos-howto {
  display: flex;
  min-height: 88px;

  &__title {
    flex: 0 0 200px;
    margin-top: auto;
    margin-bottom: 4px;
    margin-right: -45px;

    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: .03em;
    text-transform: uppercase;
    color: rgba($color-grey, .5);

    &:lang(ar) {
      margin-right: 0;
      margin-bottom: 14px;
    }

    @include bp1440 {
      flex-basis: 270px;
      margin-bottom: 0;
      margin-right: -124px;

      font-size: 24px;
      line-height: 30px;
    }
  }

  &__progress {
    flex: 1 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    margin-top: 12px;
    @include text(24px, 140%, 700, rgba($color-grey, .5));
    font-family: var(--font-family-main);
    text-align: center;
    letter-spacing: .03em;
    text-transform: uppercase;
    background-repeat: no-repeat;
    background-image:
      linear-gradient(270deg, var(--grLineWhite)),
      linear-gradient(270deg, var(--grLineBlack));
    background-size: 100% 2px, 100% 2px;
    background-position: 0 48px, 0 50px;

    &:lang(at),
    &:lang(de),
    &:lang(hu),
    &:lang(el) {
      font-size: 18px;
    }

    @include bp1440 {
      gap: 24px;
      font-size: 32px;
      line-height: 40px;
    }
  }

  &__item {
    position: relative;
    color: inherit;
    transition: 0.2s;

    &::after {
      content: '';

      position: absolute;
      left: 50%;
      top: 44px;
      transform: translateX(-50%);

      width: 10px;
      height: 10px;
      border-radius: 100%;
      background-image: var(--grCircleDark);
    }

    &:hover {
      color: $color-white;
      &::after {
        background-image: var(--grCircleLight);
        width: 16px;
        height: 16px;
        top: 41px;

        @include bp1440 {
          width: 18px;
          height: 18px;
          top: 40px;
        }
      }
    }
  }
}
