$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
//////////////////////////////////////
//styles for slider banner and home-reg (home page)
//////////////////////////////////////

.trustly-banner {

  &.is-yellow .trustly-widget__title {
    color: $color-yellow;
    max-height: 84px;
    overflow: hidden;
  }

  .trustly-widget {
    padding: 0;
    flex-wrap: wrap;
    justify-content: flex-start;
    background-image: none;
    border-bottom: none;
    margin-bottom: 28px;

    &__form {
      flex-direction: column;
      margin-top: 16px;
    }

    &__logo {
      margin-top: 21px;

    }

    &__title {
      max-width: 100%;
      @include text(36px, 42px);
      max-height: 84px;
      white-space: pre-wrap;
      order: -1;
      padding: 0;
      width: 100%;
    }

    &__btn {
      margin-left: 0;
      margin-top: 28px;
    }

    @include bp1440 {
      margin-bottom: 36px;
      &__title {
        @include text(44px, 56px);
        max-height: 112px;
      }

      &__btn {
        margin-top: 36px;

        .is-banner {
          display: block;
        }
        .is-widget {
          display: none;
        }
      }
    }

  }


}
