$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.home-live-casino {
  background: rgba(225, 45, 45, 0.3);
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  max-height: 320px;


  &__content {
    padding: 20px;
  }
  &__title {
    @include text(28px, 36px, bold, $color-white);
    letter-spacing: 0.02em;
    text-transform: uppercase;
    height: 72px;
    overflow: hidden;
    font-family: var(--font-family-main);
    &:hover { opacity: .8; }
  }
  &__providers {
    display: flex;
    flex-direction: column;
    margin-top: 36px;
  }
  &__provider {
    @include text-dots;
    @include text(16px, 60px, normal, #fff);
    letter-spacing: 0.02em;
    text-transform: capitalize;
    padding-left: 52px;
    background-image: url($cdn + '/fezbet/main/live-casino.jpg');
    background-repeat: no-repeat;
    background-size: 44px;
    background-position: left center;

    &:hover {
      color: $color-yellow;
    }
  }
  &__img {
    width: calc(100% - 156px);
    height: 320px;
    background-image: url($cdn + '/fezbet/main/live-casino.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 0 20px 20px 0;
  }
}
