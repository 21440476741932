$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.ferrari-terms {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 800px;
  margin: 0 auto;

  &__btn2 {
    display: none;
  }

  &.is-terms-open {
    .ferrari-terms__btn1 {
      display: none;
    }

    .ferrari-terms__btn2 {
      display: block;
    }

    .ferrari-info-page-terms .ferrari-terms-info {
      max-height: 2000px;

      &::before {
        z-index: -1;
      }
    }
  }

  &__show {
    margin-bottom: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
