$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.promo-page {
  display: grid;
  grid-template-areas:
  'back'
  'promo-top-gap'
  'promo'
  'promo-btm-gap'
  'btns';
  grid-template-rows:
    auto
    26px
    auto
    32px
    auto;

  margin-top: 26px;
  margin-bottom: 20px;
  width: 100%;

  @include bp1280 {
    grid-template-areas:
      'back promo'
      'back promo-btm-gap'
      'back btns';
    grid-template-columns: 148px auto;
    grid-template-rows:
      auto
      32px
      auto;
    margin-top: 20px;
  }


  &__back {
    grid-area: back;
    padding-top:20px;
  }

  &__item {
    grid-area: promo;
  }
  &__btns {
    grid-area: btns;

    display: flex;
    justify-content: space-between;
  }

}
