$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.home-jackpot {
  background-color: rgba(225, 45, 45, 0.6);
  background-image: url( $cdn + '/fezbet/main/jackpot.png' );
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
  border-radius: 20px;
  grid-column: 1/-1;
  height: 232px;
  padding: 20px 20px 24px;
  text-align: center;
  overflow: hidden;

  &__title {
    @include text(28px, 36px, bold);
    font-family: var(--font-family-main);
    letter-spacing: 0.05em;
    text-transform: uppercase;
    @include text-dots;
  }
  &__sum {
    @include text(80px, 80px, bold, $color-yellow);
    font-family: var(--font-family-main);
    margin-top: 12px;
    margin-bottom: 16px;
    @include text-dots;
    background: linear-gradient(180deg, #FFF0A2 0%, #F0AA17 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  @include bp1280 {
    grid-column: auto;
    height: 320px;
    padding: 38px 6px 46px;

    &__sum {
      font-size: 44px;
      margin-top: 36px;
      margin-bottom: 40px;
    }
  }
  @include bp1440 {
    padding: 38px 20px 46px;
    &__sum { font-size: 48px; }
  }
  @include bp1680 {
    &__sum { font-size: 56px; }
  }
}
