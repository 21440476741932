$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.bigwin{
  position:absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;


  &__wrap {
    width: 100%;
    height: 100%;
    background: $color-white;
    border-radius: 20px;
    letter-spacing: 0.02em;
  }

  &__content {
    width: calc(100% - 158px);
    height: 100%;
    margin-left: auto;
    padding: 20px 20px 28px 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
  }

  &__title {
    @include text(20px, 32px, bold, $color-black);
    font-family: var(--font-family-main);
    width: 100%;
    overflow: hidden;
    padding-bottom: 8px 0;
    text-transform: uppercase;
    @include text-dots;
  }

  &__sum {
    @include text(24px, 28px, bold, $color-yellow);
    width: 100%;
    padding-bottom: 4px;
    text-transform: uppercase;
    @include text-dots;
  }

  &__text {
    @include text(14px, 20px, normal, $color-black);
    overflow: hidden;
    margin-bottom: 14px;
    max-height: 40px;
  }

  &__image {
    width: 134px;
    height: 201px;
    position: absolute;
    bottom: 17px;
    left: 24px;
    border-radius: 12px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    box-shadow: 0px 4px 8px rgba(25, 25, 29, 0.5);
  }

  &__stub {
    width: 100%;
    height: 100%;
    background-image: url($cdn + '/fezbet/elements/latest_win.jpg');
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    border-radius: 12px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    text-align: center;
    font-family: var(--font-family-main);
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  &__stub-title {
    @include text(18px, 28px, bold, $color-white);
    padding: 0 0 16px 0;
    margin: 0 auto;
    overflow: hidden;
    @include text-dots;
  }

  &__stub-description {
    @include text(24px, 32px, bold, $color-yellow);
    margin: 0 auto;
    height: 64px;
    word-wrap: break-word;
    overflow: hidden;
    padding: 0 16px;
    margin-bottom: 22px;
  }

  @include bp1440 {
    &__sum {
      @include text(28px, 32px, bold, $color-yellow);
      padding-bottom: 8px;
    }
  }
}
