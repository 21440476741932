$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.popup-page {
    background-color: $color-black;
    color: $color-white;
    width: 100%;
    display: flex;
    position: relative;
    min-width: 1024px;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-image: url( $cdn + '/fezbet/elements/pattern.png'), url( $cdn + '/fezbet/elements/gradient_circle.png');
    background-size: 1000px auto, contain;
    background-position: center, left -60px top -300px;
    background-repeat: repeat, no-repeat;

    &__wrap {
        width: 832px;
        min-height: 460px;
        max-height: 528px;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            width: 190px;
            height: 190px;
            background-image: url( $cdn + '/fezbet/elements/circle.png');
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            bottom: -64px;
            right: -40px;
            z-index: 1;
        }

        &::after {
            content: "";
            position: absolute;
            width: 31px;
            height: 31px;
            background-image: url( $cdn + '/fezbet/elements/circle.png');
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            bottom: 160px;
            right: -63px;
            z-index: 1;
        }
    }

    &__box {
        display: flex;
        justify-content: space-between;
        position: relative;
    }

    &__close {
        @include text(14px, 20px, null, $color-black);
        position: absolute;
        top: 0;
        inset-inline-end: -90px;
        width: 80px;
        height: 50px;
        cursor: pointer;
        background-image: var(--icon-close);
        background-position: top center;
        background-size: 36px 36px;
        background-repeat: no-repeat;
        filter: $filter-grey;
        display: flex;
        justify-content: center;
        align-items: flex-end;

        &:hover {
            filter: $filter-white;
        }
    }

    &__left {
        width: 372px;
    }

    &__right {
        width: 400px;
        z-index: 2;
        position: relative;
    }

    &__trustly {
        position: absolute;
        left: 50%;
        transform: translate(-50%);
        bottom: -72px;

        &:hover {
            transform: translate(-50%, -2px);
        }
    }

    &__bnr {
        margin-top: 28px;
    }

    &__title {
        font-family: var(--font-family-main);
        @include text(44px, 56px, bold, $color-yellow);
    }

    &__subtitle {
        @include text(18px, 24px);
        margin-top: 8px;
    }

    &__link {
        color: $color-yellow;
    }

    @include bp1440 {
        background-position: center,left top -300px;

        &__wrap {
            &::before {
                right: -108px;
            }
            &::after {
                bottom: 180px;
                right: -163px;
            }
        }
    }
}

