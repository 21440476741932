$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.tournament-title {
  @include text-dots;
  @include text(64px, 80px, bold, rgba(134, 138, 146, 0.3));
  text-align: right;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  font-family: var(--font-family-main);
  margin: 20px 24px 0;

  @include bp1440 {
    font-size: 80px;
    margin: 32px 36px 0;
  }

  @include bp1680 {
    font-size: 80px;
    max-width: 1872px;
    margin: 32px auto 0;
    padding: 0 36px;
  }
}
