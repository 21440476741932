$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.vip-card {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 40px 20px;
  margin-top: 60px;

  &__item {
    padding-left: 72px;
    padding-top: 20px;
    position: relative;
    background-image: url( $cdn + '/fezbet/elements/small_circle.png');
    background-repeat: no-repeat;
    background-size: 60px;
    background-position: 10px 10px;
    overflow: hidden;

    &:lang(ar) {
      padding-left: 0;
      padding-right: 72px;
      background-position: 100% 10px;

      &::before {
        right: 16px;
        left: unset;
      }
    }

    &::before {
      content: "";
      position: absolute;
      background-repeat: no-repeat;
      background-size: 100%;
      filter: $filter-white;
      width: 28px;
      height: 28px;
      top: 26px;
      left: 26px;
    }

    &_chat:before {
      background-image: var(--icon-i-live-chat);
    }
    &_card:before {
      background-image: var(--icon-i-card);
    }
    &_promo:before {
      background-image: var(--icon-i-promotion);
    }
    &_check:before {
      background-image: var(--icon-i-check);
    }

  }

  &__title {
    font-family: var(--font-family-main);
    @include text(32px, 120%, bold);
    margin-bottom: 20px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  &__text {
    @include text(16px, 150%);
  }

  @include bp1280 {
    margin-top: 40px;
    grid-gap: 40px 24px;
  }

}
