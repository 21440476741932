$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.ferrari-banner-stage {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 160%;
  height: 388px;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 24px;
  justify-content: center;
  flex-direction: column;
  position: relative;
  padding: 16px;

  .is-transform {
    transform: translateY(71px);
    width: 100%;
    line-height: 1;
    max-width: 170px;
    display: flex;
    height: 100%;
    max-height: 44px;
    white-space: pre-wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include bp540 {
      max-height: 50px;
      max-width: 200px;
    }

    @include bp768 {
      max-width: 170px;
      max-height: 44px;
    }
  }

  &.is-2-stage-banner {
    background-size: cover;
    max-width: 100%;

    @include bp1280 {
      max-width: 840px;
    }

    @include bp1440 {
      max-width: 100%;
    }
  }

  &.is-active-banner {
    background-size: 150%;
    background-image: url('/joxi/fezbet/ferrari-promo/desk-active-banner.png');
    background-color: #26262b;

    border: 2px solid rgba(255, 255, 255, 0.1);
  }

  &.is-inactive-banner {
    background-size: 150%;
    background-image: url('/joxi/fezbet/ferrari-promo/desk-inactive-banner.png');
    background-color: #272727;
  }

  .is-active-label {
    background: #ffdc26;
    color: #19191d;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    &:lang(ar) {
      flex-direction: row-reverse;
    }
  }

  &__terms {
    color: #fff;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    line-height: 120%;
    position: absolute;
    bottom: 12px;
    left: 12px;
    right: 12px;
    border-radius: 12px;
    background: rgba(255, 255, 255, 0.1);
    padding: 16px 8px;

    &.is-2-stage-terms {
      max-width: 768px;
    }
  }

  &__label {
    border-radius: 8px;
    padding: 0 12px;
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    background: #868a92;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 12px;
    inset-inline-start: 12px;
  }

  &__text {
    color: rgba(255, 255, 255, 0.5);
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    width: 100%;
    margin: 0 80px;
    max-width: 182px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
      content: '';
      position: absolute;
      height: 32px;
      width: 32px;
      background-image: url('/joxi/fezbet/ferrari-promo/fire.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      top: -40px;
    }

    &:lang(ar)::before {
      scale: -1 1;
    }

    &.is-reboot {
      &::before {
        background-image: url('/joxi/fezbet/ferrari-promo/reboot.svg');
      }
    }
  }
}
