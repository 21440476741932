$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.gh-page {
  padding:24px 0 0;
  width: calc( 100% - 48px );
  max-width: 1850px;
  margin:0 auto;
  display: flex;

  &__nav {
    width: 240px;
    flex-shrink: 0;
  }

  &__r {
    width: calc(100% - 240px);
    flex-grow: 1;
    padding-left:16px;

    &:lang(ar) {
      padding-left: 0;
      padding-right: 16px;
    }
  }

  &__slider{
    position:relative;
    max-width: 100% ;

    &:lang(ar) {
      direction: ltr;
    }
  }

  &__box{
    margin-top:20px;
  }

  @include bp1440 {
    padding:30px 0 61px;
    width: calc(100% - 72px);

    &__r{
      padding-left:32px;
    }

    &__box{
      margin-top:32px;
    }
  }
}
