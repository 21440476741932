$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.header-tooltip {
  position: relative;
  z-index: 80;
  cursor: pointer;
  height: 36px;
  background: rgba(255, 220, 38, 0.1);
  border-radius: 35px;
  width: fit-content;
  padding: 6px 16px 6px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover &__balance:after { transform: rotate(180deg); }

  &__currency {
    @include text(14px, 24px, bold, $color-yellow);
    padding-right: 4px;
  }

  &__balance {
    @include text(14px, 24px, bold, $color-yellow);

    display: flex;
    align-items: center;

    &::after {
      content: '';
      display: block;
      width: 16px;
      height: 16px;
      flex-shrink: 0;
      margin-inline-start: 10px;
      background: var(--icon-arrow-down);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      filter: $filter-yellow;
    }
  }

  &__box {
    width: 280px;
    min-width: 280px;
    position: absolute;
    right: 0;
    top: 100%;
    display: none;
    background: $color-grey-dark;
    border-radius: 12px;
    box-shadow: 0px 4px 20px #19191D;
    margin-top: 12px;
    padding: 0 16px 24px 16px;

    &::before {
      display: block;
      content: '';
      height: 12px;
      width: 100%;
      margin-top: -12px;
      background: transparent;
    }
  }

  &:hover &__box {
    display: block;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 45px;
    background: linear-gradient(181deg,var(--grLineWhite)),linear-gradient(181deg,var(--grLineBlack));
    background-repeat: no-repeat,no-repeat;
    background-size: 100% 1px,100% 1px;
    background-position: center 1px,center 2px;
    font-size: 14px;
    line-height: 16px;

    &:first-of-type {
      background: none;
    }

    &:hover {
      p,
      span {
        filter: $filter-grey-light;
      }
    }
  }

  &__description {
    @include text-dots;

    color: $color-white;
    margin-right: 4px;
  }

  &__number {
    font-weight: bold;
    color: $color-yellow;
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__icon {
    width: 16px;
    height: 16px;
    margin-right: 4px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    &:lang(ar) {
      margin-right: 0;
      margin-left: 4px;
    }

    &._coin {
      background-image: url($cdn + '/fezbet/shop/shop-coin.webp');
    }

    &._crab {
      background-image: var(--icon-nav_crab);
    }
  }

  &__deposit {
    margin: 7px 0 13px 0;
    width: 100%;
  }

  &__withdrwal {
    @include text(18px, 24px, bold, $color-yellow);

    height: 42px;
  }

  @include bp1440 {
    height: 44px;

    &__currency {
      font-size: 14px;
    }

    &__balance {
      font-size: 16px;

      &::after {
        top: 14px;
        right: 12px;
      }
    }
  }
}
