$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.layout-sport-wgt{
  width: 100%;
  max-width: 100%;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(2 , minmax(45%, 50%));
  grid-template-rows: repeat(2, 368px);
  grid-template-areas:
    'a a'
    'b c';
  color: $color-black;
  font-weight: 700;
  font-size: 14px;

  &__item:nth-of-type(1){
    grid-area: a;
  }

  @include bp1280 {
    grid-template-columns: repeat(3, minmax(20%, 33.333%)) ;
    grid-template-areas: 'a b c';
    grid-template-rows: 368px;
  }
}
