$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.form-show-pass{
  position:absolute;
  top: 32px;
  inset-inline-end: 12px;
  width: 16px;
  height: 16px;
  background-size: 16px;
  background-position: center;
  background-repeat: no-repeat;
  cursor:pointer;
  filter: $filter-grey;
}
