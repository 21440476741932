$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.profile-board{
  display: grid;
  grid-template-rows: auto 210px auto 260px auto ;
  grid-template-columns: repeat(2, 1fr);
  grid-gap:16px;
  grid-template-areas:
          "sp sp"
          "dp lw"
          "dp lw"
          "bn lw"
          "fv fv";

  &__sp{ grid-area: sp; }
  &__dp{ grid-area: dp; }
  &__bn{ grid-area: bn; }
  &__fv{ grid-area: fv; }
  &__bw{ grid-area: bw; }
  &__lw{ grid-area: lw; }
  &__i{
    position:relative;
  }

  @include bp1280 {
    grid-template-rows: auto 262px 210px auto ;
    grid-template-columns: 304px 1fr 348px;
    grid-template-areas:
            "sp sp sp"
            "dp bn bn"
            "fv fv lw"
            "fv fv lw";
  }

  @include bp1440 {
    grid-gap: 24px;
    grid-template-columns: 360px 1fr 360px;
    grid-template-rows: auto 280px 210px auto;
  }
  @include bp1680{
    grid-template-columns: 440px 1fr 360px;
  }
}
