$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.anim-tournament__container {
  position: relative;
  overflow: auto;
}

.table {
  $this: &;
  display: flex;
  flex-direction: column;

  &_double {
    #{$this}__head,
    #{$this}__item {
      grid-template-columns: 32px 1fr;
    }
  }

  &__title {
    @include text(24px, 44px, 700);

    font-family: var(--font-family-main);
    letter-spacing: .02em;
    text-transform: uppercase;
    padding-left: 20px;
    background-image:
      linear-gradient(180deg, var(--grLineWhite), var(--grLineWhite)),
      linear-gradient(180deg, var(--grLineBlack), var(--grLineBlack));
    background-repeat: no-repeat;
    background-size: 2px 100%;
    background-position: 0 0, 2px 0;
    white-space: nowrap;

    &:lang(ar) {
      padding-left: 0;
      padding-right: 20px;
      background-position: 100% 0, 100% 0;
    }
  }

  &__head {
    display: grid;
    grid-template-columns: 32px 64px 32px 1fr;
    grid-column-gap: 12px;
    @include text(12px, 24px, null, $color-grey);
    text-align: center;
    padding-right: 20px;
    padding-left: 12px;

    &:lang(ar) {
      padding-right: 12px;
      padding-left: 20px;
      text-align: right;
    }
  }

  &__head-item {
    max-height: 24px;
    overflow: hidden;
    &:nth-child(1) {
      text-align: center;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    max-height: 792px;
    overflow-y: scroll;
    overscroll-behavior: none;
    &.is-sport {
      max-height: 100%;
    }
    &.is-height-calc-max {
      height: calc($leaderboardHeightCalc * 11);
    }
    &.is-height-calc-min {
      height: calc($leaderboardHeightCalc * 10);
    }
  }

  &__item {
    flex: 0 0 auto;
    display: grid;
    align-items: center;
    grid-template-columns: 32px 64px 32px 1fr;
    grid-column-gap: 12px;
    padding: 8px 12px 10px;
    min-height: 54px;
    background-image:
      linear-gradient(270deg, var(--grLineWhite), var(--grLineWhite)),
      linear-gradient(270deg, var(--grLineBlack), var(--grLineBlack));
    background-repeat: no-repeat;
    background-size: 100% 1px;
    background-position: left 0 bottom 1px, left 0 bottom 0;

    &:last-child {
      background-image: none;
    }

    &:nth-child(1) #{$this}__cell:nth-child(1),
    &:nth-child(2) #{$this}__cell:nth-child(1),
    &:nth-child(3) #{$this}__cell:nth-child(1) {
      background-repeat: no-repeat;
      background-size: 32px;
    }

    &:nth-child(1) #{$this}__cell:nth-child(1) {
      background-image: url($cdn + '/fezbet/tournaments/place_1.svg');
    }

    &:nth-child(2) #{$this}__cell:nth-child(1) {
      background-image: url($cdn + '/fezbet/tournaments/place_2.svg');
    }

    &:nth-child(3) #{$this}__cell:nth-child(1) {
      background-image: url($cdn + '/fezbet/tournaments/place_3.svg');
    }
    &.is-current-user {
      background-color: rgba(255, 220, 38, 0.2);
      border-radius: 8px;
      color: #ffdc26;
    }
    &.is-current-user-ferrari {
      background-color: #ffdc26;
      border-radius: 8px;
      color: #000;
      font-weight: bold;
      position: sticky;
      bottom: 0;
      top: 0;
    }
  }

  &__cell {
    &:nth-child(1) {
      height: 32px;
      margin-top: -2px;
      padding-top: 2px;

      text-align: center;
    }
    font-size: 13px;
    line-height: 24px;
    text-align: center;

    &._user-name {
      text-align: start;

      &:lang(ar) {
        direction: ltr;
      }
    }
  }

  @include bp1440 {
    &__head,
    &__item {
      grid-template-columns: 32px 66px 52px 1fr;
    }
  }

  @include bp1680 {
    &__head,
    &__item {
      grid-template-columns: 32px 124px 124px 1fr;
    }
  }
}
