$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.widget-tournament {
  position: relative;
  margin: 20px 0;
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  width: 100vw;
  height: 432px;

  max-width: 2900px;


  &.has-status-2 {

    .widget-tournament__img {
      filter: grayscale(100%);
    }

    .widget-tournament__bg {
      background-image: linear-gradient(180deg, #B5B5B5 0%, #252525 100%) !important;
    }

    .widget-tournament__value {
      color: $color-white;
    }
  }

  &__wrap {
    position: relative;
    z-index: 2;
    overflow: hidden;
    height: 100%;
    border-radius: 20px;
    flex-shrink: 0;
    width: 744px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }

  &__bg {
    position: absolute;
    width: 100%;
    top: 92px;
    border-radius: 12px 12px 0 0;
    background-image: linear-gradient(180deg, var(--color1), var(--color2));
    z-index: -2;
    height: 100%;
  }

  &__label {
    position: absolute;
    left: 31px;
    top: 82px;
    z-index: 3;
    background: $color-yellow;
    border-radius: 8px;
    padding: 0 12px;
    max-width: 100px;
    text-align: center;
    @include text(14px, 20px, bold, $color-black);
    @include text-dots;
    font-family: var(--font-family-main);
  }

  &__img {
    background-size: contain;
    background-position: center top;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    z-index: -1;
    width: 500px;
    height: 640px;
    left: -64px;
  }

  &__content {
    margin-right: 20px;
    margin-bottom: 60px;
    width: 340px;
  }

  &__title {
    @include text(28px, 48px, bold, $color-white);
    font-family: var(--font-family-main);
    @include text-dots;
    margin-bottom: 12px;
  }

  &__value {
    @include text(60px, 72px, bold, $color-yellow);
    font-family: var(--font-family-main);
    @include text-dots;
    text-shadow: 0 4px 15px rgba(40, 39, 42, 0.1);
    margin-bottom: 12px;
  }

  &__btn {
    width: fit-content;
    margin-top: 36px;
    display: block;
  }

  &__games {
    display: flex;
    align-items: center;
    overflow: hidden;
    margin-left: -20px;
    padding-left: 40px;
    height: 340px;
    background-color: #2D2D32;
    z-index: 1;
    border-radius: 0 20px 20px 0;
    max-width: 1400px;
  }

  & .game {
    width: 200px;
    height: 300px;
  }

  &__games-bg {
    position: absolute;
    right: 36px;
    bottom: 0;
    width: 200px;
    height: 388px;
    z-index: 2;
    background-image: linear-gradient(270deg, #2D2D32 0%, rgba(45, 45, 50, 0) 100%);
    background-size: cover;
    background-position: right center;
    background-repeat: no-repeat;
  }

  &__games-link {
    position: absolute;
    right: 10px;
    bottom: 28px;
    width: 200px;
    height: 24px;
    z-index: 2;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    color: #ffdc26;
    padding-right: 36px;
    background-image: var(--icon-arrow-all);
    background-size: 16px;
    background-position: right 20px bottom 3px;
    background-repeat: no-repeat;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    &:hover {
      opacity: 0.3;
    }

  }

  & .tournament__games-grid {
    grid-template-columns: repeat(9, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 20px;
  }

  & .tournament__circle {
    left: 714px;
    z-index: 3;
    transform: translateX(0%);
  }

  & .tournament__games-more {
    display: none;
  }


  @include bp1440 {
    &__wrap {
      width: 839px;
    }
    &__img {
      left: 0;
    }
    &__games-bg {
      right: 60px;
    }

    & .tournament__circle {
      left: 809px;
    }
  }

}

