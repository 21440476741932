$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.balance-stub {
    margin: 12px 0 0;
    text-align: center;
    @include text(24px, 28px, bold, $color-grey);
    text-transform: uppercase;
    letter-spacing: 0.02em;
    overflow: hidden;
    font-family: var(--font-family-main);
}
