$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.top-league {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-column-gap: 20px;
    position: relative;
    z-index: 2;
    @include bp1280 {
        grid-template-columns: repeat(3,1fr);
        grid-column-gap: 24px;
    }
    @include bp1680 {
        grid-template-columns: repeat(4,1fr);
    }
    &__item {
        height: 144px;
        background: linear-gradient(90deg, rgba(146, 66, 21, 0.7) 0%, rgba(0, 0, 0, 0.7) 100%);
        border: 3px solid rgba(255, 220, 38, 0.5);
        border-radius: 20px;

        display: flex;
        align-items: center;
        padding: 9px 13px;

        &:nth-child(3),
        &:nth-child(4) {
            display: none;
        }

        &:hover {
            .link-next {
                background-color: rgba(255, 220, 38, 0.5);
                transition: background-color .2s;
            }
        }
        @include bp1280 {
            &:nth-child(3) {
                display: flex;
            }
        }
        @include bp1680 {
            &:nth-child(4) {
                display: flex;
            }
        }
    }
    &__img {
        border: 20px solid rgba(25, 25, 29, 0.4);
        width: 120px;
        height: 120px;
        border-radius: 50%;
        object-fit: contain;
        margin-right: 12px;
    }
    &__title {
        @include text(24px, 32px, bold, $color-yellow);
        font-family: var(--font-family-main);
        letter-spacing: 0.03em;
        text-transform: uppercase;
        margin-right: 16px;
        flex-grow: 1;
        max-height: 124px;
        overflow: hidden;
    }
    .link-next {
        flex-shrink: 0;
    }
}
