$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.btn-random {
  @include text-dots;

  max-width: 100%;
  display: inline-block;
  height: 36px;
  line-height: 36px;
  background-color: rgba(255, 220, 38, 0.3);
  background-image: url( $cdn + '/fezbet/gamehall/2x_randomegame.png');
  background-repeat: no-repeat;
  background-size: 28px;
  background-position: right 12px center;
  border-radius: 22px;
  padding:0 48px 0 16px;
  font-weight: bold;
  font-size: 12px;
  color: #FFFFFF;
  cursor:pointer;

  &:lang(ar) {
    padding: 0 16px 0 48px;
    background-position: left 12px center;
  }

  &:hover {
    color: $color-yellow;
  }
}
