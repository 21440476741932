$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.layout-main{
  &.has-sidebar {
    padding-left:56px;

    &:lang(ar) {
      padding-left: 0;
      padding-right: 56px;
    }
  }

  &__content{
    min-height: calc(100vh - 336px);
    background-image: url( $cdn + '/fezbet/elements/pattern.png');
    background-size: 1000px auto;
    padding-bottom: 60px;

    &.in-home{
      background-image: linear-gradient( to bottom , rgba(26,26,34,1) 0% , rgba(26,26,34,1) 70%, rgba(26,26,34,0) 100% ) ,  url( $cdn + '/fezbet/elements/pattern.png') ;
      background-size: 100% 900px , 1000px auto;
      background-repeat: repeat-x, repeat ;
    }

    &.is-shop-page {
      background-image: linear-gradient(180deg, #19191D 75%, rgba(25, 25, 29, 0.00) 100%), url( $cdn + '/fezbet/elements/pattern.png');
      background-size: 100% 670px , 1000px auto;
      background-repeat: repeat-x, repeat ;
    }
  }

  @include bp1440 {
    &.has-sidebar {
      padding-left:72px;

      &:lang(ar) {
        padding-left: 0;
        padding-right: 72px;
      }
    }

    &__content.in-home{
      background-size: 100% 1200px, 1000px auto;
    }
  }
}
