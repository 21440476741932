$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.shop {
  position: relative;
  display: block;
  width: 100%;
  color: $color-white;
  font-family: 'Roboto Condensed', Helvetica, Arial, sans-serif;

  &::before,
  &::after {
    content: "";
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    width: 683px;
    height: 345px;
    top: 0;
    pointer-events: none;
    z-index: 1;
  }

  &::before {
    left: 0;
    background-image: url($cdn + '/fezbet/shop/left-bg-cherry.webp');
    background-position: left;
  }

  &::after {
    right: 0;
    background-image: url($cdn + '/fezbet/shop/right-bg-ball.webp');
    background-position: right;
  }

  .container {
    @include bp1680 {
      padding: 0;
    }
  }

  &__banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 100%;
    height: 230px;
    margin: 0 auto;
    z-index: 2;
  }

  &__title {
    font-size: 40px;
    font-weight: 700;
    line-height: 120%;
    color: $color-yellow;
    margin: 56px 0 8px;
    text-transform: uppercase;
    text-shadow: 0 4px 14px 0 rgba(40, 39, 42, 0.10);
  }

  &__title-back {
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: fit-content;
    font-size: 248px;
    font-weight: 700;
    text-transform: uppercase;
    text-shadow: 0 24px 90px 0 rgba(40, 39, 42, 0.10);
    pointer-events: none;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.00) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &__coins {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 24px;
    padding-bottom: 40px;
    color: $color-white;
    line-height: 28px;
  }

  &__coins-item {
    position: relative;
    padding-right: 40px;
    color: $color-grey-middle;

    &:lang(ar) {
      padding-left: 40px;
      padding-right: 0;

      &::after {
        right: unset;
        left: 4px;
      }
    }

    &::after {
      content: "";
      position: absolute;
      right: 4px;
      top: 50%;
      transform: translateY(-50%);
      width: 28px;
      height: 28px;
      background-image: url($cdn + '/fezbet/shop/shop-coin.webp');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  &__coins-val {
    display: flex;
    align-items: center;
    font-weight: 500;
  }

  &__how-to-link {
    position: relative;
    color: $color-grey;
    line-height: 24px;
    font-size: 16px;
    padding-right: 16px;
    font-weight: 700;
    display: block;

    &:lang(ar) {
      padding-right: 0;
      padding-left: 20px;

      &::after {
        background-image:  var(--icon-arrow-left);
        right: unset;
        left: 0;
      }
    }

    &::after {
      content: "";
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      background-image:  var(--icon-arrow-right);
      filter: $filter-grey-light;
      width: 16px;
      height: 16px;
    }
  }

  &__exchange-text {
    text-align: center;
    text-transform: uppercase;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    margin-top: 32px;
    margin-bottom: 24px;
    color: $color-grey;
  }

  &__how-to {
    margin: 56px auto 0;
    max-width: 1200px;
  }

  &__subtitle {
    color: $color-grey;
    text-transform: uppercase;
    font-size: 24px;
    font-weight: 700;
    line-height: 114%;
    text-align: center;
  }

  &__freespin-subtitle {
    color: $color-white;
    font-size: 16px;
    font-weight: 500;
    line-height: 120%;
    text-align: center;
    margin-top: 8px;
  }

  &__how-to-items {
    padding-top: 16px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 4px;
  }

  &__how-to-title {
    color: $color-white;
    text-transform: uppercase;
    line-height: 20px;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 12px;
    margin-top: 20px;
  }

  &__how-to-text {
    color: $color-white;
    line-height: 20px;
    font-size: 14px;
    font-weight: 400;
  }

  &__how-to-item {
    position: relative;
    display: flex;
    max-width: 360px;

    .linda-has-remembered-login & {
      &.wasnt-logged {
        display: none;
      }

      &.was-logged {
        display: flex;
      }
    }

    &.was-logged {
      display: none;
    }
  }

  &__how-to-icon {
    display: flex;
    flex-shrink: 0;
    width: 60px;
    height: 60px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    &._deposit {
      background-image: url($cdn + '/fezbet/shop/how-to-earn-deposit.webp');
    }

    &._bets {
      background-image: url($cdn + '/fezbet/shop/how-to-earn-bets.webp');
    }

    &._tournaments {
      background-image: url($cdn + '/fezbet/shop/how-to-earn-tournament.webp');
    }
  }
}

.gradient-lines {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 4px;

  .line1 {
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.20) 51.04%, rgba(255, 255, 255, 0.00) 100%);
  }

  .line2 {
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.60) 51.04%, rgba(0, 0, 0, 0.00) 100%);
  }
}
