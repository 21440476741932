$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.profile-box{
  background: #F6F6F4;
  border-radius: 20px;
  padding:22px 24px 40px;
  @include bp1280 {
    padding:26px 32px 40px;
  }
  &__title{
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #19191D;
    font-family: var(--font-family-main);
    padding-bottom: 22px;
    @include bp1440 {
      padding-bottom: 26px;
    }
  }
}
