$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.ferrari-card {
  display: grid;
  grid-template-columns: 1fr 250px;
  padding: 92px 24px 0;
  gap: 24px;
  background-color: #19191d;

  @include bp1280 {
    grid-template-columns: 1fr 330px;
  }

  @include bp1440 {
    grid-template-columns: 1fr 430px;
  }

  &__final {
    display: flex;
  }

  .is-height {
    max-height: 450px;
  }

  .is-margin {
    margin-top: 0;
  }
}
