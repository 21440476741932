$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.vip-bnr {
  height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  background-image: url($cdn + "/fezbet/vip/bg-vip.png");
  background-repeat: no-repeat;
  background-size: 669px 260px;
  background-position: right -25% top;

  &:lang(ar) {
    background-position: left -25% top;
  }

  &__wrap {
    width: 100%;
    margin: 0 auto;
  }
  &__content {
    max-width: 480px;
    margin-left: 160px;

    &:lang(ar) {
      max-width: 660px;
      margin-right: 160px;
    }
  }

  &__title {
    font-family: var(--font-family-main);
    @include text(48px, 125%, bold, $color-yellow);
    text-shadow: 0 4px 15px rgba(40, 39, 42, 0.1);
    margin-bottom: 8px;
  }
  &__description {
    @include text(14px, 20px);
  }

  @include bp1280 {
    height: 280px;
    background-size: 912px 354px;

    &__content {
      max-width: 600px;
    }
    &__title {
      @include text(72px, 80px);
      margin-bottom: 16px;
    }
    &__description {
      @include text(16px, 24px);
    }
  }

  @include bp1680 {
    background-position: right top;
    height: 340px;

    &__wrap {
      max-width: 1608px;
    }
    &__content {
      margin-left: 348px;
    }
    &__title {
      @include text(80px, 88px);
      margin-bottom: 12px;
    }
  }
}
