$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.trustly-banner-home {
  text-align: center;

  &__title {
    @include text(36px, 42px, bold, $color-white);
    @include text-dots;
    margin-bottom: 12px;
    text-align: left;
  }

  &__block {
    width: 320px;
    margin-bottom: 16px;
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    padding: 12px 33px;
    background-image: url( $cdn + '/fezbet/main/promo1.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;

    &::before,
    &::after {
      content: "";
      background-image: url( $cdn + '/fezbet/main/promo1.jpg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      position: absolute;
      z-index: 1;
    }

    &::before {
      background-image: url( $cdn + '/fezbet/elements/circle.png');
      width: 36px;
      height: 36px;
      bottom: 51px;
      left: -23px;
    }
    &::after {
      background-image: url( $cdn + '/fezbet/elements/circle.png');
      width: 124px;
      height: 124px;
      top: 28px;
      right: -102px;
    }
  }

  &__label {
    @include text(16px, 18px, normal, #000);
    margin-bottom: 8px;
    text-align: left;
    @include text-dots;
  }

  &__btn-block {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 1px;
    margin-bottom: 8px;
    border-radius: 30px;
    overflow: hidden;
    background-image: linear-gradient(to right, #C4C4C4, #C4C4C4), linear-gradient(to right, #C4C4C4, #C4C4C4);
    background-repeat: no-repeat;
    background-size: 1px 38px;
    background-position: left 84px center, right 84px center;
  }

  &__btn-item {
    height: 38px;
    width: 84px;
    background-color: #E7E7E7;
    @include text(18px, 38px, 500, #8C8E90);
    overflow: hidden;
    @include text-dots;
    border: none;

    &:hover {
      background: linear-gradient(180deg, #FFF0A2 0%, #F0AA17 100%);
      color: #19191D;
    }

    &.is-active {
      background-color: #FFDC26;
      color: #555555;
    }
  }

  &__btn {
    background-image: var(--icon-arrow-right);
    background-repeat: no-repeat;
    background-position: right 16px center;
    background-size: 20px;
    background-color: #FFDC26;
    border-radius: 4px;
    border: none;
    height: 52px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0 30px;
    color: #2D2D32;

    &:hover {
      background: var(--icon-arrow-right), linear-gradient(180deg, #FFF0A2 0%, #F0AA17 100%);
      background-position: right 16px center, center;
      background-repeat: no-repeat;
      background-size: 20px, 100%;
    }
  }

  &__btn-span {
    @include text(24px, 29px, bold, #2D2D32);
    @include text-dots;
    max-width: 100%;
  }

  &__terms {
    @include text(10px, 13px, 600, #868A92);
    margin-top: 8px;
    height: 26px;
    overflow: hidden;
  }

  &__link {
    color: #000000;
    text-decoration: underline;
  }


  @include bp1440 {

    &__title {
      @include text(44px, 56px);

    }
  }


}






//for old disign
/*.trustly-banner {

  text-align: center;
  background-image: url( $cdn + '/gransino/trustly/main-bankid.png');
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 274px 140px;
  padding-top: 122px;

  & .form-box {
    max-width: 320px;
  }


  &__title {
    @include text(24px, 32px, 800, text);
    background: linear-gradient(180deg, #F6FF8B 0%, #9CEE5C 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    max-height: 64px;
    overflow: hidden;
    margin-bottom: 8px;
    max-width: 472px;

  }

  &__label {
    @include text(18px, 24px, 600, $color-white);
    text-align: center;
    margin-bottom: 8px;
    @include ellipsis;
  }

  &__btn {
    margin-top: 20px;
  }

  &__terms {
    @include text(14px, 24px, 600, $color-white);
    @include ellipsis;
    & a {
      @include text(14px, 24px);
    }
  }


  @include bp-1440 {
    background-size: 332px 170px;
    background-position: center top 28px;
    padding-top: 190px;

    &__title {
      @include text(36px, 48px);
      max-height: 96px;
      max-width: 580px;
      margin: 0 auto 12px;
    }

    &__btn {
      margin-top: 28px;
    }

    &__terms,
    &__terms a {
      @include text(18px, 28px);
    }

  }


}
*/
