$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.form-currency {
  position: absolute;
  left: 0;
  top: 0;
  margin-left: 16px;
  text-align: center;
  width: 43px;
  @include text(20px, 38px, 500, #19191D);
  margin-right: 2px;

  @include bp1440 {
    line-height: 52px;
  }

}

