$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.form-input {
  @include text(16px, 24px);

  display: block;
  box-sizing: border-box;
  height: 40px;
  width: 100%;
  max-width: 100%;
  padding: 0;
  padding-inline-start: 16px;
  padding-inline-end: 34px;
  border: 1px solid $color-grey;
  border-radius: 4px;

  &:lang(ar) {
    padding: 0 16px 0 34px;
    text-align: right;
  }

  .form-box:hover &:not(.is-trustly-banner) {
    border: 1px solid $color-black;
  }

  &::placeholder {
    color: $color-grey;
  }

  &:not(.is-trustly-banner):focus {
    border: solid 1px $color-yellow !important;
    box-shadow: inset 0 0 0 1px $color-yellow;
  }

  &.ng-invalid.ng-touched:not(:focus) {
    border: 1px solid $color-red;
  }

  &:disabled {
    background: rgba(134, 138, 146, 0.3);
    border: 1px solid $color-grey;
  }

  &.is-trustly {
    padding-right: 40px;
    padding-left: 16px;
    width: 160px;
    border-radius: 12px;
    text-align: center;
    @include text(24px, 44px, bold);
    height: 44px;
    border: 1px solid $color-grey;
    background-image: linear-gradient(-90deg, #E7E7E4 32px, white 32px);
    font-family: var(--font-family-main);

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  &.is-trustly-banner {
    height: 38px;
    @include text(20px, 38px, 500, #19191D);
    background-color: #FFFFFF;
    border: 1px solid #DFDFDF;
    border-radius: 26px;
    padding: 0 110px 0 61px;
    background-image: url( $cdn + '/fezbet/bankid/paymsystem_zimpler.svg' );
    background-repeat: no-repeat;
    background-size: 139px 38px;
    background-position: right -13px center;

    //&.ng-invalid.ng-touched:not(:focus) {
    //  border: 1px solid #E82B2B;
    //}

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    @include bp1440 {
      height: 52px;
      line-height: 52px;
      background-size: auto 42px;
      background-position: right -16px center;
      padding-right: 120px;
    }
  }

  &.restore-password:lang(el) {
    font-size: 12px;
  }
}
