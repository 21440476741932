$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.last-deposit {

    position: relative;
    display: flex;
    align-items: center;

    &__pay {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__icon-pay {
        width: 88px;
        height: auto;
    }

    &__sum {
        @include text(16px, 24px, bold, $color-black);
    }

    &__text {
        background: #FFDC26;
        border-radius: 4px;
        position: relative;

        @include text(12px, 16px, bold, $color-black);
        padding: 8px 12px;

        animation: bounce-horizontal 2s infinite;
        transform-origin: left right;

        &::after {
            content: '';
            position: absolute;
            right: -10px;
            top: 50%;
            width: 12px;
            height: 12px;
            background: #FFDC26;
            clip-path: polygon(0 0, 48% 42%, 50% 49%, 48% 55%, 0 100%);
            transform: translateY(-50%);

        }
    }

    &__btn {
        width: 44px;
        height: 44px;
        border-radius: 50%;
        border: none;
        background: #EEA315;
        box-shadow: inset 0px 4px 6px rgba(255, 255, 255, 0.8), inset 0px -4px 6px #CA4D06;
        background-position: center;
        background-repeat: no-repeat;
        margin-right: -2px;
    }
    &__wrap {
        min-width: 186px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #FFFFFF;
        border-radius: 35px;
        margin-left: 14px;
        padding: 10px 0 10px 4px;
        &:hover {
            box-shadow: 0px 0px 16px rgba(255, 220, 38, 0.3);
            .last-deposit__btn {
                box-shadow: 0px 4px 20px #19191D, inset 0px 4px 6px rgba(255, 255, 255, 0.8), inset 0px -4px 6px #CA4D06;
            }
        }
        &:active {
            .last-deposit__btn {
                box-shadow: inset 0px -4px 6px rgba(255, 255, 255, 0.8), inset 0px 4px 6px #CA4D06;
            }
        }
    }
    &.has-tooltip {
        .last-deposit__text {
            display: block;
        }
    }

}
@keyframes bounce-horizontal {
    from,
    20%,
    53%,
    80%,
    to {
      -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }

    40%,
    43% {
      -webkit-transform: translateX(-20px);
      transform: translateX(-20px);
    }

    70% {
      -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      -webkit-transform: translateX(-10px);
      transform: translateX(-10px);
    }

    90% {
      -webkit-transform: translateX(-2px);
      transform: translateX(-2px);
    }
  }
