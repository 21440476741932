$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.tournament-leagues {
  &__title {
    @include text(24px, 28px, 700, $color-black);
    text-transform: uppercase;
    margin-bottom: 24px;
  }
  &__wrap {
    display: flex;
    height: 100%;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
  }
  &__list {
    display: contents;
  }
  &__item {
    padding: 32px 22px;
    background-image: var(--league-icon), linear-gradient(180deg, #ffd43e 0%, #ff4e4e 100%);
    border-radius: 24px;
    width: 204px;
    height: 104px;
    margin: 0 10px 10px 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 160px 40px, cover;
    transition: .3s linear;
    &:hover {
        opacity: .8;
        transform: translateY(3px);
        transition: .3s linear;
    }
    @include bp1280 {
        width: 184px;
        height: 95px;
    }
    @include bp1920 {
        width: 212px;
        height: 110px;
        margin: 0 10px 24px 0;
    }
  }
}
