$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.gh-wrap {
  background: #2D2D32;
  border-radius: 12px;

  &__header {
    padding:12px 20px;
    border-bottom:solid 1px #19191D;
    display: flex;
    align-items: center;
  }

  &__icon {
    width: 36px;
    height: 36px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-right:14px;
    flex-shrink: 0;

    &:lang(ar) {
      margin-right: 0;
      margin-left: 14px;
    }
  }

  &__name {
    @include text-dots;

    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #FFFFFF;
    font-family: var(--font-family-main);
    margin-right: 16px;

    &:lang(ar) {
      margin-right: 0;
      margin-left: 16px;
    }
  }

  &__btn {
    @include text-dots;

    margin-left:auto;
    flex-shrink: 0;
    max-width: 50%;

    &:lang(ar) {
      margin-left: unset;
      margin-right: auto;
    }
  }

  &__content {
    padding:17px 20px 40px;
  }

  @include bp1280 {
    &__header {
      padding:12px 24px;
    }

    &__content {
      padding:20px;
    }
  }

  @include bp1440 {
    &__content{
      padding:24px  32px 40px;
    }

    &__header {
      padding:14px 32px;
    }
  }
}
