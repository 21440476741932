$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.ferrari-rules-item {
  color: #fff;
  width: 110px;
  padding-bottom: 12px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 1px;
    border-radius: 5px 5px 0px 0px;
    border-top: 1px solid rgba(0, 0, 0, 0.3);
    background-color: #050505;
    width: 40px;
    height: 5px;
  }
  &:nth-child(1)::before {
    content: '';
    width: 1px;
    height: 64px;
    opacity: 0.3;
    right: 0;
    background: #000;
    position: absolute;
    transform: translateY(5px);
    box-shadow: 1px 0px 0px 0px #ffffff26;
  }
  &:nth-child(2)::after {
    background-color: #ffe71d;
  }
  &:nth-child(3)::before {
    content: '';
    width: 1px;
    height: 64px;
    opacity: 0.3;
    left: 0;
    background: #000;
    position: absolute;
    transform: translateY(5px);
    box-shadow: 1px 0px 0px 0px #ffffff26;
  }

  &__terms {
    box-shadow: 0 0 0 2px #ffffff26, inset 0 0 0 2px #fff;
    border: none;
    border-radius: 50%;
    color: #fff;
    height: 30px;
    width: 30px;
    font-size: 15px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: -1.2px;
    position: absolute;
    transform: translateY(-20px);
    background-color: rgba(255, 255, 255, 0.15);
    outline: none;
  }
  &__text {
    font-size: 14px;
    line-height: 16px;
  }
  &__label {
    box-shadow: 0 0 0 2px #ffffff26, inset 0 0 0 2px #00a3ff;
    border: none;
    border-radius: 50%;
    color: #fff;
    height: 30px;
    width: 30px;
    font-size: 15px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: -1.2px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(-20px);
    background-color: rgba(255, 255, 255, 0.15);
    outline: none;
  }
  &__pitstope {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    transform: translateY(-20px);
  }
}
