$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.gh-load{
  position:absolute;
  width: 77px;
  height: 77px;
  z-index: 50;
  left: calc(50% - 35px);
  bottom: -75px;
  border-radius: 50%;
  background-color: #19191D;
  background-image: var(--icon-load2);
  background-size: contain;
  cursor:pointer;

  &__text{
    text-align: center;
    padding-top:20px;
    width: 100%;
    color: #FFDC26;
    text-shadow: 0 0 6px #FFDC26;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    font-family: var(--font-family-main);

    &:lang(fr),
    &:lang(el),
    &:lang(eu) {
      font-size: 12px;
    }

    &:lang(ru),
    &:lang(pt) {
      font-size: 16px;
    }
  }

  &__dots{
    height: 17px;
    display: flex;
    align-items: flex-end;
    justify-content: center;

    & span{
      width: 2px;
      height: 2px;
      background: $color-yellow;
      display: block;
      margin:0 1px;
      box-shadow: 0 0 6px #FFDC26;
      animation-timing-function: ease-in-out;
      animation-duration: 1.3s;
      animation-iteration-count: infinite;

      &:nth-of-type(3){ animation-delay: 0s; }
      &:nth-of-type(2){ animation-delay: .25s; }
      &:nth-of-type(1){ animation-delay: .5s; }

      .gh-load.animate-preloader &{ animation-name: load ; }
    }
  }
}

@keyframes load {
  50% { transform: translateY(-5px)}
}
