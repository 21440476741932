$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.tooltip{
  display: none;
  position:absolute;
  top: 24px;
  left: 50%;
  transform: translateX(-50%);

  background: #FFFFFF;
  border-radius: 10px;

  width: 160px;
  padding:12px;

  text-align: left;
  font-size: 12px;
  line-height: 16px;
  color: #0B1222;
  font-weight: 500;
  box-shadow: 0 3px 4px rgba(0,0,0,0.3);
  max-width: none;

  z-index: 200;

  .popup & { z-index: 1200;}
  .has-tooltip:hover &{ display: block; }
}