$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.cookie-banner {
  position: fixed;
  bottom: 0;
  max-width: 500px;
  padding-bottom: 16px;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  z-index: 900;

  &__wrap {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    background: #ffffff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    border-radius: 7px;
    @include text(12px, 16px, 400, #3F3F3F);
  }

  &__text {
    flex: 1 1 auto;
    padding: 0 12px;
    font-size: 12px;
    line-height: 16px;
    color: #3F3F3F;

    a {
      color: currentColor;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__btn {
    min-width: 95px;
    flex-shrink: 0;
    padding: 6px 18px;
    background-color: #ffdc26;
    box-shadow: inset 0 10px 12px #fffc, inset 0 -10px 12px #e88e0f;
    @include text(14px, 24px, 700, #19191D);

    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 2px 8px #19191d40, inset 0 10px 12px #fffc, inset 0 -10px 12px #e88e0f;
      transition: transform .3s;
    }
  }
}
