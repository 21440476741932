$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.page-verification-alert {
  position: relative;
  width: 100%;
  height: 30px;
  display: none;
  padding-inline-start: 18px;
  padding-inline-end: 10px;
  background-color: #cc0034;
  color: #ffffff;
  align-items: center;
  justify-content: center;
  z-index: 100;

  &.is-verified {
    background-color: #00c400;
  }

  &.is-verification-changed {
    display: flex;
  }

  &__text {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;

    &::before {
      width: 20px;
      height: 20px;
      display: block;
      content: '';
      background: url($cdn + '/rabona/verification/non-verified.svg');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      margin-inline-end: 8px;

      .is-verified & {
        background: url($cdn + '/rabona/verification/verified.svg') no-repeat;
      }
    }
  }

  &__link {
    text-decoration: underline;
    color: #ffffff;
    font-weight: 600;
    margin: 0 4px;
  }

  &__icon {
    width: 18px;
    height: 18px;
    background-repeat: no-repeat;
    background-position: center;
    margin-inline-start: 8px;
    cursor: pointer;
    filter: invert(100%);

    &.is-arrow {
      background-size: contain;
      background-image: var(--icon-i-arrow);
      transform: rotate(-90deg);
    }

    &.is-close {
      background-size: 18px;
      background-image: var(--icon-close);
    }
  }
}

.verification-popup {
  &__description ul {
    padding-inline-start: 20px;
  }

  &__close-btn {
    &:lang(ar) {
      right: unset;
      left: 40px;
    }
  }
}

.document-item__ico {
  &:lang(ar) {
    margin-right: 0;
    margin-left: 12px;
  }
}
