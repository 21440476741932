$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.profile-info {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &__block {
        width: 48%;
        height: 68px;
        background: $color-white;
        border-radius: 8px;
        text-align: center;
        padding: 8px 12px 12px 12px;
        margin-bottom: 16px;

        &:nth-child(3) {
            margin: 0 auto;
        }

        &.is-line {
            width: 100%;
            height: 1px;
            background: linear-gradient(184deg, var(--grLineBlack));
            padding: 0;
            margin: 21px 0 20px 0;
        }

        &.is-change-password {
            width: 100%;
            height: auto;
            padding: 0;
            margin: 10px auto 26px auto;
            background: transparent;
            display: flex;
            justify-content: center;
            @include text(18px, 20px, normal, $color-black);
            text-transform: uppercase;
            font-family: var(--font-family-main);
            @include text-dots;
        }
    }

    &__key {
       @include text(14px, 20px, normal, $color-grey);
       padding-bottom: 4px;
       @include text-dots;
    }

    &__val {
        @include text(20px, 24px, bold, $color-black);
        font-family: var(--font-family-main);
        @include text-dots;
    }

    @include bp1280 {
        &__block {
            width: 32%;
            margin-bottom: 20px;

            &:nth-child(3) {
                margin: 0;
            }

            &.is-change-password {
                width: 32%;
                height: 68px;
                margin: 0;
                align-items: center;
            }

            &.is-line {
                margin-top: 1px;
            }
        }
        &__link {
            margin: auto;
        }
    }

    @include bp1440 {
        &__block {
            margin-bottom: 24px;

            &.is-line {
                margin: 5px 0 24px 0;
            }
        }
    }
}
