$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.sidemenu {
  position: fixed;
  left: 0;
  top: 0;
  display: grid;
  grid-template-columns: 1fr;
  width: 0;
  height: 100%;
  z-index: 500;
  transition: 300ms;
  overflow: hidden;

  @include bp1024 {
    grid-template-columns: 60% 40%;
  }

  @include bp1280 {
    grid-template-columns: 50% 50%;
  }

  &:lang(ar) {
    left: unset;
    right: 0;
  }

  &.is-open {
    width: 100%;
  }

  // Close button
  &__close {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 520;
    width: 72px;
    height: 72px;
    background-size: 22px 22px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: var(--icon-close-thin);
    filter: $filter-grey-light;
    cursor: pointer;

    &:lang(ar) {
      left: unset;
      right: 0;
    }

    &:hover {
      filter: $filter-white;
    }
  }

  // Wrapper
  &__wrapper {
    display: flex;
    gap: 30px;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 72px;
    margin-left: auto;
    background-color: #19191D;

    &:lang(ar) {
      margin-right: auto;
      margin-left: unset;
    }
  }

  // Overlay layer
  &__overlay {
    display: none;
    background: linear-gradient(to right, #19191D 0, rgba(0,0,0,0) 100%);

    &:lang(ar) {
      background: linear-gradient(to left, #19191D 0, rgba(0,0,0,0) 100%);
    }

    @include bp1024 {
      display: block;
    }
  }

  &__block {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 100%;
    opacity: 0;

    transform: translateX(-50px);

    &:lang(ar) {
      transform: translateX(50px);
    }

    &.is-left {
      .is-open & {
        animation: showNavBlock 0.15s linear;
        animation-fill-mode: forwards;
        animation-delay: 0.4s;

        &:lang(ar) {
          animation: showNavBlockArab 0.15s linear;
          animation-fill-mode: forwards;
          animation-delay: 0.3s;
        }
      }
    }

    &.is-right {
      .is-open & {
        animation: showNavBlock 0.15s linear;
        animation-fill-mode: forwards;
        animation-delay: 0.3s;

        &:lang(ar) {
          animation: showNavBlockArab 0.15s linear;
          animation-fill-mode: forwards;
          animation-delay: 0.4s;
        }
      }
    }
  }

  &__link {
    display: flex;
    align-items: center;
    height: 73px;
    padding-inline-start: 38px;

    &-label {
      background-color: $color-red;
      color: $color-white;
      display: flex;
      border-radius: 35px;
      justify-content: center;
      align-items: center;
      padding: 3px 4px;
      height: 12px;
      font-weight: 700;
      line-height: 10px;
      font-size: 9px;
    }

    &.is-summer-promo {
      display: flex;
      justify-content: space-between;
    }

    &.euro-link {
      position: relative;
      flex-direction: column;
      height: fit-content;
      align-items: flex-start;
      width: fit-content;
      border-radius: 12px;
      background-color: rgba(255, 220, 38, 0.1);
      padding: 8px 46px 8px 24px;
      margin-bottom: 10px;
      line-height: 110%;
      margin-inline-start: 20px;

      &::before {
        margin-inline-start: 8px;
      }

      &::after {
        content: '';
        position: absolute;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
        width: 28px;
        height: 28px;
        background-image: url($cdn + '/fezbet/tournaments/icon-euro2024.svg');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }

      &:hover {
        &::before {
          background-image: url($cdn + '/fezbet/tournaments/icon-euro2024-hover.svg');
        }
      }
    }

    .euro-link__text {
      color: $color-white;
      font-size: 10px;
      font-weight: 400;
      line-height: 120%;
    }

    &-divider {
      background: linear-gradient(181deg,var(--grLineWhite)),linear-gradient(181deg,var(--grLineBlack));
      background-repeat: no-repeat,no-repeat;
      background-size: 100% 1px,100% 1px;
      background-position: center 1px, center 0;

      &.is-summer-promotion {
        background-image: linear-gradient(181deg, var(--grLineWhite));
        background-position: 50% 100%;
        position: relative;
        bottom: -1px;
      }
    }

    &:not(:first-of-type) {
      background: linear-gradient(181deg,var(--grLineWhite)),linear-gradient(181deg,var(--grLineBlack));
      background-repeat: no-repeat,no-repeat;
      background-size: 100% 1px,100% 1px;
      background-position: center 1px, center 0;
    }

    &::before {
      margin-left: 23px;
    }

    &:lang(ar) {
      margin-left: 0;
      margin-right: 23px;
    }

    &.is-white::before {
      width: 8px;
      height: 8px;
      border: 1px solid $color-white;
    }

    &.is-holiday {
      align-items: center;
      display: flex;
      padding-left: 50px;

      &::before {
        background-image: var(--icon-holiday);
        border: none;
        background-size: 46px 46px;
        background-repeat: no-repeat;
        background-position: 50% -4px;
        height: 40px;
        margin: -4px 0 0 10px;
        width: 40px;
      }

      &::after {
        align-items: center;
        background: #E12D2D;
        box-shadow: inset 0 -2px 3px rgba(60, 18, 5, .4), inset 0 2px 3px rgba(255, 255, 255, .3);
        color: #fff;
        content: "NEW";
        display: flex;
        font-size: 10px;
        height: 18px;
        justify-content: center;
        width: 32px;
      }

      span {
        margin-right: auto;
      }
    }
  }
}

@keyframes showNavBlock {
  0% {
    transform: translateX(-50px);
    opacity: 0;
  }

  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}

@keyframes showNavBlockArab {
  0% {
    transform: translateX(50px);
    opacity: 0;
  }

  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}
