$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.home-week-game {
  background: rgba(225, 45, 45, 0.3);
  border-radius: 20px;
  text-align: right;
  padding: 20px 20px 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
  max-height: 320px;
  overflow: hidden;

  &__title {
    @include text(28px, 36px, bold);
    letter-spacing: 0.05em;
    text-transform: uppercase;
    font-family: var(--font-family-main);
    height: 72px;
    overflow: hidden;
    width: calc(100% - 200px);
    max-width: 192px;
    z-index: 10;
  }
}
