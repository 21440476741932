$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.home-promo {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 64px;
    justify-content: space-between;
    background-image: linear-gradient(to bottom, rgba(234, 234, 234, 0) 0%, #EAEAEA 51.04%, rgba(234, 234, 234, 0) 100%, rgba(234, 234, 234, 0) 100%);
    background-repeat: no-repeat;
    background-size: 1px 100%;
    background-position: center;
    position: relative;
    min-height: 240px;

    &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 60px;
        height: 60px;
        background-image: url($cdn + '/fezbet/elements/circle.png');
        background-size: cover;
        z-index: 1;
    }

    @include bp1440 {
        background-size: 2px 100%;

        &::after {
            width: 96px;
            height: 96px;
        }
    }

    &__item {
        width: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        position: relative;
        padding: 32px 32px 28px;
        overflow: hidden;

        @include bp1280 {
            padding: 32px 70px 28px;
        }

        @include bp1440 {
            height: 100%;
            padding: 52px 52px 60px;
        }

        @include bp1680 {
            padding: 60px;
        }
    }

    &__content {
        width: 260px;
        margin-bottom: 12px;
        flex-grow: 1;
        position: relative;
        z-index: 2;

        @include bp1280 {
            width: 300px;
        }

        @include bp1440 {
            width:  320px;
            margin-bottom: 27px;
        }

        @include bp1680 {
            margin-bottom: 8px;
            width:  396px;
        }
    }

    &__title {
        @include text(28px, 36px, bold, #28272A);
        font-family: var(--font-family-main);
        text-transform: uppercase;
        margin-bottom: 12px;
        word-break: break-word;

        @include bp1440 {
            font-size: 40px;
            line-height: 48px;
            margin-bottom: 20px;
        }

        @include bp1680 {
            font-size: 48px;
            line-height: 56px;
        }
    }

    &__text {
        width: 100%;
        @include text(14px, 20px, normal, #28272A);
        letter-spacing: 0.03em;

        @include bp1440 {
            font-size: 18px;
            line-height: 28px;
        }
    }

    &__value {
        @include text(64px, 72px, bold, $color-yellow-dark);
        font-family: var(--font-family-main);
        display: flex;
        align-items: center;
        position: absolute;
        top: 14px;
        right: 30px;
        z-index: 1;
        width: fit-content;

        &:lang(ar) {
            right: unset;
            left: 30px;
            text-shadow: 0 0 8px $color-white;
        }

        @include bp1440 {
            line-height: 100px;
            top: 36px;
            right: 52px;

            &:lang(ar) {
                left: 52px;
            }
        }

        &:lang(pt-BR) {
            font-size: 50px;

            @include bp1440 {
                font-size: 70px;
            }
        }

        @include bp1680 {
            right: 60px;
        }
    }

    &__btns {
        display: flex;
        align-items: center;
        .btn {
            margin-right: 20px;
            max-width: 55%;

            &:lang(ar) {
                margin-right: 0;
                margin-left: 20px;
            }
        }
        .link-arr {
            max-width: 45%;
            @include text-dots;
        }
    }
}
