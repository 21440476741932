$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.promo {
  $this: &;

  color: $color-black;

  display: flex;
  padding: 40px 24px 40px 28px;

  border-radius: 20px;
  background-color: $color-white;
  background-repeat: no-repeat;
  background-size: 32px;
  background-position: 286px 12px;

  &_sport {
    background-image: var(--icon-promo-preview-sport);
  }

  &_casino {
    background-image: var(--icon-promo-preview-casino);
  }

  &_live-casino {
    background-image: var(--icon-promo-preview-live-casino);
  }

  &__terms {
    padding-inline-start: 20px;
  }

  &__main {
    flex: 0 0 (380px + 32px);
    display: flex;
    flex-direction: column;
    padding-right: 32px;
    background-repeat: no-repeat;
    background-image:
      linear-gradient(180deg, var(--grLineWhite), var(--grLineWhite)),
      linear-gradient(180deg, var(--grLineBlack), var(--grLineBlack));
    background-size: 2px 100%;
    background-position: top 0 right 2px, top 0 right 0;

    &:lang(ar) {
      padding-right: 0;
      padding-left: 32px;
      background-position: top 0 left 2px, top 0 left 0;
    }
  }

  &__title {
    margin-bottom: 4px;
    font-family: var(--font-family-main);
    @include text(32px, 38px, 700, $color-grey-dark);
    text-transform: uppercase;
  }

  &__subtitle {
    margin-bottom: 24px;
    font-family: var(--font-family-main);
    @include text(52px, 62px, 700, $color-yellow-dark);
  }

  &__description {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 24px;
  }

  &__btns {
    position: relative;
    height: 44px;

    .btn {
      position: absolute;
      top: 0;
      inset-inline-start: 0;
    }
  }

  &__btn {
    align-self: flex-start;
  }

  &__terms-title {
    font-family: var(--font-family-main);
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: .02em;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 22px;
  }

  &__text {
    p {
      position: relative;
      padding-inline-start: 36px;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 10px;
      text-align: justify;

      bdi {
        display: contents;
      }

      span,
      strong {
        position: absolute;
        inset-inline-start: 0;
        font-weight: 700;
      }
    }

    a {
      color: #19191d;
      display: contents;
    }

    table {
      font-size: 16px;
      width: calc(100% - 40px);
      margin: 20px 0;
      margin-inline-start: 40px;

      td,
      th {
        border: 1px solid #48526b;
        padding: 8px;
        text-align: center;
      }

      tr:first-child {
        td {
          color: #000;
        }
      }
    }

    ul,
    ol {
      font-size: 16px;
      padding-inline-start: 58px;
      padding-bottom: 10px;

      li {
        padding-bottom: 6px;
      }
    }

    ul {
      li {
        list-style-type: disc;
      }
    }
  }

  @include bp1440 {
    background-position-x: 400px;
    padding: 48px 32px 60px 32px;

    #{$this} {
      &__terms {
        padding-left: 27px;
      }

      &__terms-title {
        margin-bottom: 30px;
      }
    }
  }

  &-slider {
    position: relative;
    margin-inline-start: -10px;
    direction: ltr;

    &__arr {
      position: absolute;
      width: 36px;
      height: 36px;
      background-color: rgba(45, 45, 50, 0.8);
      border-radius: 50%;
      border: none;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;

      &:hover {
        background-color: rgba(25, 25, 29, 0.8);
      }

      &.is-prev {
        left: 18px;

        &::before {
          content: '';
          width: 100%;
          height: 100%;
          display: flex;
          filter: $filter-yellow;
          background-size: 18px;
          background-repeat: no-repeat;
          background-position: center;
          background-image: var(--icon-arrow-left);
        }
      }

      &.is-next {
        right: 18px;

        &::before {
          content: '';
          width: 100%;
          height: 100%;
          display: flex;
          filter: $filter-yellow;
          background-size: 18px;
          background-repeat: no-repeat;
          background-position: center;
          background-image: var(--icon-arrow-right);
        }
      }

      &.slick-disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }

    &__item {
      border-radius: 20px;
      background-color: $color-white;
      padding: 40px 28px;
      text-transform: uppercase;
      display: grid;
      background-size: 32px;
      background-position: top 12px right 12px;
      background-repeat: no-repeat;
      margin: 0 10px;
      font-family: $font-roboto;

      &.sport {
        background-image: var(--icon-promo-preview-sport);
      }

      &.casino {
        background-image: var(--icon-promo-preview-casino);
      }

      &.disable {
        background-image: var(--icon-icon-disable);
        pointer-events: none;
      }
    }

    &__title {
      @include text(20px, 140%, 700, $color-black);

      letter-spacing: 1px;
    }

    &__subtitle {
      @include text(36px, 120%, 700, #eea315);

      margin-bottom: 14px;

      &.is-future-promo {
        opacity: 0.2;
      }
    }

    &__btns {
      display: flex;
      align-items: center;

      .btn-44-yellow:lang(el) {
        font-size: 16px;
        padding: 0 20px;
      }

      &.is-future-promo {
        opacity: 0.2;
        pointer-events: none;
      }
    }

    &__btn {
      .linda-has-remembered-login & {
        &.wasnt-logged {
          display: none;
        }

        &.was-logged {
          display: inline-block;
        }
      }

      &.was-logged {
        display: none;
      }
    }

    &__link {
      text-transform: none;
      margin-inline-start: 20px;
    }
  }
}
