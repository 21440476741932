dvs-popup {
  color: #000000;
  font-family: $ff;
}

.verification-popup {
  max-width: 800px;
  width: 100%;
  height: auto;
  max-height: 90%;
  background: #ffffff;
  overflow-y: auto;
  box-shadow: 0 6px 16px rgba(37, 14, 61, 0.1);
  left: 50%;
  padding: 30px 40px 20px 40px;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);

  @include breakpoint($bp2){
    max-height: none;
    max-width: 100%;
    height: 100%;
    overflow-y: auto;
    padding: 10px 20px 100px;
    min-height: 100vh;
    position: fixed;
    inset: 0;
    transform: translate(0,0);
  }

  @media (max-width: 950px) and (orientation: landscape) {
    max-width: 100%;
    height: 100%;
    overflow-y: auto;
    padding: 10px 20px 100px;
    min-height: 100vh;
    position: fixed;
    inset: 0;
    transform: translate(0,0);
  }

  &__close-btn {
    background-image: url($cdn + '/dvs/i-close-rounded.svg');
    background-color: transparent;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    height: 28px;
    position: absolute;
    inset-inline-end: 40px;
    top: 30px;
    transition: opacity 0.3s ease;
    width: 28px;

    &:hover {
      opacity: 0.6;
    }

    @include breakpoint($bp2){
      inset-inline-end: 15px;
      top: 10px;
    }
  }

  &__title {
    font-size: 32px;
    line-height: 40px;
    font-weight: 600;
    padding: 0 40px 20px;
    text-align: center;

    @include breakpoint($bp2){
      font-size: 24px;
      line-height: 28px;
      padding-block: 0 20px;
      padding-inline: 0 25px;
    }
  }

  body.rtl-lang &__title {
    text-align: center;
  }

  &__frame {
    margin: 0 auto;

    &.is-kreditz {
      padding: 30px;
      height: 100%;

      & iframe {
        width: 100%;
        height: calc(100vh - 215px);

        @include breakpoint($bp2){
          height: 100%;
        }
      }
    }
  }

  &__description {
    color: #524e72;

    ul {
      list-style-type: disc;
      padding-left: 20px;
      margin-bottom: 10px;
    }

    p {
      line-height: 20px;
      margin-bottom: 12px;

      @include breakpoint($bp2){
        margin-bottom: 4px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    li {
      line-height: 20px;
      margin-bottom: 4px;
    }
  }

  &__btn-wrap {
    text-align: center;
  }

  &__error {
    width: 100%;
    border: 1px solid rgba(244,67,54,.5);
    background-color: rgba(244,67,54,.08);
    height: 40px;
    padding: 0 16px;
    font-size: 16px;
    line-height: 40px;
    font-style: italic;
    color: #524e72;
    margin-bottom: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .onfido-sdk-ui-Modal-inner {
    @include breakpoint($bp2) {
      width: calc(100% - 40px);
      height: 37.5em;
    }

    @media (max-width: 950px) and (orientation: landscape) {
      width: calc(100% - 40px);
      height: 37.5em;
    }
  }

  &-wrap {
    &.dvs-preloader {
      position: relative;
      z-index: 900;

      .verification-popup__form {
        pointer-events: none;
      }
    }

    &.dvs-preloader::after {
      content: '';
      display: block;
      position: fixed;
      width: 100%;
      height: 100%;
      z-index: 900;
      inset: 0;
      background: rgba(255,255,255,.6);
      pointer-events: none;
    }
  }
}
