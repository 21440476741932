$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.btn-fullscreen {
  @include text(14px, 32px, normal, $color-white);

  background-image: var(--icon-icon_full-screen);
  background-color: rgba(255, 220, 38, 0.1);
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 16px;
  border-radius: 35px;
  border: none;
  padding: 0 16px 0 34px;
  margin-left: 20px;
  font-family: var(--font-family-main);
  text-transform: uppercase;

  &:lang(ar) {
    margin-left: 0;
    margin-right: 20px;
  }

  &:hover {
    color: $color-yellow;
  }
}
