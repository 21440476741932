$build: 1742800300223;
$cdn: "https://joxi.imgsrcdata.com";
$cdnProject: undefined;
.cash-crab-popup{
  position:relative;
  overflow: hidden;
  padding-bottom:10px;
  &__congrat{
    height: 80px;
    text-align: center;
    font-style: italic;
    font-weight: 900;
    font-size: 36px;
    line-height: 80px;
    letter-spacing: 0.10em;
    text-transform: uppercase;
    color: #05207B;
    text-shadow: 0 0 2px #fff;
    background-image: url( $cdn + '/crab/brand/fezbet/flash.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  &__text{
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #262626;
    font-weight: 700;
    margin-top:8px;
  }
  &__step{
    margin:24px auto 0;
    background-size: contain;
    background-position: center top;
    background-repeat: no-repeat;
    height: 230px;
    &.is-1{
      width: 280px;
      background-image: url( $cdn +  '/crab/brand/fezbet/cash-crab-popup1.png');
    }
    &.is-2{
      width: 270px;
      background-image: url( $cdn + '/crab/brand/fezbet/cash-crab-popup2.png');
    }
  }
  &__row{
    margin-top:24px;
    height: 260px;
    display: flex;
    align-items: center;
    justify-content: center;
    &.is-sm{
      height: 162px;
    }
  }
  &__item{
    width: 124px;
    & span{
      display: inline-block;
      min-height: 36px;
    }

  }
  &__symbol{
    font-size: 28px;
    line-height: 40px;
    color: #262626;
    margin:0 28px;
  }

  &__btn{
    margin:12px 0 36px;
    display: flex;
    justify-content: center;
    &.is-nm{
      margin:12px 0 0;
    }
  }

  &__dots{
    position:absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  &__dot{
    position:relative;
    border-radius: 0;
    width: 24px;
    height: 24px;
    margin:0 14px;
    &::before{
      content: '';
      display: block;
      position:absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50% , -50%);
      width: 8px;
      height: 8px;
      border:solid 2px #999999;
      border-radius: 50%;
    }
    &.is-active::before{
      background-color: #2B2B30;
    }
    &.is-complete::before{
      width: 24px;
      height: 24px;
      border: none;
      background-color: #19191D;
      background-image: var(--icon-check);
      background-position: center;
      background-repeat: no-repeat;

    }
  }
}